import React, { useCallback, useEffect, useRef, useState } from "react"
import { useRoutingFlow } from "../../shared/RoutingFlowContainer"
import { useHistory } from "react-router"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Typography, Button, ForwardIcon, Dialog, BackIcon, useTheme } from "@carrotfertility/carotene-core"
import { RoutingFlowTitle } from "#/components/carrot-plans/shared"
import { RegisteringLoadingIndicator } from "#/components/views/register/RegistrationLoadingIndicator"
import { CarrotDocumentType, getSignatureForDocument, SignatureCompleteEvent } from "#/lib/carrot-api/signatureClient"
import { useUpdateDocumentStatus } from "#/components/medical-records-consent/utils/DocumentStatusApi"
import { determineDocumentAction } from "#/components/medical-records-consent/utils/documentStatusHelper"

function MedicalRecordsConsentStep({ documentType }: { documentType: CarrotDocumentType }): JSX.Element {
  const { setShowProgressTracker } = useRoutingFlow()
  const history = useHistory()
  const intl = useIntl()
  const { mutateAsync: updateDocumentStatus } = useUpdateDocumentStatus()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [frameSrc, setFrameSrc] = useState("/blank.html")
  const [docuSignIsReady, setDocuSignIsReady] = useState(false)
  const signatureIdRef = useRef<number | null>(null)

  useEffect(() => {
    setShowProgressTracker(false)
  }, [setShowProgressTracker])

  const handleWindowEvent = useCallback(
    async (event: SignatureCompleteEvent) => {
      if (
        event?.data?.documentType === CarrotDocumentType.ArtMedicalRecordsConsentForm ||
        event?.data?.documentType === CarrotDocumentType.FertilityPreservationConsentForm
      ) {
        const documentAction = determineDocumentAction(event)
        await updateDocumentStatus({
          documentType: documentType,
          documentStatusRequest: { signatureId: signatureIdRef.current, documentAction }
        })

        window.removeEventListener("message", handleWindowEvent)
        history.push("/")
      }
    },
    [history, updateDocumentStatus, documentType]
  )

  const showDocument = async () => {
    setModalIsOpen(true)
    window.addEventListener("message", handleWindowEvent)
    const envelopeSignatureInfo = await getSignatureForDocument(documentType)
    signatureIdRef.current = envelopeSignatureInfo.signatureId
    setFrameSrc(envelopeSignatureInfo.docuSignUrl)
    setDocuSignIsReady(true)
  }

  const closeModal = () => {
    setModalIsOpen(false)
    setFrameSrc("/blank.html")
    setDocuSignIsReady(false)
  }

  const onCloseModal = () => {
    window.removeEventListener("message", handleWindowEvent)
    closeModal()
  }

  const handleGoToCarrotHomeClick = async () => {
    await updateDocumentStatus({
      documentType: documentType,
      documentStatusRequest: { signatureId: signatureIdRef.current, documentAction: "SKIPPED" }
    })

    history.push("/")
  }

  return (
    <>
      <RoutingFlowTitle title={intl.formatMessage({ defaultMessage: "While we prepare your Carrot Plan..." })} />
      <HeaderContent />
      <MainContent />
      <ActionButtons onGoToCarrotHomeClick={handleGoToCarrotHomeClick} onReviewAuthorizationClick={showDocument} />
      <AuthorizationFormModal
        modalIsOpen={modalIsOpen}
        docuSignIsReady={docuSignIsReady}
        frameSrc={frameSrc}
        onCloseModal={onCloseModal}
      />
    </>
  )
}

const HeaderContent = () => (
  <>
    <Box display="flex" justifyContent="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
      <Box
        component="img"
        src={`/images/illo-doctor-with-clipboard.png`}
        srcSet={`/images/illo-doctor-with-clipboard-2x.png`}
        alt=""
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}
        height={230}
        width={230}
      />
    </Box>
    <Typography
      color={(theme) => theme.palette.text.primary}
      variant="h3"
      component="h1"
      marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
    >
      <FormattedMessage defaultMessage="While we prepare your Carrot Plan..." />
    </Typography>
  </>
)

const MainContent = () => (
  <>
    <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
      <FormattedMessage defaultMessage="Members going through assisted reproduction can get even more personalized care — and improve outcomes for all our members — by allowing Carrot to access relevant medical records. Please review and sign the following authorization so we can better support you." />
    </Typography>
    <Typography marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
      <FormattedMessage defaultMessage="Personal, identifiable information is always kept secure, and we will never share it with your benefit sponsor or any other third party. For reporting purposes, we will always de-identify your health information." />
    </Typography>
  </>
)

const ActionButtons = ({
  onGoToCarrotHomeClick,
  onReviewAuthorizationClick
}: {
  onGoToCarrotHomeClick: () => void
  onReviewAuthorizationClick: () => void
}) => (
  <Box paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xl)} textAlign="end">
    <Button
      data-testid="go-to-carrot-home-button"
      size="medium"
      color="secondary"
      variant="text"
      onClick={onGoToCarrotHomeClick}
    >
      <FormattedMessage defaultMessage="Go to Carrot home" />
    </Button>
    <Button
      data-testid="review-authorization-button"
      size="medium"
      color="secondary"
      variant="outlined"
      endIcon={<ForwardIcon />}
      onClick={onReviewAuthorizationClick}
    >
      <FormattedMessage defaultMessage="Review authorization" />
    </Button>
  </Box>
)

const AuthorizationFormModal = ({
  onCloseModal,
  modalIsOpen,
  docuSignIsReady,
  frameSrc
}: {
  onCloseModal: () => void
  modalIsOpen: boolean
  docuSignIsReady: boolean
  frameSrc: string
}) => {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Dialog
      aria-labelledby={intl.formatMessage({ defaultMessage: "Authorization Form Modal" })}
      onClose={onCloseModal}
      open={modalIsOpen}
      fullScreen
      PaperProps={{
        sx: {
          borderRadius: theme.tokens.borderRadius.none,
          padding: theme.tokens.spacing.xs,
          [theme.breakpoints.only("md")]: {
            padding: theme.tokens.spacing.md
          },
          [theme.breakpoints.up("md")]: {
            padding: theme.tokens.spacing.xl
          }
        }
      }}
    >
      <Button
        data-testid="authorization-form-modal-back-button"
        onClick={onCloseModal}
        variant="text"
        color="secondary"
        startIcon={<BackIcon />}
      >
        <FormattedMessage defaultMessage="Back" />
      </Button>
      <Box marginTop={theme.tokens.spacing.xs} height="95%">
        {docuSignIsReady ? (
          <iframe
            title={intl.formatMessage({ defaultMessage: "Authorization Form" })}
            style={{ width: "100%", height: "100%" }}
            src={frameSrc}
            allowFullScreen
          />
        ) : (
          <RegisteringLoadingIndicator isModal={true} />
        )}
      </Box>
    </Dialog>
  )
}

export function ArtMedicalRecordsConsentStep(): JSX.Element {
  return <MedicalRecordsConsentStep documentType={CarrotDocumentType.ArtMedicalRecordsConsentForm} />
}
export function PreservationMedicalRecordsConsentStep(): JSX.Element {
  return <MedicalRecordsConsentStep documentType={CarrotDocumentType.FertilityPreservationConsentForm} />
}
