import React, { useEffect } from "react"
import { useSignUpFlow } from "../context/signup/SignUpFlowContext"
import { useHistory } from "react-router"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { Button, Typography, Stack, useTheme, Box } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { PiiText } from "../../services/tracking"

export default function SignUpEmployeeHasSamlPage() {
  const { email } = useSignUpFlow()
  const history = useHistory()
  const theme = useTheme()
  const intl = useIntl()

  useEffect(() => {
    if (!email) {
      history.push("/signup")
    }
  }, [history, email])
  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "We have an email on file for you" })}>
      <AppAccessPageCard>
        <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Typography component="h1" variant="h2">
            <FormattedMessage defaultMessage="We have an email on file for you" />
          </Typography>
        </Box>
        <Stack
          spacing={theme.spacing(theme.tokens.spacing.xs)}
          textAlign="center"
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
        >
          <Typography>
            <FormattedMessage defaultMessage="We found an account for " />
          </Typography>
          <Typography component={PiiText}>{email}</Typography>
          <FormattedMessage defaultMessage="Please sign in with this email address." />
        </Stack>
        <Box textAlign="center">
          <Button component={RouterLink} to={`/login`}>
            <FormattedMessage defaultMessage="Go to login" description="Go to login from SignUpEmployeeHasSamlPage" />
          </Button>
        </Box>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
