import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export default function WhereAreYouInJourneyDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyStageValue = routingData?.journeyStage
  const memberSelectsVal = (() => {
    switch (journeyStageValue) {
      case "PRESERVATION_STARTED":
      case "PRESERVATION_READY":
      case "PRESERVATION_RECENTLY_RECEIVED":
      case "GETTING_READY_START_IUI":
      case "ALREADY_STARTED_IUI_CYCLE":
      case "ALREADY_STARTED_IVF_CYCLE":
      case "RECENTLY_COMPLETED_IUI_CYCLE":
      case "RECENTLY_COMPLETED_IVF_CYCLE":
        return "CLINIC"
      case "GETTING_READY_START_IVF":
        return "IVF_TREATMENT_START"
      case "WORKING_WITH_AGENCY_CLINIC_ATTORNEY":
        return "CLINIC_AGENCY_ATTORNEY"
      case "WORKING_WITH_AGENCY_ATTORNEY":
        return "AGENCY"
      case "PREGNANT_FIRST":
      case "PREGNANT_SECOND":
      case "PREGNANT_THIRD":
        return "CURRENTLY_PREGNANT"
      case "POSTPARTUM":
        return "POSTPARTUM"
      default:
        return "OTHER_ANSWERS"
    }
  })()
  const { send } = useStateMachine()
  useEffect(() => {
    send(memberSelectsVal)
  })

  return <></>
}
