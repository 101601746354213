import React, { useContext, useEffect } from "react"
import usePartnerAccess, { useGetPartnerInviteStatus, useUserRole } from "../../partner-access/hooks/usePartnerAccess"
import { FormDatePicker, FormTextField } from "@carrotfertility/carotene-core-x"
import { Box, Button, Link, Stack, Typography, dayjs, useTheme } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { useFormContext } from "react-hook-form"
import { PartnerInviteStatus } from "#/components/partner-access/partnerAccessTypes"
import { SettingsPageDivider } from "#/pages/account/page"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { Link as RouterLink } from "react-router-dom"
import { getCarrotLite } from "#/redux/reducers/companyInfo"
import { getHeap } from "#/utils/heap"
import { SettingsContext } from "#/pages/account"
import { HttpStatusCodes } from "#/utils/HttpStatusCodes"
import PasswordSettings from "./PasswordSettings"
import { GenderIdentitySettings, SexSettings } from "./MemberPartnerPersonalSettings"
import { emailValidation } from "#/utils/Regexes"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { EmailWithChip } from "#/components/EmailVerification/EmailAddressesAccountSettings"
import { FormattedMessage, useIntl } from "react-intl"
import { validatePartnerDOB } from "../../../services/common-forms"
import { CommunicationSettings } from "./CommunicationSettings"

// @ts-expect-error TS(7031) FIXME: Binding element 'inviteData' implicitly has an 'an... Remove this comment to see the full error message
function PartnerDateOfBirth({ inviteData = null }): JSX.Element {
  const intl = useIntl()

  function validate(dateOfBirthValue: dayjs.Dayjs): string | boolean {
    try {
      dateOfBirthValue?.toISOString()
    } catch (e) {
      return intl.formatMessage({ defaultMessage: "Please enter a valid date" })
    }

    if (inviteData && inviteData?.status !== PartnerInviteStatus.NONE && !dateOfBirthValue) {
      return intl.formatMessage({ defaultMessage: "Required" })
    }

    if (!dateOfBirthValue) return true

    const result = validatePartnerDOB(dateOfBirthValue)
    if (typeof result !== "boolean") {
      return intl.formatMessage(result)
    }
    return result
  }

  return (
    <FormDatePicker
      required={inviteData && inviteData?.status !== PartnerInviteStatus.NONE}
      controllerProps={{ rules: { validate } }}
      name="partnerDateOfBirth"
      label={intl.formatMessage({ defaultMessage: "Date of birth" })}
    />
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'inviteData' implicitly has an 'an... Remove this comment to see the full error message
function PartnerEmail({ inviteData = null }): JSX.Element {
  const { personalEmail, email } = useCurrentUser()
  const { fieldErrorFromServer } = useContext(SettingsContext)
  const { setError } = useFormContext()
  const intl = useIntl()
  const intlEmailValidation = useIntlValidator(emailValidation)

  useEffect(() => {
    if (
      fieldErrorFromServer?.response?.status === HttpStatusCodes.CONFLICT &&
      fieldErrorFromServer?.message.includes("Partner email already exists")
    ) {
      setError("partnerEmail", {
        message: intl.formatMessage({ defaultMessage: "That email address is already in use" })
      })
      getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
    }
  }, [setError, fieldErrorFromServer, intl])

  function validate(partnerEmail: string) {
    if (!partnerEmail) {
      return true
    }
    if (partnerEmail && (partnerEmail === personalEmail || partnerEmail === email)) {
      getHeap().track("DuplicateEmail", { EventName: "Attempted to use non-unique email" })
      return intl.formatMessage({ defaultMessage: "That email address is already associated with your account." })
    }

    return intlEmailValidation(partnerEmail)
  }
  return (
    <FormTextField
      label={intl.formatMessage({ defaultMessage: "Email" })}
      name="partnerEmail"
      registerOptions={{ validate }}
      disabled={inviteData?.status === PartnerInviteStatus.ACTIVE}
    />
  )
}

function PartnerInformationSettingsBody(): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="Partner information" />
      </Typography>
      <FormTextField name="partnerFirstName" label={intl.formatMessage({ defaultMessage: "first name" })} />
      <FormTextField name="partnerLastName" label={intl.formatMessage({ defaultMessage: "last name" })} />
      <FormTextField
        name="partnerPreferredName"
        label={intl.formatMessage({ defaultMessage: "preferred name" })}
        inputProps={{ maxLength: 200 }}
      />
      <PartnerDateOfBirth />
      <GenderIdentitySettings
        names={{ genderIdentity: "partnerGenderIdentity", genderIdentityOther: "partnerGenderIdentityOther" }}
      />
      <SexSettings name="partnerSex" />
      <PartnerEmail />
    </Stack>
  )
}

export function PartnerInformationSettingsPrompt({ onClick }: { onClick: () => void }): JSX.Element {
  const theme = useTheme()
  return (
    <Stack
      spacing={theme.spacing(theme.tokens.spacing.md)}
      bgcolor={(theme) => theme.palette.primary.light}
      borderRadius={(theme) => theme.tokens.borderRadius.md}
      padding={(theme) => theme.spacing(theme.tokens.spacing.xxl)}
    >
      <Typography variant="h6">
        <FormattedMessage defaultMessage="Are you on your journey with a partner?" />
      </Typography>
      <Typography>
        <FormattedMessage
          defaultMessage="Providing details about your partner helps us better support both of you. You can change this information from
        here anytime."
        />
      </Typography>
      <Box display="flex" justifyContent="flex-end">
        <Button id="add-partner-info" onClick={onClick}>
          <FormattedMessage defaultMessage="Add partner info" />
        </Button>
      </Box>
    </Stack>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'inviteData' implicitly has an 'an... Remove this comment to see the full error message
function PartnerAccessPartnerInfoSettings({ inviteData }): JSX.Element {
  const theme = useTheme()
  const intl = useIntl()
  const inviteStatusIsActiveOrInvited = inviteData && inviteData?.status !== PartnerInviteStatus.NONE
  // @ts-expect-error TS(7006) FIXME: Parameter 'fieldValue' implicitly has an 'any' typ... Remove this comment to see the full error message
  function validate(fieldValue) {
    return inviteStatusIsActiveOrInvited && fieldValue === ""
      ? intl.formatMessage({ defaultMessage: "Required" })
      : true
  }
  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h2">
        <FormattedMessage defaultMessage="Partner information" />
      </Typography>
      <FormTextField
        required={inviteStatusIsActiveOrInvited}
        registerOptions={{ validate }}
        name="partnerFirstName"
        label={intl.formatMessage({ defaultMessage: "first name" })}
      />
      <FormTextField
        required={inviteStatusIsActiveOrInvited}
        registerOptions={{ validate }}
        name="partnerLastName"
        label={intl.formatMessage({ defaultMessage: "last name" })}
      />
      <FormTextField
        name="partnerPreferredName"
        label={intl.formatMessage({ defaultMessage: "preferred name" })}
        inputProps={{ maxLength: 200 }}
      />
      <PartnerDateOfBirth {...{ inviteData }} />
      <GenderIdentitySettings
        names={{ genderIdentity: "partnerGenderIdentity", genderIdentityOther: "partnerGenderIdentityOther" }}
      />
      <SexSettings name="partnerSex" />
      <PartnerEmail {...{ inviteData }} />
    </Stack>
  )
}

// When in partner view
function PartnerAccessPartnerViewSettings(): JSX.Element {
  const { partnerEmail } = useCurrentUser()
  const theme = useTheme()
  const intl = useIntl()
  return (
    <>
      <SettingsPageDivider />
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Typography variant="h2">
          <FormattedMessage defaultMessage="Partner email address" />
        </Typography>
        <EmailWithChip
          email={partnerEmail}
          chipLabel={intl.formatMessage({
            defaultMessage: "partner sign-in"
          })}
        />
      </Stack>
      <SettingsPageDivider />
      <PasswordSettings isPartnerSection />
      <SettingsPageDivider />
      <CommunicationSettings isForPartner={true} />
    </>
  )
}

// @ts-expect-error TS(7031) FIXME: Binding element 'inviteData' implicitly has an 'an... Remove this comment to see the full error message
function PartnerAccessInformationActive({ inviteData }): JSX.Element {
  const isCarrotLite = useSelector(getCarrotLite)
  const { partnerEmail } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()
  const theme = useTheme()
  const intl = useIntl()

  return inviteData?.status === PartnerInviteStatus.ACTIVE && !isPartnerAccessingAccount ? (
    <>
      <SettingsPageDivider />
      <Typography variant="h2" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
        <FormattedMessage defaultMessage="Partner email address" />
      </Typography>
      <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
        <Typography>
          {!isCarrotLite
            ? intl.formatMessage({
                defaultMessage:
                  "Your partner can sign in to this account with their own email address and password. They can access educational resources, benefit details, and more, but they won't be able to edit your account information, access Financial support, or see your Messages. They may be able to see personal information like gender identity."
              })
            : intl.formatMessage({
                defaultMessage:
                  "Your partner can sign in to this account with their own email address and password. They can access educational resources, benefit details, and more, but they won't be able to edit your account information or see your Messages. They may be able to see personal information like gender identity."
              })}
        </Typography>
        <EmailWithChip
          email={partnerEmail}
          chipLabel={intl.formatMessage({
            defaultMessage: "partner sign-in"
          })}
        />
        <>
          <Typography fontStyle="italic">
            <FormattedMessage defaultMessage="Need to remove your partner’s access or change their sign-in?" />
          </Typography>
          <Link component={RouterLink} to={"/send-a-message"}>
            <Typography>
              <FormattedMessage defaultMessage="Send us a message." />
            </Typography>
          </Link>
        </>
      </Stack>
    </>
  ) : null
}

function PartnerAccessPartnerInfoSettingsContainer(): JSX.Element {
  const { inviteData } = useGetPartnerInviteStatus()
  const { isPartnerAccessingAccount } = useUserRole()

  return (
    <>
      <PartnerAccessPartnerInfoSettings inviteData={inviteData} />
      {isPartnerAccessingAccount && <PartnerAccessPartnerViewSettings />}
      {!isPartnerAccessingAccount && <PartnerAccessInformationActive inviteData={inviteData} />}
    </>
  )
}

export function PartnerInformationSettings(): JSX.Element {
  const { showPartnerAccess } = usePartnerAccess()

  return !showPartnerAccess ? <PartnerInformationSettingsBody /> : <PartnerAccessPartnerInfoSettingsContainer />
}
