import React from "react"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { Progress } from "../../views/modal/Progress"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { IVFCard } from "./modules/IvfCard"
import { DoulaRequirementsCard } from "./modules/DoulaRequirementsCard"
import CarrotRxMedicationCard from "./modules/CarrotRxMedicationCard"
import WhatKindOfJourneyEnums from "../../../utils/WhatKindOfJourneyEnums"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { JourneySubTypes, JourneyTypes } from "./ExpenseJourneyTypes"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { DialogActionsBar, Form, FormOnSubmitHandler, FormSelect, useWatch } from "@carrotfertility/carotene-core-x"

const JourneyModule = (props: { hasRequirements: boolean }) => {
  const { data: benefit } = useBenefit()
  const kindOfJourney = useWatch({ name: "kindOfJourney" })
  const isDoulaService = kindOfJourney ? kindOfJourney.includes("DOULA") : false

  if (props.hasRequirements && kindOfJourney === WhatKindOfJourneyEnums.IVF) {
    return <IVFCard />
  }

  if (isDoulaService) return <DoulaRequirementsCard />

  if (kindOfJourney === JourneySubTypes.MEDICATION && benefit?.carrotRx?.isCarrotRxRequired) {
    return <CarrotRxMedicationCard />
  }

  return null
}

export const AboutThisExpenseWhatKindOfJourney = ({
  headerText,
  bodyText,
  selectOptions,
  hasRequirements = false
}: {
  headerText: string
  bodyText: string
  selectOptions: { label: string; value: string }[]
  hasRequirements?: boolean
}): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateWhatKindOfJourney, updateJourneySubtype, state } = useReimbursementContext()
  const { kindOfJourney, journeySubtype } = state

  const intl = useIntl()

  const shouldUseSubtype = [
    JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES,
    JourneyTypes.MENOPAUSE,
    JourneyTypes.LOW_TESTOSTERONE,
    JourneyTypes.GENDER_AFFIRMING_CARE
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  ].includes(JourneyTypes[state.expenseRelatedTo.value])

  const setDefault = (selection: { label: string; value: string }) => {
    return selection?.label ? selection.value : ""
  }

  const handleOnSubmit: FormOnSubmitHandler<{ kindOfJourney: string }> = (values) => {
    const answerAsSelectOption = selectOptions.find((j) => j.value === values.kindOfJourney)

    if (shouldUseSubtype) {
      updateJourneySubtype(answerAsSelectOption)
    } else {
      updateWhatKindOfJourney(answerAsSelectOption)
    }

    if (
      [
        JourneySubTypes.MEDICATION,
        JourneySubTypes.IN_PERSON_CARE,
        JourneySubTypes.ONLINE_CARE,
        JourneySubTypes.NUTRITION_COUNSELING,
        JourneySubTypes.TEMPERATURE_REGULATION_PRODUCTS,
        JourneySubTypes.LABS,
        JourneySubTypes.TRAVEL_ELIGIBLE_PROVIDER,
        JourneySubTypes.MEDICAL_APPOINTMENTS
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      ].includes(JourneySubTypes[journeySubtype?.value])
    ) {
      send("ATTESTATION_MEDICALLY_NECESSARY_AND_ELIGIBLE")
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    } else if ([JourneySubTypes.BINDERS_PACKERS_BODY_SHAPING].includes(JourneySubTypes[journeySubtype?.value])) {
      send("ATTESTATION_MEDICALLY_RELATED")
    } else {
      send("")
    }
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      defaultValues={{ kindOfJourney: shouldUseSubtype ? setDefault(journeySubtype) : setDefault(kindOfJourney) }}
    >
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">{headerText}</DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <DialogContentText>{bodyText}</DialogContentText>
          <FormSelect
            name="kindOfJourney"
            aria-labelledby="step-heading"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" })
              }
            }}
            options={selectOptions}
            label={intl.formatMessage({ defaultMessage: "Select option" })}
          />
          <JourneyModule hasRequirements={hasRequirements} />
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </Form>
  )
}
