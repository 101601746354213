import React from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Link, Tile, Typography } from "@carrotfertility/carotene-core"
import { useGetMostRecentlyCompletedRouting } from "#/components/carrot-plans/hooks/useGetMostRecentlyCompletedRouting"
import { getExpertSpecialty } from "#/components/carrot-plans/hooks/useJourneyOptions"

export default function CarrotPlanIsOnTheWayBanner(): JSX.Element {
  const intl = useIntl()
  const { data: routingData, isLoading: isRoutingInfoLoading } = useGetMostRecentlyCompletedRouting()
  const journey = routingData?.journey || null
  const expertSpecialty = journey ? getExpertSpecialty(journey) : null
  const formattedExpertSpecialty = journey && expertSpecialty ? intl.formatMessage(expertSpecialty) : null

  if (isRoutingInfoLoading) {
    return null
  }
  return (
    <Tile
      component={Box}
      display="flex"
      border={(theme) => `2px solid ${theme.palette.background.paper}`}
      borderRadius={(theme) => theme.tokens.borderRadius.sm}
      color="info"
    >
      <Box
        display="flex"
        flexDirection="row-reverse"
        paddingBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        paddingTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        gap={(theme) => theme.spacing(theme.tokens.spacing.sm)}
        border={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      >
        <Box
          component="img"
          src={`/images/illo-paper-airplane.png`}
          srcSet={`/images/illo-paper-airplane-2x.png`}
          alt=""
          height={44}
          width={44}
        />
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography
              variant="h4"
              color={(theme) => theme.palette.text.primary}
              marginBottom={(theme) => theme.tokens.spacing.xs}
            >
              <FormattedMessage defaultMessage="Your Carrot Plan is on the way" />
            </Typography>
            <Typography
              variant="body1"
              color={(theme) => theme.palette.text.primary}
              paddingBottom={(theme) => theme.tokens.spacing.lg}
            >
              {formattedExpertSpecialty ? (
                <FormattedMessage
                  defaultMessage="A Carrot Expert specializing in {journeySpecificSpecialty} is creating your Carrot Plan. We will send you an email when it is ready within one business day."
                  values={{ journeySpecificSpecialty: formattedExpertSpecialty }}
                />
              ) : (
                <FormattedMessage defaultMessage="A Carrot Expert is creating your Carrot Plan. We will send you an email when it is ready within one business day." />
              )}
            </Typography>
            <Typography variant="body1" color={(theme) => theme.palette.text.primary}>
              <FormattedMessage
                defaultMessage="In the meantime, explore your benefit highlights or see more detailed information in your <link>benefit guide</link>. "
                values={{
                  link: (linkContent) => (
                    <Link href={"/your-benefit-guide"} fontSize="inherit" color="inherit">
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Tile>
  )
}
