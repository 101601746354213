import React from "react"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { Link, DialogContentText, DialogTitle, Box } from "@carrotfertility/carotene-core"
import { useSelector } from "react-redux"
import { DialogActionsBar, Form, FormCheckbox, FormOnSubmitHandler, FormSelect } from "@carrotfertility/carotene-core-x"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "#/components/context/reimbursement/GetReimbursedContext"
import { getBenefitConfiguration } from "#/redux/reducers/benefitConfiguration"
import { Progress } from "#/components/views/modal/Progress"
import { CoverageConfiguration } from "#/types/benefitConfiguration"

type WhoReceivedCareChoice = {
  label: MessageDescriptor
  value: string
  selected: boolean
  filter?: (coverageConfig: CoverageConfiguration) => boolean
}

const whoReceivedCareChoices: WhoReceivedCareChoice[] = [
  {
    label: defineMessage({ defaultMessage: "Me" }),
    value: "MEMBER",
    selected: false
  },
  {
    label: defineMessage({ defaultMessage: "My legal spouse or tax-dependent partner" }),
    value: "LEGAL_PARTNER",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isMemberAndPartnerEligible
  },
  {
    label: defineMessage({ defaultMessage: "My non-married partner" }),
    value: "NON_MARRIED_PARTNER",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isMemberAndPartnerEligible && !coverageConfig.areLegalPartnersEligible
  },
  {
    label: defineMessage({ defaultMessage: "Birth parent for adoption" }),
    value: "BIRTH_PARENT",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isAdoptionEnabled
  },
  {
    label: defineMessage({ defaultMessage: "Donor" }),
    value: "DONOR",
    selected: false,
    filter: (coverageConfig) => {
      return coverageConfig.isDonorAssistanceEnabled || coverageConfig.isGcEnabled
    }
  },
  {
    label: defineMessage({ defaultMessage: "Gestational carrier" }),
    value: "GESTATIONAL_CARRIER",
    selected: false,
    filter: (coverageConfig) => coverageConfig.isGcEnabled
  }
]

export const AboutThisExpenseWhoReceivedCare = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateWhoReceivedCare, state } = useReimbursementContext()
  const intl = useIntl()
  const { coverageConfig } = useSelector(getBenefitConfiguration)

  const formattedAndFilteredOptions = whoReceivedCareChoices
    .filter((choice) => {
      if (!choice.filter) return choice
      return choice.filter(coverageConfig)
    })
    .map((choice) => ({ ...choice, label: intl.formatMessage(choice.label) }))

  const onlyOneOption = formattedAndFilteredOptions.length === 1

  const handleOnSubmit: FormOnSubmitHandler<{ whoReceivedCare: string; acknowledged: boolean }> = (values) => {
    updateWhoReceivedCare(
      values.acknowledged
        ? { label: "Me", value: "MEMBER" }
        : formattedAndFilteredOptions.find((choice) => choice.value === values.whoReceivedCare)
    )
    send("")
  }

  return (
    <Form
      shouldUnregister
      onSubmit={handleOnSubmit}
      defaultValues={{ whoReceivedCare: state.whoReceivedCare?.value || "", acknowledged: false }}
    >
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      {onlyOneOption ? (
        <ReimbursementDialogFraming>
          <DialogTitle>
            <FormattedMessage defaultMessage="Did you receive the care or service?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}
          >
            <DialogContentText>
              <FormattedMessage defaultMessage="You can only use funds for your own eligible expenses. " />
            </DialogContentText>
            <DialogContentText>
              <FormattedMessage
                defaultMessage="<link>Visit your benefit guide</link> for more information."
                values={{
                  link: (linkContent) => (
                    <Link target="_blank" href={"/your-benefit-guide/coverage"} color="inherit">
                      {linkContent}
                    </Link>
                  )
                }}
              />
            </DialogContentText>
            <FormCheckbox
              name="acknowledged"
              label={<FormattedMessage defaultMessage="Yes, I received the care or service." />}
              controllerProps={{
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Required" })
                }
              }}
            />
          </Box>
        </ReimbursementDialogFraming>
      ) : (
        <ReimbursementDialogFraming>
          <DialogTitle id="step-heading">
            <FormattedMessage defaultMessage="Who received the care or service?" />
          </DialogTitle>
          <Box
            sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}
          >
            <DialogContentText>
              <FormattedMessage defaultMessage="If you have eligible expenses for more than one person, please upload them as separate submissions." />
            </DialogContentText>
            <FormSelect
              aria-labelledby="step-heading"
              name="whoReceivedCare"
              controllerProps={{
                rules: {
                  required: intl.formatMessage({ defaultMessage: "Required" })
                }
              }}
              label={intl.formatMessage({ defaultMessage: "Select option" })}
              options={formattedAndFilteredOptions}
            />
          </Box>
        </ReimbursementDialogFraming>
      )}
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </Form>
  )
}
