import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { Journey } from "#/lib/contentful/utils/journeyOrderMapping"

export default function WhereAreYouInJourneyPreservationDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyValue = routingData?.journey
  const journeyStageValue = routingData?.journeyStage
  const { send } = useStateMachine()
  useEffect(() => {
    send(journeyValue === Journey.PRESERVATION ? journeyStageValue : null)
  })

  return <></>
}
