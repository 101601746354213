import React, { useRef, useState } from "react"
import { algoliasearch } from "algoliasearch"
import Settings from "#/utils/CarrotConfig"
import {
  Box,
  ClickAwayListener,
  InputAdornment,
  MenuItem,
  MenuList,
  Paper,
  Progress,
  SearchIcon,
  TextField
} from "@carrotfertility/carotene-core"
import { Configure, InstantSearch, useSearchBox } from "react-instantsearch"

const searchClient = algoliasearch(Settings.ALGOLIA_APP_ID, Settings.ALGOLIA_API_KEY)

export function Search({
  size = "medium",
  facetFilters,
  indexName,
  hits,
  placeholder,
  id,
  label
}: {
  size?: "small" | "medium"
  facetFilters: any
  indexName: string
  hits: React.ReactElement
  placeholder?: string
  id?: string
  label?: string
}): JSX.Element {
  return (
    <Box sx={() => ({ position: "relative", minInlineSize: 301 })}>
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        future={{
          preserveSharedStateOnUnmount: true
        }}
      >
        <Configure hitsPerPage={50} facetFilters={facetFilters} />
        <CustomSearchBox {...{ size, hits, placeholder, id, label }} />
      </InstantSearch>
    </Box>
  )
}

function CustomSearchBox({
  size,
  hits,
  placeholder,
  id,
  label
}: {
  size: "small" | "medium"
  hits: React.ReactElement | React.ReactElement[]
  placeholder: string
  id?: string
  label?: string
}): JSX.Element {
  const { loading, setQuery, inputValue } = useSearchTerm()
  const searchResultsRef = useRef(null)

  return (
    <>
      <TextField
        placeholder={placeholder}
        aria-label={label}
        size={size}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          )
        }}
        autoComplete="off"
        value={inputValue}
        onChange={(event) => setQuery(event.currentTarget.value)}
        onClick={(event) => {
          const target = event.target as HTMLInputElement
          setQuery(target.value)
        }}
        id={id}
        onKeyDown={(event) => {
          if (event.key === "ArrowDown") {
            event.preventDefault()
            const firstItem = searchResultsRef.current.querySelector('[role="menuitem"]')
            firstItem?.focus({ preventScroll: true })
          }
        }}
      />
      {inputValue ? (
        <ClickAwayListener onClickAway={() => setQuery(null)}>
          <Paper elevation={10} sx={{ zIndex: 2, position: "absolute", inlineSize: "100%" }} role="menu">
            <LoadSearchTerm {...{ loading }}>
              <MenuList
                ref={searchResultsRef}
                sx={{
                  maxBlockSize: 300,
                  overflowY: "auto",
                  overflowX: "hidden"
                }}
              >
                {hits}
              </MenuList>
            </LoadSearchTerm>
          </Paper>
        </ClickAwayListener>
      ) : null}
    </>
  )
}

function LoadSearchTerm({ loading, children }: { loading: boolean; children: React.ReactElement }) {
  return loading ? (
    <MenuItem sx={{ display: "flex", justifyContent: "center" }}>
      <Progress />
    </MenuItem>
  ) : (
    children
  )
}

function useSearchTerm(): { loading: boolean; setQuery: (arg: string) => void; inputValue: string } {
  const { query, refine } = useSearchBox()
  const [inputValue, setInputValue] = useState(query)
  const [loading, setLoading] = useState(false)

  React.useEffect(() => {
    const timeoutId = setTimeout(() => {
      refine(inputValue)
      setLoading(false)
    }, 1000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [inputValue, query, refine])

  function setQuery(newQuery: string) {
    setInputValue(newQuery)
    setLoading(true)
  }

  return { loading, setQuery, inputValue }
}
