import React, { ReactElement } from "react"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Button, Card, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { CarrotPlansPaths, DbgPaths } from "#/utils/Paths"
import { useBenefitHighlights, ValueProposition } from "./hooks/useBenefitHighlights"
import { useStartRouting } from "../../carrot-plans/shared/useQueryStartRouting"
import { useStartRoutingInfo } from "../../carrot-plans/shared/useQueryRoutingInfo"
import { caseRateRenewalRoutingFlowUrl } from "../../carrot-plans/case-rate-renewals/workflow"
import { useHistory } from "react-router-dom"
import { useCurrentUser } from "../../context/user/UserContext"
import useCarrotPlanEmployeeSettings from "../../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { ProgramType } from "#/lib/carrot-api/types/Benefit"
import { WtwEngageHero } from "../WtwEngageHero"
import { BenefitHighlightHero, BenefitHighlightsInner } from "./BenefitHighlightHero"
import { useGetCarrotPlanRequestStatus } from "#/components/carrot-plans/hooks/useCarrotPlanRequestStatus"

type UseBenefitHighlightComponentSetUp = {
  filteredValueProps: ValueProposition[]
  isDoneForNow: boolean
  startRouting: () => Promise<void>
  shouldSeeEngageHero: boolean
  areAnyLoading: boolean
  showContinueCopy: boolean
  carrotPlanIsOnTheWay: boolean
  programTypeAllowsBenefitHighlight: boolean
}

function useBenefitHighlightsComponentSetUp(): UseBenefitHighlightComponentSetUp {
  const { isBenefitLoading, programTypeAllowsBenefitHighlight, filteredValueProps, programType } =
    useBenefitHighlights()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    isDoneForNow
  } = useCarrotPlanEmployeeSettings()
  const { carrotPlansRoutingStarted } = useCurrentUser()
  const { startRouting } = useStartRouting(useStartRoutingInfo(), caseRateRenewalRoutingFlowUrl)
  const cpRequestStatusQuery = useGetCarrotPlanRequestStatus()

  const shouldSeeEngageHero = !programTypeAllowsBenefitHighlight && programType === ProgramType.WTW_ENGAGE
  const areAnyLoading = isBenefitLoading || isLoadingEmployeeSettings || cpRequestStatusQuery.isLoading
  const areAnyInError = isErrorEmployeeSettings || cpRequestStatusQuery.isError
  const showContinueCopy = carrotPlansRoutingStarted && !areAnyInError && !!carrotPlanEmployeeSettings?.routingInfoId

  return {
    filteredValueProps,
    isDoneForNow,
    startRouting,
    shouldSeeEngageHero,
    areAnyLoading,
    showContinueCopy,
    carrotPlanIsOnTheWay: cpRequestStatusQuery.data?.carrotPlanIsOnTheWay,
    programTypeAllowsBenefitHighlight
  }
}

function getTileText(showContinueCopy: boolean, shouldSeeEngageHero: boolean) {
  const buttonText = showContinueCopy
    ? defineMessage({ defaultMessage: "Continue" })
    : defineMessage({ defaultMessage: "Get your Carrot Plan" })

  const messageText = showContinueCopy
    ? defineMessage({ defaultMessage: "Continue telling us about your journey so we can create your Carrot Plan." })
    : defineMessage({
        defaultMessage:
          "When you're ready to unlock full access to your benefit, tell us about your journey and we'll craft a free, personalized plan to support you."
      })

  const headerText = shouldSeeEngageHero
    ? defineMessage({ defaultMessage: "Tell us about your journey" })
    : defineMessage({ defaultMessage: "Benefit highlights" })

  return {
    buttonText,
    messageText,
    headerText
  }
}

function BenefitHighlights(): ReactElement {
  const theme = useTheme()
  const history = useHistory()
  const intl = useIntl()
  const {
    areAnyLoading,
    showContinueCopy,
    shouldSeeEngageHero,
    carrotPlanIsOnTheWay,
    isDoneForNow,
    startRouting,
    filteredValueProps,
    programTypeAllowsBenefitHighlight
  } = useBenefitHighlightsComponentSetUp()
  const { buttonText, messageText, headerText } = getTileText(showContinueCopy, shouldSeeEngageHero)

  if (areAnyLoading || (shouldSeeEngageHero && carrotPlanIsOnTheWay)) {
    return null
  }

  async function handleClick(): Promise<Promise<void> | void> {
    if (isDoneForNow) {
      await startRouting()
    } else {
      history.push(CarrotPlansPaths.ENROLLMENT)
    }
  }

  return (
    <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Typography variant="h3">{intl.formatMessage(headerText)}</Typography>
      {shouldSeeEngageHero && (
        <WtwEngageHero handleClick={handleClick} buttonText={buttonText} messageText={messageText} />
      )}
      {programTypeAllowsBenefitHighlight && (
        <BenefitHighlightHero
          handleClick={handleClick}
          buttonText={buttonText}
          messageText={messageText}
          carrotPlanIsOnTheWay={carrotPlanIsOnTheWay}
          filteredValueProps={filteredValueProps}
        />
      )}
    </Stack>
  )
}

function BenefitHighlightsCard(): ReactElement {
  const intl = useIntl()
  const { isBenefitLoading, programTypeAllowsBenefitHighlight, filteredValueProps, programType } =
    useBenefitHighlights()

  if (isBenefitLoading || !programTypeAllowsBenefitHighlight) {
    return null
  }

  return (
    <Card
      id="benefit-highlights-sidebar"
      sx={{
        backgroundColor: (theme) => theme.palette.background.hover
      }}
      title={intl.formatMessage({
        defaultMessage: "Benefit highlights"
      })}
      primaryAction={
        programType === ProgramType.PRO && (
          <Button variant="outlined" color="secondary" href={DbgPaths.CARROT_ELIGIBLE} component="a" fullWidth>
            <FormattedMessage defaultMessage="Learn to use Carrot funds" />
          </Button>
        )
      }
    >
      <BenefitHighlightsInner useCompactSpacing filteredValueProps={filteredValueProps} />
    </Card>
  )
}

export { BenefitHighlights, BenefitHighlightsCard }
