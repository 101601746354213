import React from "react"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { useCurrentUser } from "../../context/user/UserContext"
import { Progress } from "../../views/modal/Progress"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { JourneyTypes } from "./ExpenseJourneyTypes"
import { Box, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { DialogActionsBar, Form, FormOnSubmitHandler, FormSelect } from "@carrotfertility/carotene-core-x"

type StorageFeesOption = {
  label: MessageDescriptor
  value: string
}
const storageFeeOptions: StorageFeesOption[] = [
  {
    value: "OTHER",
    label: defineMessage({ defaultMessage: "No" })
  },
  {
    value: "FIRST_YEAR_STORAGE",
    label: defineMessage({ defaultMessage: "Yes: First year of storage" })
  },
  {
    value: "POST_FIRST_YEAR_STORAGE",
    label: defineMessage({ defaultMessage: "Yes: After first year of storage" })
  }
]

const isGCExpense = (expenseRelatedTo: string): boolean => {
  return expenseRelatedTo.includes("GC")
}

export const AboutThisExpenseRelatedToStorageFees = (): JSX.Element => {
  const { updateRelatedToStorageFees, state } = useReimbursementContext()
  const { send, back } = useStateMachine()
  const { isUsa } = useCurrentUser()
  const intl = useIntl()
  const { data: benefit } = useBenefit()

  const storageFeesBodyText = isUsa
    ? intl.formatMessage({
        defaultMessage:
          "This helps us determine whether an expense is taxable so we can process reimbursements in a legally compliant way."
      })
    : intl.formatMessage({ defaultMessage: "If you're not sure, choose the option that seems like the best fit." })

  const isPossibleCarrotRxExpense = (expenseRelatedTo: string): boolean => {
    const isCarrotRxRequired = benefit.carrotRx.isCarrotRxRequired
    const isPreservationRxExpense = expenseRelatedTo === JourneyTypes.PRESERVATION
    const isGcRxExpense = expenseRelatedTo === JourneyTypes.GC
    const isRxCovered = benefit.journeys.fertilityCare.eligibleExpensesDetail.isRxCovered
    const hasDonorAssistance = benefit.journeys.fertilityCare.eligibleExpensesDetail.hasDonorAssistance
    const isFertilityCareCarrotRxExpense =
      expenseRelatedTo === JourneyTypes.FERTILITY_CARE && (isRxCovered || hasDonorAssistance)

    return isCarrotRxRequired && (isGcRxExpense || isPreservationRxExpense || isFertilityCareCarrotRxExpense)
  }

  const formattedRelatedToStorageFeesOptions = storageFeeOptions.map((option) => ({
    ...option,
    label: intl.formatMessage(option.label)
  }))

  const handleOnSubmit: FormOnSubmitHandler<{ relatedToStorageFees: string }> = (values) => {
    const answerAsSelectOption = formattedRelatedToStorageFeesOptions.find(
      (j) => j.value === values.relatedToStorageFees
    )

    updateRelatedToStorageFees(answerAsSelectOption)

    if (isPossibleCarrotRxExpense(state.expenseRelatedTo?.value)) {
      send("RELATED_TO_MEDICATION")
    } else if (isGCExpense(state.expenseRelatedTo?.value)) {
      send("ADDITIONAL_INFO_ANYTHING_ELSE")
    } else {
      send("")
    }
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      defaultValues={{
        relatedToStorageFees: state.relatedToStorageFees?.value || ""
      }}
    >
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Is this related to storage fees?" />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <DialogContentText>{storageFeesBodyText}</DialogContentText>

          <FormSelect
            aria-labelledby="step-heading"
            name="relatedToStorageFees"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" })
              }
            }}
            options={formattedRelatedToStorageFeesOptions}
            label={intl.formatMessage({ defaultMessage: "Select option" })}
          />
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </Form>
  )
}
