import React, { useContext, useState } from "react"
import { RoutingFlowTopSection } from "./RoutingFlowTopSection"
import { PartnerAccessAccountBanner } from "#/components/partner-access/PartnerAccessBanner"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { useGetRoutingInfo } from "./useQueryRoutingInfo"
import { CenteredLoadingIndicator } from "#/components/views/molecules/Molecules"
import { Box, CloseIcon, Container, IconButton, IconButtonProps } from "@carrotfertility/carotene-core"
import { AdvancedCheckInWarningQueryString } from "../case-rate-renewals/useCaseRateRenewals"
import { CaseRateRenewalsStatus, useGetCaseRateRenewalStatus } from "../case-rate-renewals/useQueryCaseRateRenewals"
import { useIntl } from "react-intl"
import { Link as ReactRouterLink } from "react-router-dom"
import { MedicalInfoContextProvider } from "../context/MedicalInfoContext"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"

export type RoutingFlowContextProps = {
  setShowProgressTracker: React.Dispatch<React.SetStateAction<boolean>>
  setDisableButtons: React.Dispatch<React.SetStateAction<boolean>>
  disableButtons: boolean
  setHideCloseButton: React.Dispatch<React.SetStateAction<boolean>>
  hideCloseButton: boolean
}

const RoutingFlowContext = React.createContext<RoutingFlowContextProps>(null)

export const useRoutingFlow = (): RoutingFlowContextProps => useContext(RoutingFlowContext)

export function CustomStepRoutingFlowLayout({
  children,
  stepName,
  sectionName
}: React.PropsWithChildren<{ stepName: string; sectionName: string }>) {
  return (
    <RoutingFlowLayoutBase stepName={stepName} sectionName={sectionName}>
      {children}
    </RoutingFlowLayoutBase>
  )
}

function RoutingFlowLayoutStateMachine({ children }: React.PropsWithChildren) {
  const { stepName, sectionName } = useStateMachine()
  return (
    <RoutingFlowLayoutBase stepName={stepName} sectionName={sectionName}>
      {children}
    </RoutingFlowLayoutBase>
  )
}

function RoutingFlowLayoutBase({
  children,
  stepName,
  sectionName
}: React.PropsWithChildren<{ stepName: string; sectionName: string }>) {
  return (
    <Box
      bgcolor="#eeddcd"
      height="100%"
      width="100%"
      minHeight="100vh"
      paddingBottom={(theme) => ({ xs: 0, md: theme.spacing(theme.tokens.spacing.xxl) })}
      data-carrot-plan-routing-flow-step={stepName}
      data-carrot-plan-routing-flow-section={sectionName}
    >
      <Box
        display={{ xs: "none", md: "block" }}
        padding={(theme) => theme.spacing(theme.tokens.spacing.lg, theme.tokens.spacing.xxxl)}
      >
        <Box
          component={"img"}
          src={`/images/CarrotGrey.svg`}
          alt=""
          height={(theme) => theme.spacing(theme.tokens.spacing.md)}
        />
      </Box>
      <Container
        component="main"
        maxWidth="md"
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          border: `${theme.tokens.borderWidth.sm} solid white`,
          borderRadius: theme.tokens.borderRadius.md,
          minBlockSize: "100vh",
          [theme.breakpoints.down("md")]: {
            padding: theme.spacing(theme.tokens.spacing.md)
          },
          [theme.breakpoints.up("md")]: {
            padding: theme.spacing(theme.tokens.spacing.xl, theme.tokens.spacing.xxxl),
            minBlockSize: "unset"
          }
        })}
      >
        {children}
      </Container>
    </Box>
  )
}

const CloseButtonReactRouterLink = React.forwardRef<HTMLAnchorElement, Pick<IconButtonProps<"a">, "href">>(
  ({ href, ...otherProps }, ref) => <ReactRouterLink to={href} {...otherProps} ref={ref} />
)
CloseButtonReactRouterLink.displayName = "CloseButtonReactRouterLink"

function CloseButton({ disabled }: Pick<IconButtonProps, "disabled">) {
  const intl = useIntl()
  const { data: caseRateRenewalStatus, showAdvancedCheckInFeatures } = useGetCaseRateRenewalStatus()

  const destination = React.useMemo(() => {
    if (caseRateRenewalStatus === CaseRateRenewalsStatus.GATED) {
      return "/refresh-carrot-plan-leave-warning"
    }
    if (showAdvancedCheckInFeatures) {
      return `/?step=${AdvancedCheckInWarningQueryString}`
    } else {
      return "/"
    }
  }, [caseRateRenewalStatus, showAdvancedCheckInFeatures])

  return (
    <IconButton
      data-testid="close-button"
      aria-label={intl.formatMessage({ defaultMessage: "Save progress and close" })}
      LinkComponent={CloseButtonReactRouterLink}
      href={destination}
      disabled={disabled}
    >
      <CloseIcon />
    </IconButton>
  )
}

export function RoutingFlowContainer({ children }: React.PropsWithChildren<unknown>): JSX.Element {
  const [showProgressTracker, setShowProgressTracker] = useState(true)
  const [disableButtons, setDisableButtons] = useState(false)
  const [hideCloseButton, setHideCloseButton] = useState(false)
  const { isPartnerAccessingAccount } = useUserRole()

  const { isLoading } = useGetRoutingInfo()

  const context = {
    setShowProgressTracker,
    setDisableButtons,
    disableButtons,
    setHideCloseButton,
    hideCloseButton
  }

  return (
    <RoutingFlowLayoutStateMachine>
      <RoutingFlowContext.Provider value={context}>
        <MedicalInfoContextProvider>
          <Box display="flex" justifyContent="flex-end">
            {!hideCloseButton ? <CloseButton disabled={disableButtons} /> : null}
          </Box>
          {isPartnerAccessingAccount && <PartnerAccessAccountBanner isRouting={true} />}
          <RoutingFlowTopSection {...{ showProgressTracker }} />
          {isLoading ? <CenteredLoadingIndicator /> : children}
        </MedicalInfoContextProvider>
      </RoutingFlowContext.Provider>
    </RoutingFlowLayoutStateMachine>
  )
}
