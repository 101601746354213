import React from "react"
import { useGetMedicalInfo, useMedicalInfo } from "../../context/MedicalInfoContext"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import {
  StepLayout,
  SkipButton,
  ButtonFooterLayout,
  ContinueButton,
  RoutingFlowTitle
} from "#/components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { InputAdornment, Stack, useTheme } from "@carrotfertility/carotene-core"
import { TextDirection } from "#/types/Locale"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"

type FormValues = {
  height: {
    measurementOne: string
    measurementTwo: string
  }
  weight: string
}

export default function HeightWeightStep(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const textDirection = useTextDirection()
  const isRtl = textDirection === TextDirection.RightToLeft
  const isLtr = textDirection === TextDirection.LeftToRight

  const defaultHeightMeasurementOne = medicalInfo?.height ? Math.floor(parseInt(medicalInfo?.height, 10) / 12) : null
  const defaultHeightMeasurementTwo = medicalInfo?.height ? parseInt(medicalInfo?.height, 10) % 12 : null

  const { send } = useStateMachine(
    medicalInfo?.weight || medicalInfo?.height || hasQuestionBeenSkipped("heightWeight") ? "" : null
  )

  async function onClickContinue(values: FormValues): Promise<void> {
    if (!values?.height.measurementOne && !values?.height.measurementTwo && !values?.weight) {
      await onSkip()
    } else {
      const heightMeasurementOne = parseInt(values?.height.measurementOne || "0", 10)
      const heightMeasurementTwo = parseInt(values?.height.measurementTwo || "0", 10)
      const weight = parseFloat(values?.weight || "0")

      const medicalInfoPayload = {
        height: heightMeasurementOne * 12 + heightMeasurementTwo,
        heightUnits: "IN",
        weight: weight,
        weightUnits: "LB"
      }
      await updateMedicalInfo(medicalInfoPayload)
      send("")
    }
  }

  async function onSkip(): Promise<void> {
    await updateMedicalInfo({ skipFields: ["heightWeight"] })
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Please enter your height & weight."
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form
            defaultValues={{
              height: {
                measurementOne: defaultHeightMeasurementOne?.toString(),
                measurementTwo: defaultHeightMeasurementTwo?.toString()
              },
              weight: medicalInfo?.weight
            }}
            onSubmit={onClickContinue}
          >
            <Stack
              marginBottom={theme.spacing(theme.tokens.spacing.lg)}
              spacing={theme.spacing(theme.tokens.spacing.xs)}
            >
              <FormTextField
                InputLabelProps={{ "aria-hidden": true }}
                label={<FormattedMessage defaultMessage="Height" />}
                id="heightFtInput"
                name="height.measurementOne"
                type="number"
                registerOptions={{
                  max: 10,
                  min: 1
                }}
                inputProps={{
                  max: 10,
                  min: 1,
                  "aria-label": intl.formatMessage({ defaultMessage: "height in feet" })
                }}
                InputProps={{
                  endAdornment: isLtr ? (
                    <InputAdornment position="end">
                      <FormattedMessage defaultMessage="ft" description="Abbreviation for feet" />
                    </InputAdornment>
                  ) : null,
                  startAdornment: isRtl ? (
                    <InputAdornment position="start">
                      <FormattedMessage defaultMessage="ft" description="Abbreviation for feet" />
                    </InputAdornment>
                  ) : null
                }}
              />
              <FormTextField
                id="heightInInput"
                name="height.measurementTwo"
                type="number"
                registerOptions={{
                  max: 11,
                  min: 0
                }}
                inputProps={{
                  max: 11,
                  min: 0,
                  "aria-label": intl.formatMessage({ defaultMessage: "height in inches" })
                }}
                InputProps={{
                  endAdornment: isLtr ? (
                    <InputAdornment position="end">
                      <FormattedMessage defaultMessage="in" description="Abbreviation for inches" />
                    </InputAdornment>
                  ) : null,
                  startAdornment: isRtl ? (
                    <InputAdornment position="start">
                      <FormattedMessage defaultMessage="in" description="Abbreviation for inches" />
                    </InputAdornment>
                  ) : null
                }}
              />
            </Stack>
            <FormTextField
              label={<FormattedMessage defaultMessage="Weight" />}
              InputLabelProps={{ "aria-hidden": true }}
              id={"weightInput"}
              name="weight"
              type="number"
              registerOptions={{
                max: 999,
                min: 0
              }}
              inputProps={{
                max: 999,
                min: 0,
                step: 0.1,
                "aria-label": intl.formatMessage({ defaultMessage: "weight in pounds" })
              }}
              InputProps={{
                endAdornment: isLtr ? (
                  <InputAdornment position="end">
                    <FormattedMessage defaultMessage="lbs" description="Abbreviation for pounds (weight measurement)" />
                  </InputAdornment>
                ) : null,
                startAdornment: isRtl ? (
                  <InputAdornment position="start">
                    <FormattedMessage defaultMessage="lbs" description="Abbreviation for pounds (weight measurement)" />
                  </InputAdornment>
                ) : null
              }}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
