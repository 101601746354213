import React from "react"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { Progress } from "#/components/views/modal/Progress"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useReimbursementContext } from "#/components/context/reimbursement/GetReimbursedContext"
import { useCurrentUser } from "#/components/context/user/UserContext"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { JourneyTypes } from "./ExpenseJourneyTypes"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { Benefit } from "carrot-api/types/Benefit"
import { DialogTitle, DialogContentText, Box } from "@carrotfertility/carotene-core"
import { DialogActionsBar, Form, FormOnSubmitHandler, FormSelect } from "@carrotfertility/carotene-core-x"

type ExpenseRelatedToChoice = {
  label: MessageDescriptor
  value: JourneyTypes
  filter?: (j: any, benefit?: Benefit) => boolean
}
const reimbursementJourneys: ExpenseRelatedToChoice[] = [
  {
    value: JourneyTypes.ADOPTION,
    label: defineMessage({ defaultMessage: "Adoption" }),
    filter: (j) => j.adoption.hasEligibleExpenses
  },
  {
    value: JourneyTypes.GC,
    label: defineMessage({ defaultMessage: "Gestational surrogacy" }),
    filter: (j) => j.gestationalCarrier.hasEligibleExpenses
  },
  {
    value: JourneyTypes.PRESERVATION,
    label: defineMessage({ defaultMessage: "Fertility preservation" }),
    filter: (j) => j.fertilityPreservation.hasEligibleExpenses
  },
  {
    value: JourneyTypes.FERTILITY_CARE,
    label: defineMessage({ defaultMessage: "Fertility care" }),
    filter: (j) => j.fertilityCare.hasEligibleExpenses
  },
  {
    value: JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES,
    label: defineMessage({ defaultMessage: "Pregnancy and postpartum services" }),
    filter: (j, b) =>
      j.pregnancy.hasEligibleExpenses &&
      (b.journeys.pregnancy.eligibleExpensesDetail.milkShippingCovered ||
        b.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered ||
        b.journeys.pregnancy.eligibleExpensesDetail.childbirthClassesCovered)
  },
  {
    value: JourneyTypes.MENOPAUSE,
    label: defineMessage({ defaultMessage: "Menopause" }),
    filter: (j) => j.menopause.hasEligibleExpenses
  },
  {
    value: JourneyTypes.LOW_TESTOSTERONE,
    label: defineMessage({ defaultMessage: "Low testosterone" }),
    filter: (j) => j.lowTestosterone.hasEligibleExpenses
  },
  {
    value: JourneyTypes.GENDER_AFFIRMING_CARE,
    label: defineMessage({ defaultMessage: "Gender-affirming care" }),
    filter: (j) => j.genderAffirmingCare.hasEligibleExpenses
  },
  {
    value: JourneyTypes.PATHFINDING,
    label: defineMessage({ defaultMessage: "Something else" })
  }
]

const isQME = (answer: string): boolean => {
  return ["FERTILITY_CARE", "PRESERVATION", "PATHFINDING"].includes(answer)
}

export const AboutThisExpenseExpenseRelatedTo = (): JSX.Element => {
  const { send, back } = useStateMachine()
  const { updateExpenseRelatedTo, updateJourneySubtype, updateWhatKindOfJourney, state } = useReimbursementContext()
  const { isUsa } = useCurrentUser()
  const { data: benefit } = useBenefit()
  const intl = useIntl()

  const filteredAndFormattedReimbursementJourneyOptions = reimbursementJourneys
    .filter((c) => !c.filter || c.filter(benefit.journeys, benefit))
    .map((journeyOption) => ({
      ...journeyOption,
      label: intl.formatMessage(journeyOption.label)
    }))

  const updateJourneyTypeAndSubtype = (answer: string): void => {
    // if expenseRelatedTo changes clear out whatKindOfJourney/JourneySubType
    if (state.expenseRelatedTo.value !== answer) {
      updateJourneySubtype({})
      updateWhatKindOfJourney({})
    }
  }

  const handleOnSubmit: FormOnSubmitHandler<{ expenseRelatedTo: string }> = (values): void => {
    const answer = values.expenseRelatedTo
    const answerAsSelectOption = filteredAndFormattedReimbursementJourneyOptions.find((j) => j.value === answer)
    updateJourneyTypeAndSubtype(answer)
    updateExpenseRelatedTo(answerAsSelectOption)
    if (answer === "ADOPTION" && isUsa) {
      send("ADOPTION")
      return
    }
    if (
      [
        JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES,
        JourneyTypes.MENOPAUSE,
        JourneyTypes.LOW_TESTOSTERONE,
        JourneyTypes.GENDER_AFFIRMING_CARE
      ].includes(answer as JourneyTypes)
    ) {
      send("WHAT_SERVICES")
      return
    }
    if ([JourneyTypes.PRESERVATION, "GC"].includes(answer)) {
      send("STORAGE_FEES")
      return
    }
    if (!isQME(answer)) {
      send("ADDITIONAL_INFO_ANYTHING_ELSE")
      return
    }
    if (answer === JourneyTypes.FERTILITY_CARE) {
      send("FERTILITY_CARE")
      return
    }
    send("")
  }

  return (
    <Form onSubmit={handleOnSubmit} defaultValues={{ expenseRelatedTo: state?.expenseRelatedTo?.value || null }}>
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="What is this expense related to?" />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <DialogContentText>
            <FormattedMessage defaultMessage="If you're not sure, choose the option that seems like the best fit." />
          </DialogContentText>
          <FormSelect
            aria-labelledby="step-heading"
            name="expenseRelatedTo"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" })
              }
            }}
            label={intl.formatMessage({ defaultMessage: "Select option" })}
            options={filteredAndFormattedReimbursementJourneyOptions}
          />
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </Form>
  )
}
