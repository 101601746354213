import { useCurrentUser } from "#/components/context/user/UserContext"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@carrotfertility/carotene-core"
import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"

export function SignInAsPartnerStep(): JSX.Element {
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const { partnerPreferredName, partnerFirstName } = useCurrentUser()
  const partnerName = partnerPreferredName ?? partnerFirstName
  return (
    <>
      <DialogTitle>
        <FormattedMessage defaultMessage="Sign in with {partnerName}'s credentials" values={{ partnerName }} />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            defaultMessage="Sign in to Carrot with {partnerName}’s own email address and password to access Carrot’s virtual menopause clinic."
            values={{ partnerName }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialogFlow(false)}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
