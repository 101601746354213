import React, { useEffect, useLayoutEffect, useRef, useState } from "react"
import { getErrorMessageFromCode, CarrotErrorCodes } from "#/utils/CarrotErrors"
import { useHistory, useLocation } from "react-router-dom"
import queryString from "query-string"
import { useDidMount } from "../../../utils/Hooks"
import { useDispatch } from "react-redux"
import { redirectToSamlExternalLogin } from "../../../utils/RedirectToSamlExternalLogin"
import { persistEmail } from "#/redux/actions/loginActions"
import { useQueryClient } from "@tanstack/react-query"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"
import { Link } from "@carrotfertility/carotene-core"
import { Form, FormOnSubmitHandler } from "@carrotfertility/carotene-core-x"
import { EmployeeSupportUrl } from "../../../utils/EmployeeSupportLink"
import { carrotClient } from "#/utils/CarrotClient"
import { OpenIDSignIn } from "#/pages/openid-sign-in"
import Settings from "#/utils/CarrotConfig"

export default function OpenIDLoginContainer() {
  const location = useLocation()
  const history = useHistory()
  const [state, setState] = useState({
    username: null as string,
    password: null as string,
    errorMessage: null as MessageDescriptor,
    returnUrl: null as string,
    isErrorPage: false,
    submitting: false,
    showForgotPassword: false,
    showPasswordInput: false,
    showEmailInput: true,
    samlConfig: null,
    shake: false,
    errorHasLink: false
  })
  const passwordFailedRef = useRef(false)
  const dispatch = useDispatch()
  const passwordInputRef = useRef<HTMLInputElement>()
  const queryClient = useQueryClient()

  const intl = useIntl()

  useEffect(() => {
    queryClient.clear()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- See https://carrotfertility.atlassian.net/wiki/spaces/PE/pages/2050295461/Remove+Build+Warnings#react-hooks%2Fexhaustive-deps
  }, [])

  const onSubmit: FormOnSubmitHandler<{ username: string; password?: string }> = async (formData) => {
    if (formData.password) {
      await openIdLogin(formData.username, formData.password)
    } else {
      await lookup(formData.username)
    }
  }

  useDidMount(() => {
    const parsedQueryString = queryString.parse(location.search)
    const [errorCode] = Array.isArray(parsedQueryString.errorCode)
      ? parsedQueryString.errorCode
      : [parsedQueryString.errorCode]
    const isErrorPage = errorCode != null

    const [returnUrl] = Array.isArray(parsedQueryString.ReturnUrl)
      ? parsedQueryString.ReturnUrl
      : [parsedQueryString.ReturnUrl]

    setState({
      ...state,
      errorMessage: errorCode ? getErrorMessageFromCode(errorCode) : null,
      // If we have an error message from the URL upon loading this view, that means we want to display only the message and "Sign in" button, so showEmailInput should be false
      showEmailInput: !isErrorPage,
      isErrorPage,
      returnUrl: returnUrl || null,
      errorHasLink:
        errorCode === CarrotErrorCodes.EMPLOYEE_INACTIVE || !!CarrotErrorCodes.SAML_UNSUCCESSFUL_OPERATION_EXCEPTION
    })
  })

  useLayoutEffect(() => {
    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }
  }, [state.showEmailInput])

  const lookup = async (email: string) => {
    if (state.isErrorPage) {
      setState({
        ...state,
        isErrorPage: false,
        showEmailInput: true,
        showForgotPassword: false,
        showPasswordInput: false,
        errorMessage: null,
        errorHasLink: false
      })
      return
    }

    setState({
      ...state,
      submitting: true,
      shake: false
    })

    let response
    try {
      response = await carrotClient.lookup(email)
    } catch (error) {
      setState({
        ...state,
        submitting: false,
        errorMessage: defineMessage({ defaultMessage: "Connection problem" }),
        shake: true,
        errorHasLink: false
      })
      return
    }
    // Employee's company uses SAML
    if (response.employee && response.employee.samlIdpEntityId && response.employee.ssoOnly) {
      redirectToSamlExternalLogin(
        response.employee.samlExternalLoginUrl,
        response.employee.samlIdpEntityId,
        response.employee.isAdmin,
        state.returnUrl,
        true
      )
      return
    }

    if (!response.employee) {
      setState({
        ...state,
        submitting: false,
        errorMessage:
          response.status === "MEMBER_INACTIVE"
            ? defineMessage({
                defaultMessage:
                  "It looks like your account has recently been deactivated. If you need to submit receipts for service prior to leaving your benefit sponsor, please <link>contact us</link>. If you feel that your account has been deactivated in error, please reach out to your internal benefits team for next steps."
              })
            : defineMessage({
                defaultMessage: "We couldn't find your Carrot account."
              }),
        errorHasLink: response.status === "MEMBER_INACTIVE",
        shake: true
      })
      return
    }

    // Persist current email in Store
    dispatch(persistEmail(email))

    // if employee hasn't registered yet, send them to signup
    if (!response.employee.isRegistered) {
      history.push(`/signup`)
      return
    }

    // Employee exists, so show login view with no error message
    await setState({
      ...state,
      submitting: false,
      showPasswordInput: true,
      showForgotPassword: true,
      samlConfig:
        !response.employee.ssoOnly && response.employee.samlIdpEntityId
          ? {
              samlExternalLoginUrl: response.employee.samlExternalLoginUrl,
              samlIdpEntityId: response.employee.samlIdpEntityId
            }
          : null,
      errorMessage: null,
      shake: false,
      errorHasLink: false
    })

    if (passwordInputRef.current) {
      passwordInputRef.current.focus()
    }

    // Set listener for "back" button press, and transforms view into "lookup" state
    history.listen((location, action) => {
      if (action === "POP") {
        setState({
          ...state,
          submitting: false,
          showPasswordInput: false,
          showForgotPassword: false,
          errorMessage: null
        })
      }
    })
  }

  const openIdLogin = async (email: string, password?: string) => {
    setState({
      ...state,
      submitting: true,
      shake: false
    })

    try {
      await carrotClient.openIdLogin(email, password)

      if (state.returnUrl) {
        window.location.href = Settings.CARROT_BACKEND_URL + state.returnUrl
      }
    } catch (error) {
      setState({
        ...state,
        submitting: false,
        errorMessage: defineMessage({ defaultMessage: "Password does not match." }),
        shake: true
      })

      passwordFailedRef.current = true
      return
    }
  }

  const errorMessageElement = state.errorMessage?.id
    ? intl.formatMessage(state.errorMessage, {
        link: (linkContent) => (
          <Link style={{ color: "inherit" }} href={EmployeeSupportUrl} target={"_blank"}>
            {linkContent}
          </Link>
        )
      })
    : ""

  return (
    <Form onSubmit={onSubmit}>
      <OpenIDSignIn
        errorMessage={errorMessageElement}
        shake={state.shake}
        showEmailInput={state.showEmailInput}
        showForgotPassword={state.showForgotPassword}
        showPasswordInput={state.showPasswordInput}
        submitting={state.submitting}
        samlConfig={state.samlConfig}
        redirectToSamlExternalLogin={redirectToSamlExternalLogin}
        returnUrl={state.returnUrl}
      />
    </Form>
  )
}
