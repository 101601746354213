import React from "react"
import {
  Link,
  Stack,
  Typography,
  useTheme,
  Box,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  CloseIcon,
  IconButton,
  BoxProps,
  Button,
  DialogTitle
} from "@carrotfertility/carotene-core"
import { useCurrentUser } from "../context/user/UserContext"
import { Link as RouterLink } from "react-router-dom"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { PiiText } from "../../services/tracking"

type PersonalEmailActionTakenModalProps = {
  open: boolean
  onClose: () => void
}

export const emailVerificationHeaderText = defineMessage({ defaultMessage: "Email verification sent" })
export const emailVerificationExpirationText = defineMessage({
  defaultMessage: "The verification link will expire in 24 hours."
})
export const emailVerificationFindEmailText = defineMessage({
  defaultMessage: "If you did not receive the email, please check your spam folder."
})

function SentEmailImage(props: BoxProps<"img">): JSX.Element {
  return (
    <Box
      src={`/images/illo-paper-airplane-blue-circle.png`}
      srcSet={`/images/illo-paper-airplane-blue-circle-2x.png`}
      alt=""
      component="img"
      {...props}
    />
  )
}

export default function VerificationSentModal({ open, onClose }: PersonalEmailActionTakenModalProps): JSX.Element {
  const { personalEmail } = useCurrentUser()
  const intl = useIntl()
  const theme = useTheme()
  return (
    <EmployeeOnlyModalContainer onClose={onClose} open={open}>
      <Dialog aria-labelledby="email-verification-sent-title" scroll="body" open={open} onClose={onClose}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end"
          }}
        >
          <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box padding={theme.spacing(theme.tokens.spacing.md, theme.tokens.spacing.none)}>
          <SentEmailImage height="5rem" width="5rem" />
        </Box>
        <DialogTitle id="email-verification-sent-title">
          <FormattedMessage
            defaultMessage="{emailVerificationHeaderText}"
            values={{ emailVerificationHeaderText: intl.formatMessage(emailVerificationHeaderText) }}
          />
        </DialogTitle>
        <DialogContent>
          <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
            <EmailVerificationConfirmationSentLink personalEmail={personalEmail} component={DialogContentText} />
            <Box>
              <DialogContentText>
                <FormattedMessage
                  defaultMessage="{emailVerificationExpirationText}"
                  values={{ emailVerificationExpirationText: intl.formatMessage(emailVerificationExpirationText) }}
                />
              </DialogContentText>
              <DialogContentText>
                <FormattedMessage
                  defaultMessage="{emailVerificationFindEmailText}"
                  values={{ emailVerificationFindEmailText: intl.formatMessage(emailVerificationFindEmailText) }}
                />
              </DialogContentText>
            </Box>
            <EmailVerificationContactUs component={DialogContentText} />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}

export function EmailVerificationConfirmationSentLink({
  personalEmail,
  component
}: {
  personalEmail: string
  component: React.ElementType
}): JSX.Element {
  return (
    <Typography component={component}>
      <FormattedMessage
        defaultMessage="We sent you a confirmation email to: <bold>{personalEmail}</bold>"
        values={{
          personalEmail: personalEmail,
          bold: (boldContent) => (
            <>
              <br />
              <Typography component={PiiText} fontWeight="bold">
                {boldContent}
              </Typography>
              <br />
            </>
          )
        }}
      />
    </Typography>
  )
}

export function EmailVerificationContactUs({ component }: { component: React.ElementType }): JSX.Element {
  return (
    <Typography component={component}>
      <FormattedMessage
        defaultMessage="Still can't find it? <link>Contact us</link>"
        values={{
          link: (linkContent) => (
            <Link to="/send-a-message/?verification=personal-email" component={RouterLink}>
              {linkContent}
            </Link>
          )
        }}
      />
    </Typography>
  )
}
