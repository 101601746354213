import React from "react"
import { Box, InternalIcon, LaptopIcon, Link, Tile, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Link as RouterLink } from "react-router-dom"

export function TelemedicineForMenopausePfBanner(): JSX.Element {
  return (
    <Tile>
      <Box
        sx={(theme) => ({ display: "flex", direction: "row", marginBlockEnd: theme.spacing(theme.tokens.spacing.xs) })}
      >
        <LaptopIcon />
        <Typography sx={(theme) => ({ marginInlineStart: theme.spacing(theme.tokens.spacing.xxs) })} variant="h5">
          <FormattedMessage defaultMessage="Carrot virtual menopause clinic" />
        </Typography>
      </Box>
      <Typography sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.md) })}>
        <FormattedMessage defaultMessage="Are you 45 or older? Get virtual menopause care, including hormone replacement therapy (HRT) prescriptions when appropriate, from the comfort of your home." />
      </Typography>
      <Link
        component={RouterLink}
        to="/telemedicine-for-menopause"
        endIcon={<InternalIcon color="primary" fontSize="small" />}
      >
        <FormattedMessage defaultMessage="Learn more about virtual menopause care" />
      </Link>
    </Tile>
  )
}
