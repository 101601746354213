import React, { useContext } from "react"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"

export function ResendPartnerInviteStep(): JSX.Element {
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const { partnerPreferredName, partnerFirstName, preferredName, firstName } = useCurrentUser()
  const memberName = preferredName ?? firstName
  const partnerName = partnerPreferredName ?? partnerFirstName
  return (
    <>
      <DialogTitle>
        <FormattedMessage defaultMessage="Verify {partnerName}'s email" values={{ partnerName }} />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            defaultMessage="An invitation to join {memberName}’s Carrot account was previously sent to {partnerName}’s email. Verify {partnerName}'s email address and create a password to access Carrot’s virtual menopause clinic. "
            values={{ partnerName, memberName }}
          />
        </Typography>
        <Typography>
          <FormattedMessage defaultMessage="Can't find the email? Resend the invite." />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="secondary" component={RouterLink} to="/account#partnerAccessFooterContainer">
          <FormattedMessage defaultMessage="Resend invite" />
        </Button>
        <Button onClick={() => setOpenDialogFlow(false)}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
