import { useCurrentUser } from "#/components/context/user/UserContext"
import { useFlags } from "launchdarkly-react-client-sdk"
import React from "react"
import { useJourney } from "./useJourney"
import useCarrotPlanEmployeeSettings from "./useCarrotPlanEmployeeSettings"
import { useGetCarrotPlanRequestStatus } from "#/components/carrot-plans/hooks/useCarrotPlanRequestStatus"

type UseJourneyChange = {
  shouldShowEngagedJourneyDisplay: boolean
  shouldShowEnrolledJourneyDisplay: boolean
  journeyChangeDataIsLoaded: boolean
  isJourneyComplete: boolean
  resolvedJourney: string | null
  carrotPlanIsOnTheWay: boolean
  appIsUnlockedAndPendingCarrotPlan: boolean
}

export function useJourneyChange(): UseJourneyChange {
  const { isInAppLockdown } = useCurrentUser()
  const { journeyDataIsLoaded, isJourneyComplete, memberHasJourneyType, resolvedJourney } = useJourney()
  const { enrolledJourneySelectionHomePageDisplay, engagedJourneySelectionHomePageDisplay } = useFlags()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    appIsUnlockedAndPendingCarrotPlan
  } = useCarrotPlanEmployeeSettings()
  const cpRequestStatusQuery = useGetCarrotPlanRequestStatus()

  const [shouldShowEngagedJourneyDisplay, setShouldShowEngagedJourneyDisplay] = React.useState(false)
  const [shouldShowEnrolledJourneyDisplay, setShouldShowEnrolledJourneyDisplay] = React.useState(false)
  const [carrotPlanIsOnTheWay, setCarrotPlanIsOnTheWay] = React.useState(false)

  React.useEffect(() => {
    if (!journeyDataIsLoaded) return

    const userIsEngaged =
      engagedJourneySelectionHomePageDisplay && !isInAppLockdown && !isJourneyComplete && memberHasJourneyType
    const userIsEnrolled = enrolledJourneySelectionHomePageDisplay && (isInAppLockdown || isJourneyComplete)

    setCarrotPlanIsOnTheWay(cpRequestStatusQuery.data?.carrotPlanIsOnTheWay)
    setShouldShowEngagedJourneyDisplay(userIsEngaged)
    setShouldShowEnrolledJourneyDisplay(userIsEnrolled && !cpRequestStatusQuery.data?.carrotPlanIsOnTheWay)
  }, [
    journeyDataIsLoaded,
    isJourneyComplete,
    memberHasJourneyType,
    engagedJourneySelectionHomePageDisplay,
    isInAppLockdown,
    enrolledJourneySelectionHomePageDisplay,
    isLoadingEmployeeSettings,
    isErrorEmployeeSettings,
    carrotPlanEmployeeSettings,
    appIsUnlockedAndPendingCarrotPlan,
    cpRequestStatusQuery
  ])

  return {
    shouldShowEnrolledJourneyDisplay,
    shouldShowEngagedJourneyDisplay,
    journeyChangeDataIsLoaded: journeyDataIsLoaded,
    isJourneyComplete,
    resolvedJourney,
    carrotPlanIsOnTheWay,
    appIsUnlockedAndPendingCarrotPlan
  }
}
