import { useGetRoutingInfo } from "../shared/useQueryRoutingInfo"
import { Workflows } from "../steps/medical-questions/workflows"
import { carrotClient } from "../../../utils/CarrotClient"
import { afabJourneys, amabJourneys } from "#/components/carrot-plans/hooks/useMedicalQuestionMenuItemWorkflow"
import { useParams } from "react-router-dom"
import { AMAB_AFAB_MEDICAL_PREFERENCE } from "../steps/journey/AmabAfabMedicalPreferenceStep"

export const useMedicalInfoWorkflow = () => {
  const workflow = useGetWorkflow()
  const { journey } = useParams<{ journey: string }>()
  const medicalQuestionsFromMenuItem = journey != null
  const IsAMAB = medicalQuestionsFromMenuItem
    ? amabJourneys.includes(journey)
    : workflow === Workflows.AMAB_MEDICAL_QUESTIONS
  const IsAFAB = medicalQuestionsFromMenuItem
    ? afabJourneys.includes(journey)
    : workflow === Workflows.AFAB_MEDICAL_QUESTIONS
  const decline = workflow === Workflows.DECLINE_MEDICAL_QUESTIONS
  return { workflow, IsAMAB, IsAFAB, decline }
}

function useGetWorkflow(): Workflows {
  const { data: routingData } = useGetRoutingInfo()
  const journey = routingData?.journey
  const medicalPreference = routingData?.amabAfabMedicalPreference
  const preservationInterest = routingData?.preservationInterest
  const afabJourneys = ["PREGNANT", "MENOPAUSE"]
  const medicalPreferenceJourneys = ["ASSISTED_REPRODUCTION", "PRESERVATION", "TRY_PREGNANT", "LOW_TESTOSTERONE"]

  if (medicalPreferenceJourneys.includes(journey)) {
    if (journey === "PRESERVATION") {
      if (preservationInterest === "PRESERVE_SPERM") {
        return Workflows.AMAB_MEDICAL_QUESTIONS
      } else if (preservationInterest === "PRESERVE_EGGS") {
        return Workflows.AFAB_MEDICAL_QUESTIONS
      }
    }
    if (medicalPreference === AMAB_AFAB_MEDICAL_PREFERENCE.AFAB) {
      return Workflows.AFAB_MEDICAL_QUESTIONS
    } else if (medicalPreference === AMAB_AFAB_MEDICAL_PREFERENCE.AMAB) {
      return Workflows.AMAB_MEDICAL_QUESTIONS
    }
  } else if (afabJourneys.includes(journey)) {
    return Workflows.AFAB_MEDICAL_QUESTIONS
  } else {
    return Workflows.DECLINE_MEDICAL_QUESTIONS
  }
}

export const useCreateZendeskTicket = () => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'stepId' implicitly has an 'any' type.
  const createZendeskTicket = async (stepId) => {
    await carrotClient.createZendeskTicketForCPMedicalQuestions(stepId)
  }
  return { createZendeskTicket }
}
