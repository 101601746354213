import React from "react"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { Progress } from "../../views/modal/Progress"
import { ArrowSubmitButton, ReimbursementDialogFraming, BackArrow } from "../shared/layouts"
import { useStateMachine } from "../../context/stateMachine/StateMachineV2"
import { defineMessage, FormattedMessage, MessageDescriptor, useIntl } from "react-intl"
import { JourneyTypes } from "./ExpenseJourneyTypes"
import CarrotRxMedicationCard from "./modules/CarrotRxMedicationCard"
import { Box, DialogTitle } from "@carrotfertility/carotene-core"
import { DialogActionsBar, Form, FormOnSubmitHandler, FormSelect, useWatch } from "@carrotfertility/carotene-core-x"

type MedicationOption = {
  label: MessageDescriptor
  value: string
}

const medicationOptions: MedicationOption[] = [
  {
    value: "YES",
    label: defineMessage({ defaultMessage: "Yes" })
  },
  {
    value: "NO",
    label: defineMessage({ defaultMessage: "No" })
  }
]

const CarrotRxCardWrapper = (): JSX.Element => {
  const relatedToMedication = useWatch({ name: "relatedToMedication" })
  if (relatedToMedication !== "YES") return null

  return <CarrotRxMedicationCard />
}

export const AboutThisExpenseRelatedToMedication = (): JSX.Element => {
  const { updateRelatedToMedication, state } = useReimbursementContext()
  const { send, back } = useStateMachine()
  const intl = useIntl()

  const formattedRelatedToMedicationOptions = medicationOptions.map((option) => ({
    ...option,
    label: intl.formatMessage(option.label)
  }))

  const handleOnSubmit: FormOnSubmitHandler<{ relatedToMedication: string }> = (values): void => {
    const answerAsSelectOption = formattedRelatedToMedicationOptions.find((j) => j.value === values.relatedToMedication)

    updateRelatedToMedication(answerAsSelectOption)

    if (state.expenseRelatedTo?.value === JourneyTypes.GC) {
      send("ADDITIONAL_INFO_ANYTHING_ELSE")
    } else {
      send("")
    }
  }

  return (
    <Form
      onSubmit={handleOnSubmit}
      defaultValues={{
        relatedToMedication: state.relatedToMedication?.value
      }}
    >
      <Progress
        numSteps={3}
        step={2}
        label={intl.formatMessage({ defaultMessage: "About This Expense" })}
        inactiveStepBackgroundColor="#DBE6EE"
      />
      <BackArrow onClick={back} />
      <ReimbursementDialogFraming>
        <DialogTitle id="step-heading">
          <FormattedMessage defaultMessage="Is this related to medication expenses?" />
        </DialogTitle>
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(theme.tokens.spacing.lg) }}>
          <FormSelect
            aria-labelledby="step-heading"
            name="relatedToMedication"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" })
              }
            }}
            options={formattedRelatedToMedicationOptions}
            label={intl.formatMessage({ defaultMessage: "Select option" })}
          />
          <CarrotRxCardWrapper />
        </Box>
      </ReimbursementDialogFraming>
      <DialogActionsBar
        primaryAction={
          <ArrowSubmitButton>
            <FormattedMessage defaultMessage="Continue" />
          </ArrowSubmitButton>
        }
      />
    </Form>
  )
}
