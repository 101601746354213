import React, { useEffect, useState } from "react"
import {
  Box,
  InternalIcon,
  LaptopIcon,
  Link,
  ListItemText,
  Stack,
  Typography,
  UnorderedList,
  UnorderedListItem,
  useTheme
} from "@carrotfertility/carotene-core"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useHistory } from "react-router"
import usePartnerAccess, { useGetPartnerInviteStatus } from "#/components/partner-access/hooks/usePartnerAccess"

import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader } from "#/features/global-ui/page-header"
import { FormattedMessage, useIntl } from "react-intl"
import { Link as RouterLink } from "react-router-dom"
import { PartnerInviteStatus } from "#/components/partner-access/partnerAccessTypes"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import { Title } from "#/services/page-title/Title"
import { EnterTelemedicineForMenopauseDialog } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"

export function TelemedicineForMenopauseLandingPage(): JSX.Element {
  const { showTelemedicineForMenopause } = useFlags()
  const history = useHistory()
  const [openDialogFlow, setOpenDialogFlow] = useState(false)
  const intl = useIntl()
  const theme = useTheme()
  const { showPartnerAccess } = usePartnerAccess()

  useEffect(() => {
    if (!showTelemedicineForMenopause) {
      history.push("/")
    }
  }, [history, showTelemedicineForMenopause])

  return (
    <PageLayout
      variant="sidebar"
      sidebar={<></>}
      header={
        <PageHeader
          startGlyph={<LaptopIcon fontSize="large" />}
          pageTitle={<FormattedMessage defaultMessage="Carrot virtual menopause clinic" />}
        />
      }
    >
      <Title title={intl.formatMessage({ defaultMessage: "Carrot virtual menopause clinic" })} />
      <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
        <Typography>
          <FormattedMessage
            defaultMessage="Carrot has partnered with Wheel to provide convenient, on-demand menopause telemedicine services for members who are 45 or older {partnerText}"
            values={{
              partnerText: showPartnerAccess ? intl.formatMessage({ defaultMessage: " and their partners." }) : null
            }}
          />
        </Typography>
        <Box>
          <Link
            component={"button"}
            endIcon={<InternalIcon fontSize="small" color="primary" />}
            onClick={() => setOpenDialogFlow(true)}
          >
            <FormattedMessage defaultMessage="Schedule a virtual visit with Carrot’s menopause clinic" />
          </Link>
        </Box>
        <Box component={"img"} src={"/images/woman-at-table.png"} alt="woman-at-table" height="180px" width="100%" />
        <Box>
          <GetSupportUnorderedList />
          <WhoCanUseVirtualMenopauseClinic {...{ showPartnerAccess }} />
          <PayingForCareUnorderedList />
          <Link component={RouterLink} to={"/your-benefit-guide/additional-at-home-care/virtual-menopause-clinic"}>
            <FormattedMessage defaultMessage="Learn more in your benefit guide" />
          </Link>
        </Box>
        <ScheduleVisitCard {...{ setOpenDialogFlow }} />
      </Stack>
      <EnterTelemedicineForMenopauseDialog {...{ openDialogFlow, setOpenDialogFlow }} />
    </PageLayout>
  )
}

function GetSupportUnorderedList(): JSX.Element {
  const intl = useIntl()
  return (
    <>
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Get support with:" />
      </Typography>
      <UnorderedList>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Telehealth appointments"
            })}
          />
        </UnorderedListItem>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Laboratory testing"
            })}
          />
        </UnorderedListItem>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Hormone replacement therapy (HRT) prescriptions when appropriate"
            })}
          />
        </UnorderedListItem>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "Non-hormonal treatment options"
            })}
          />
        </UnorderedListItem>
      </UnorderedList>
    </>
  )
}

function WhoCanUseVirtualMenopauseClinic({ showPartnerAccess }: { showPartnerAccess: boolean }): JSX.Element {
  const intl = useIntl()
  const { inviteData, isInviteStatusLoading } = useGetPartnerInviteStatus()
  return (
    <>
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Who can use Carrot’s virtual menopause clinic" />
      </Typography>
      <UnorderedList>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage: "You"
            })}
          />
        </UnorderedListItem>
        {showPartnerAccess ? (
          <UnorderedListItem>
            <ListItemText
              primary={intl.formatMessage({
                defaultMessage: "Your partner"
              })}
            />
          </UnorderedListItem>
        ) : null}
      </UnorderedList>
      {showPartnerAccess && !isInviteStatusLoading && inviteData.status === PartnerInviteStatus.NONE ? (
        <Typography fontStyle="italic" sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.lg) })}>
          <FormattedMessage
            defaultMessage="For your partner to use Wheel, you must <link>invite them to access your Carrot account.</link>"
            values={{
              link: (linkContent) => (
                <Link color="inherit" component={RouterLink} to={"/account#partnerAccessFooterContainer"}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      ) : null}
    </>
  )
}

function PayingForCareUnorderedList(): JSX.Element {
  const intl = useIntl()
  const { data: benefitData, isLoading: isBenefitLoading } = useBenefit()
  return (
    <>
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Paying for care" />
      </Typography>
      <UnorderedList>
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage:
                "Wheel providers may be in network with your insurance. You will be able to verify coverage before scheduling your fist virtual visit."
            })}
          />
        </UnorderedListItem>
        {!isBenefitLoading && benefitData.journeys.menopause.hasEligibleExpenses ? (
          <UnorderedListItem>
            <ListItemText
              primary={intl.formatMessage(
                {
                  defaultMessage:
                    "You may be able to use your Carrot funds for <link>eligible menopause expenses</link> not covered by insurance. Check your benefit guide for details on menopause coverage. "
                },
                {
                  link: (linkContent) => (
                    <Link color="inherit" component={RouterLink} to={"/your-benefit-guide/coverage/menopause"}>
                      {linkContent}
                    </Link>
                  )
                }
              )}
            />
          </UnorderedListItem>
        ) : null}
        <UnorderedListItem>
          <ListItemText
            primary={intl.formatMessage({
              defaultMessage:
                "You can pay for your care out of pocket with a credit card. Visits cost $135 for Initial and $90 for follow-ups."
            })}
          />
        </UnorderedListItem>
      </UnorderedList>
    </>
  )
}

function ScheduleVisitCard({ setOpenDialogFlow }: { setOpenDialogFlow: (arg: boolean) => void }): JSX.Element {
  return (
    <Box
      gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
      sx={(theme) => ({
        borderRadius: theme.tokens.borderRadius.md,
        padding: theme.spacing(theme.tokens.spacing.lg),
        border: `2px solid ${theme.palette.divider}`
      })}
    >
      <Typography variant="h5">
        <FormattedMessage defaultMessage="Carrot virtual menopause clinic" />
      </Typography>
      <Link
        component={"button"}
        endIcon={<InternalIcon fontSize="small" color="primary" />}
        onClick={() => setOpenDialogFlow(true)}
      >
        <FormattedMessage defaultMessage="Schedule a virtual visit with Wheel" />
      </Link>
    </Box>
  )
}
