import { Steps } from "./step-library"
import WhatKindOfJourneyStep from "./WhatKindOfJourneyStep"
import WhereAreYouInJourneyStep from "./WhereAreYouInJourneyStep"
import { NameOfAgencyStep, NameOfClinicAgencyAttorneyStep, NameOfClinicStep } from "./AgencyClinicStep"
import MostHelpfulStep from "./MostHelpfulStep"
import IvfInformationStep from "./IvfInformationStep"
import WhereAreYouInJourneyDecisionStep from "./WhereAreYouInJourneyDecisionStep"
import WhatKindOfJourneyShareBackgroundDecisionStep from "./WhatKindOfJourneyShareBackgroundDecisionStep"
import PersonOfColorShareBackgroundStep from "./PersonOfColorShareBackgroundStep"
import MostHelpfulDecisionStep from "./MostHelpfulDecisionStep"
import WhatKindOfJourneyFinalDecisionStep from "./WhatKindOfJourneyFinalDecisionStep"
import AdoptionPathwayStep from "./AdoptionPathwayStep"
import DoAnyOfTheFollowingApplyStep from "./DoAnyOfTheFollowingApplyStep"
import { GestationalCarrierDeliveryStep, GestationalCarrierInMindStep } from "./GestationalCarrierStep"
import WhatKindOfJourneyPreservationDecisionStep from "./WhatKindOfJourneyPreservationDecisionStep"
import WhereAreYouInJourneyIVFDecisionStep from "./WhereAreYouInJourneyIVFDecisionStep"
import PreservationStep from "./PreservationStep"
import IsThereAnythingElseStep from "./IsThereAnythingElseStep"
import ProcessInitialCarrotPlanChatRequestedStep from "./ProcessInitialCarrotPlanChatRequestedStep"
import CarrotPlanOnTheWayStep from "./CarrotPlanOnTheWayStep"
import { ArtMedicalRecordsConsentStep, PreservationMedicalRecordsConsentStep } from "./MedicalRecordsConsentStep"
import {
  UsMostHelpfulIsUsStep,
  FertilityPreservationIsUsDecisionStep,
  AssistedReproductionIsUsDecisionStep,
  AttemptingToGetPregnantIsUsDecisionStep,
  CurrentlyPregnantIsUsDecisionStep,
  MenopauseIsUsDecisionStep,
  LowTestosteroneIsUsDecisionStep
} from "./IsUsDecisionStep"
import ShowMedicalQuestionsDecisionStep from "../medical-questions/ShowMedicalQuestionsDecisionStep"
import AmabOrAfabDecisionStep from "../medical-questions/AmabOrAfabDecisionStep"
import { Steps as MedicalSteps } from "../medical-questions/workflows"
import MostHelpFulPathwayFindingProviderDecisionStep from "./MostHelpFulPathwayFindingProviderDecisionStep"
import AmabAfabMedicalPreferenceStep from "./AmabAfabMedicalPreferenceStep"
import MedicalPreferenceStep from "./MedicalPreferenceStep"
import DueDateStep from "./DueDateStep"
import DeliveryDateStep from "./DeliveryDateStep"
import NumberOfChildrenStep from "./NumberOfChildrenStep"
import GestationalCarrierDecisionStep from "./GestationalCarrierDecisionStep"
import IvfTreatmentStartStep from "./IvfTreatmentStartStep"
import ArtMedicationStartDateStep from "./ArtMedicationStartDateStep"
import PreservationMedicationStartDateStep from "./PreservationMedicationStartDateStep"
import SocialDeterminantsOfHealthStep from "./SocialDeterminantsOfHealthStep"
import SocialDeterminantsOfHealthDecisionStep from "./SocialDeterminantsOfHealthDecisionStep"
import PreservationCycleTestingStep from "#/components/carrot-plans/steps/journey/PreservationCycleTestingStep"
import EggRetrievalDateStep from "#/components/carrot-plans/steps/journey/EggRetrievalDateStep"
import WhereAreYouInJourneyPreservationDecisionStep from "#/components/carrot-plans/steps/journey/WhereAreYouInJourneyPreservationDecisionStep"
import { HasRecentlyReceivedPreservationDecisionStep } from "#/components/carrot-plans/steps/journey/HasRecentlyReceivedPreservationDecisionStep"
import WhatKindOfJourneyIVFDecisionStep from "./WhatKindOfJourneyIVFDecisionStep"

export enum Workflows {
  JOURNEY = "Journey"
}

// prettier-ignore
export const journeySteps = [
  { name: Steps.WHAT_KIND_OF_JOURNEY, component: WhatKindOfJourneyStep },
  { name: Steps.WHAT_KIND_OF_JOURNEY_IVF_DECISION, component: WhatKindOfJourneyIVFDecisionStep, decisionStep: true },
  { name: Steps.WHERE_ARE_YOU_IN_JOURNEY, component: WhereAreYouInJourneyStep },
  { name: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION, component: WhereAreYouInJourneyDecisionStep, decisionStep: true },
  { name: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION, component: WhereAreYouInJourneyIVFDecisionStep, decisionStep: true },
  { name: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION, component: WhereAreYouInJourneyPreservationDecisionStep, decisionStep: true },
  { name: Steps.NAME_OF_AGENCY, component: NameOfAgencyStep },
  { name: Steps.NAME_OF_CLINIC_AGENCY_ATTORNEY, component: NameOfClinicAgencyAttorneyStep },
  { name: Steps.NAME_OF_CLINIC, component: NameOfClinicStep },
  { name: Steps.MOST_HELPFUL, component: MostHelpfulStep },
  { name: Steps.US_MOST_HELPFUL, component: UsMostHelpfulIsUsStep, decisionStep: true },
  { name: Steps.IVF_INFORMATION, component: IvfInformationStep },
  { name: Steps.IVF_TREATMENT_START, component: IvfTreatmentStartStep },
  { name: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, component: WhatKindOfJourneyShareBackgroundDecisionStep, decisionStep: true },
  { name: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND, component: PersonOfColorShareBackgroundStep },
  { name: Steps.MOST_HELPFUL_DECISION, component: MostHelpfulDecisionStep, decisionStep: true },
  { name: Steps.MOST_HELPFUL_PATHWAY_FINDING_PROVIDER_DECISION, component: MostHelpFulPathwayFindingProviderDecisionStep, decisionStep: true },
  { name: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION, component: WhatKindOfJourneyFinalDecisionStep, decisionStep: true },
  { name: Steps.ADOPTION_PATHWAY, component: AdoptionPathwayStep },
  { name: Steps.GESTATIONAL_CARRIER_DECISION, component: GestationalCarrierDecisionStep, decisionStep: true },
  { name: Steps.GESTATIONAL_CARRIER_IN_MIND, component: GestationalCarrierInMindStep },
  { name: Steps.GESTATIONAL_CARRIER_DELIVERY, component: GestationalCarrierDeliveryStep },
  { name: Steps.DO_ANY_APPLY, component: DoAnyOfTheFollowingApplyStep },
  { name: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION, component: WhatKindOfJourneyPreservationDecisionStep, decisionStep: true },
  { name: Steps.PRESERVATION, component: PreservationStep },
  { name: Steps.FERTILITY_PRESERVATION_IS_US_DECISION, component: FertilityPreservationIsUsDecisionStep, decisionStep: true },
  { name: Steps.ASSISTED_REPRODUCTION_IS_US_DECISION, component: AssistedReproductionIsUsDecisionStep, decisionStep: true },
  { name: Steps.ATTEMPTING_TO_GET_PREGNANT_IS_US_DECISION, component: AttemptingToGetPregnantIsUsDecisionStep,  decisionStep: true},
  { name: Steps.CURRENTLY_PREGNANT_IS_US_DECISION, component: CurrentlyPregnantIsUsDecisionStep, decisionStep: true },
  { name: Steps.MENOPAUSE_IS_US_DECISION, component: MenopauseIsUsDecisionStep, decisionStep: true },
  { name: Steps.LOW_TESTOSTERONE_IS_US_DECISION, component: LowTestosteroneIsUsDecisionStep, decisionStep: true },
  { name: Steps.ANYTHING_ELSE, component: IsThereAnythingElseStep },
  { name: Steps.PROCESS_CREATE_CARROT_PLAN, component: ProcessInitialCarrotPlanChatRequestedStep },
  { name: Steps.MESSAGE_WONDERFUL, component: CarrotPlanOnTheWayStep },
  { name: Steps.ART_MEDICAL_RECORDS_CONSENT, component: ArtMedicalRecordsConsentStep },
  { name: Steps.PRESERVATION_MEDICAL_RECORDS_CONSENT, component: PreservationMedicalRecordsConsentStep },
  { name: Steps.SHOW_MEDICAL_QUESTIONS_DECISION, component: ShowMedicalQuestionsDecisionStep, decisionStep: true },
  { name: Steps.AMAB_OR_AFAB_DECISION, component: AmabOrAfabDecisionStep, decisionStep: true },
  { name: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP, component: AmabAfabMedicalPreferenceStep },
  { name: Steps.MEDICAL_PREFERENCE_STEP, component: MedicalPreferenceStep },
  { name: Steps.DUE_DATE, component: DueDateStep },
  { name: Steps.DELIVERY_DATE, component: DeliveryDateStep },
  { name: Steps.NUMBER_OF_CHILDREN, component: NumberOfChildrenStep },
  { name: Steps.ART_MEDICATION_START_DATE, component: ArtMedicationStartDateStep },
  { name: Steps.PRESERVATION_MEDICATION_START_DATE, component: PreservationMedicationStartDateStep },
  { name: Steps.SOCIAL_DETERMINANTS_OF_HEALTH, component: SocialDeterminantsOfHealthStep },
  { name: Steps.SOCIAL_DETERMINANTS_OF_HEALTH_DECISION, component: SocialDeterminantsOfHealthDecisionStep, decisionStep: true },
  { name: Steps.PRESERVATION_CYCLE_TESTING, component: PreservationCycleTestingStep },
  { name: Steps.EGG_RETRIEVAL_DATE, component: EggRetrievalDateStep },
  { name: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION, component: HasRecentlyReceivedPreservationDecisionStep, decisionStep: true },


]

// prettier-ignore
export const journeyFlow: (JourneyFlowTransition)[] = [
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "ADOPTION",                            goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "GESTATIONAL",                         goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "PRESERVATION",                        goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "TRY_PREGNANT",                        goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "EXPLORING",                           goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "PREGNANT",                            goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "PARENTING",                           goto: Steps.NUMBER_OF_CHILDREN },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "ASSISTED_REPRODUCTION",               goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "MENOPAUSE",                           goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "LOW_TESTOSTERONE",                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "GENDER_AFFIRMING_CARE",               goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY,                           given: "SOMETHING_ELSE",                      goto: Steps.ANYTHING_ELSE },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY,                       given: "",                                    goto: Steps.WHAT_KIND_OF_JOURNEY_IVF_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY,                       given: "PRESERVATION_STARTED",                goto: Steps.PRESERVATION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY,                       given: "PRESERVATION_READY",                  goto: Steps.PRESERVATION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY,                       given: "PRESERVATION_RECENTLY_RECEIVED",      goto: Steps.PRESERVATION },
  { at: Steps.NUMBER_OF_CHILDREN,                             given: "",                                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY },
  { at: Steps.WHAT_KIND_OF_JOURNEY_IVF_DECISION,              given: "ASSISTED_REPRODUCTION",               goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION },
  { at: Steps.WHAT_KIND_OF_JOURNEY_IVF_DECISION,              given: "OTHER_ANSWERS",                       goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION,          given: "ALREADY_STARTED_IUI_CYCLE",           goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION,          given: "ALREADY_STARTED_IVF_CYCLE",           goto: Steps.ART_MEDICATION_START_DATE },
  { at: Steps.ART_MEDICATION_START_DATE,                      given: "",                                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION,          given: "RECENTLY_COMPLETED_IUI_CYCLE",        goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION,          given: "RECENTLY_COMPLETED_IVF_CYCLE",        goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION,          given: "GETTING_READY_START_IVF",             goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.PRESERVATION,                                   given: "PRESERVE_EGGS",                       goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION },
  { at: Steps.PRESERVATION,                                   given: "PRESERVE_EMBRYOS",                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION },
  { at: Steps.PRESERVATION,                                   given: "PRESERVE_EGGS_EMBRYOS_OR_SPERM",      goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION },
  { at: Steps.PRESERVATION,                                   given: "PRESERVE_SPERM",                      goto: Steps.MOST_HELPFUL},
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION,          given: "OTHER_ANSWERS",                       goto: Steps.IVF_INFORMATION },
  { at: Steps.IVF_INFORMATION,                                given: "YES",                                 goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.IVF_INFORMATION,                                given: "NO",                                  goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "AGENCY",                              goto: Steps.NAME_OF_AGENCY },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "CLINIC_AGENCY_ATTORNEY",              goto: Steps.NAME_OF_CLINIC_AGENCY_ATTORNEY },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "CLINIC",                              goto: Steps.NAME_OF_CLINIC },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "IVF_TREATMENT_START",                 goto: Steps.IVF_TREATMENT_START },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "CURRENTLY_PREGNANT",                  goto: Steps.DUE_DATE },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "POSTPARTUM",                          goto: Steps.DELIVERY_DATE },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION, given: "EXPLORING",                           goto: Steps.MOST_HELPFUL},
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION, given: "PRESERVATION_STARTED",                goto: Steps.PRESERVATION_MEDICATION_START_DATE },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION, given: "PRESERVATION_READY",                  goto: Steps.PRESERVATION_CYCLE_TESTING },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION, given: "PRESERVATION_RECENTLY_RECEIVED",      goto: Steps.EGG_RETRIEVAL_DATE },
  { at: Steps.PRESERVATION_MEDICATION_START_DATE,             given: "",                                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.PRESERVATION_CYCLE_TESTING,                     given: "",                                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.EGG_RETRIEVAL_DATE,                             given: "",                                    goto: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION },
  { at: Steps.WHERE_ARE_YOU_IN_JOURNEY_DECISION,              given: "OTHER_ANSWERS",                       goto: Steps.MOST_HELPFUL },
  { at: Steps.DUE_DATE,                                       given: "",                                    goto: Steps.MOST_HELPFUL },
  { at: Steps.DELIVERY_DATE,                                  given: "",                                    goto: Steps.MOST_HELPFUL },
  { at: Steps.NAME_OF_AGENCY,                                 given: "",                                    goto: Steps.MOST_HELPFUL },
  { at: Steps.NAME_OF_CLINIC_AGENCY_ATTORNEY,                 given: "",                                    goto: Steps.MOST_HELPFUL },
  { at: Steps.NAME_OF_CLINIC,                                 given: "",                                    goto: Steps.MOST_HELPFUL},
  { at: Steps.MOST_HELPFUL,                                   given: "",                                    goto: Steps.US_MOST_HELPFUL},
  { at: Steps.IVF_TREATMENT_START,                            given: "",                                    goto: Steps.NAME_OF_CLINIC },
  { at: Steps.US_MOST_HELPFUL,                                given: "NO",                                  goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.US_MOST_HELPFUL,                                given: "YES",                                 goto: Steps.SOCIAL_DETERMINANTS_OF_HEALTH_DECISION},
  { at: Steps.SOCIAL_DETERMINANTS_OF_HEALTH_DECISION,         given: "YES",                                 goto: Steps.SOCIAL_DETERMINANTS_OF_HEALTH},
  { at: Steps.SOCIAL_DETERMINANTS_OF_HEALTH_DECISION,         given: "NO",                                  goto: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION},
  { at: Steps.SOCIAL_DETERMINANTS_OF_HEALTH,                  given: "",                                    goto: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "PRESERVATION",                        goto: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "ASSISTED_REPRODUCTION",               goto: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "TRY_PREGNANT",                        goto: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "EXPLORING",                           goto: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "SOMETHING_ELSE",                      goto: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "MENOPAUSE",                           goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "PARENTING",                           goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "LOW_TESTOSTERONE",                    goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "ADOPTION",                            goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "GESTATIONAL",                         goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "PREGNANT",                            goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION, given: "GENDER_AFFIRMING_CARE",               goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.PERSON_OF_COLOR_SHARE_BACKGROUND,               given: "",                                    goto: Steps.MOST_HELPFUL_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "DISCUSS_OPTIONS",                     goto: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "HELP_PATHWAY",                        goto: Steps.MOST_HELPFUL_PATHWAY_FINDING_PROVIDER_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "HELP_FINDING_PROVIDER",               goto: Steps.MOST_HELPFUL_PATHWAY_FINDING_PROVIDER_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "CHAT_FERTILITY",                      goto: Steps.DO_ANY_APPLY},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "CHAT_LIFESTYLE",                      goto: Steps.DO_ANY_APPLY},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "CHAT_HEALTH",                         goto: Steps.DO_ANY_APPLY},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "LGBTQ_FAMILY",                        goto: Steps.ANYTHING_ELSE},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "ANYTHING_ELSE",                       goto: Steps.ANYTHING_ELSE},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "OTHER_ANSWERS",                       goto: Steps.ANYTHING_ELSE},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "MENOPAUSE",                           goto: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "LOW_TESTOSTERONE",                    goto: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "GENDER_AFFIRMING_CARE",               goto: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION},
  { at: Steps.MOST_HELPFUL_DECISION,                          given: "PREGNANT",                            goto: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION},
  { at: Steps.MOST_HELPFUL_PATHWAY_FINDING_PROVIDER_DECISION, given: "YES",                                 goto: Steps.ANYTHING_ELSE},
  { at: Steps.MOST_HELPFUL_PATHWAY_FINDING_PROVIDER_DECISION, given: "NO",                                  goto: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "ADOPTION",                            goto: Steps.ADOPTION_PATHWAY},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "GESTATIONAL",                         goto: Steps.GESTATIONAL_CARRIER_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "PRESERVATION",                        goto: Steps.DO_ANY_APPLY},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "ASSISTED_REPRODUCTION",               goto: Steps.DO_ANY_APPLY},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "TRY_PREGNANT",                        goto: Steps.DO_ANY_APPLY},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "MENOPAUSE",                           goto: Steps.MENOPAUSE_IS_US_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "LOW_TESTOSTERONE",                    goto: Steps.LOW_TESTOSTERONE_IS_US_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "GENDER_AFFIRMING_CARE",               goto: Steps.ANYTHING_ELSE},
  { at: Steps.WHAT_KIND_OF_JOURNEY_FINAL_DECISION,            given: "PREGNANT",                            goto: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION},
  { at: Steps.ADOPTION_PATHWAY,                               given: "",                                    goto: Steps.ANYTHING_ELSE},
  { at: Steps.GESTATIONAL_CARRIER_DECISION,                   given: "YES",                                 goto: Steps.GESTATIONAL_CARRIER_DELIVERY},
  { at: Steps.GESTATIONAL_CARRIER_DECISION,                   given: "NO",                                  goto: Steps.GESTATIONAL_CARRIER_IN_MIND},
  { at: Steps.GESTATIONAL_CARRIER_IN_MIND,                    given: "",                                    goto: Steps.GESTATIONAL_CARRIER_DELIVERY},
  { at: Steps.GESTATIONAL_CARRIER_DELIVERY,                   given: "",                                    goto: Steps.ANYTHING_ELSE},
  { at: Steps.DO_ANY_APPLY,                                   given: "SELECTED",                            goto: Steps.ANYTHING_ELSE},
  { at: Steps.DO_ANY_APPLY,                                   given: "SKIP",                                goto: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION},
  { at: Steps.DO_ANY_APPLY,                                   given: "NONE_SELECTED",                       goto: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION,     given: "PRESERVATION",                        goto: Steps.FERTILITY_PRESERVATION_IS_US_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION,     given: "ASSISTED_REPRODUCTION",               goto: Steps.ASSISTED_REPRODUCTION_IS_US_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION,     given: "TRY_PREGNANT",                        goto: Steps.ATTEMPTING_TO_GET_PREGNANT_IS_US_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION,     given: "EXPLORING",                           goto: Steps.ANYTHING_ELSE},
  { at: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION,     given: "PREGNANT",                            goto: Steps.CURRENTLY_PREGNANT_IS_US_DECISION},
  { at: Steps.WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION,     given: "OTHER_ANSWERS",                       goto: Steps.ANYTHING_ELSE},
  { at: Steps.FERTILITY_PRESERVATION_IS_US_DECISION,          given: "YES",                                 goto: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION},
  { at: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION,        given: "ANYTHING_ELSE",                       goto: Steps.ANYTHING_ELSE},
  { at: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION,        given: "PRESERVE_SPERM",                      goto: Steps.MEDICAL_PREFERENCE_STEP},
  { at: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION,        given: "PRESERVE_EGGS",                       goto: Steps.MEDICAL_PREFERENCE_STEP},
  { at: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION,        given: "PRESERVE_EMBRYOS",                    goto: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP},
  { at: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION,        given: "PRESERVE_EGGS_EMBRYOS_OR_SPERM",      goto: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP},
  { at: Steps.RECENTLY_RECEIVED_PRESERVATION_DECISION,        given: "EXPLORING_PRESERVATION",              goto: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP},
  { at: Steps.FERTILITY_PRESERVATION_IS_US_DECISION,          given: "NO",                                  goto: Steps.ANYTHING_ELSE},
  { at: Steps.ASSISTED_REPRODUCTION_IS_US_DECISION,           given: "YES",                                 goto: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP},
  { at: Steps.ASSISTED_REPRODUCTION_IS_US_DECISION,           given: "NO",                                  goto: Steps.ANYTHING_ELSE},
  { at: Steps.ATTEMPTING_TO_GET_PREGNANT_IS_US_DECISION,      given: "YES",                                 goto: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP},
  { at: Steps.ATTEMPTING_TO_GET_PREGNANT_IS_US_DECISION,      given: "NO",                                  goto: Steps.ANYTHING_ELSE},
  { at: Steps.CURRENTLY_PREGNANT_IS_US_DECISION,              given: "YES",                                 goto: Steps.MEDICAL_PREFERENCE_STEP},
  { at: Steps.CURRENTLY_PREGNANT_IS_US_DECISION,              given: "NO",                                  goto: Steps.ANYTHING_ELSE},
  { at: Steps.MENOPAUSE_IS_US_DECISION,                       given: "YES",                                 goto: Steps.MEDICAL_PREFERENCE_STEP},
  { at: Steps.MENOPAUSE_IS_US_DECISION,                       given: "NO",                                  goto: Steps.ANYTHING_ELSE},
  { at: Steps.LOW_TESTOSTERONE_IS_US_DECISION,                given: "YES",                                 goto: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP},
  { at: Steps.LOW_TESTOSTERONE_IS_US_DECISION,                given: "NO",                                  goto: Steps.ANYTHING_ELSE},
  { at: Steps.PROCESS_CREATE_CARROT_PLAN,                     given: "",                                    goto: Steps.MESSAGE_WONDERFUL},
  { at: Steps.MESSAGE_WONDERFUL,                              given: "ART_MEDICAL_RECORDS_CONSENT",         goto: Steps.ART_MEDICAL_RECORDS_CONSENT},
  { at: Steps.MESSAGE_WONDERFUL,                              given: "PRESERVATION_MEDICAL_RECORDS_CONSENT",goto: Steps.PRESERVATION_MEDICAL_RECORDS_CONSENT},
  { at: Steps.AMAB_AFAB_MEDICAL_PREFERENCE_STEP,              given: "",                                    goto: Steps.SHOW_MEDICAL_QUESTIONS_DECISION},
  { at: Steps.MEDICAL_PREFERENCE_STEP,                        given: "",                                    goto: Steps.SHOW_MEDICAL_QUESTIONS_DECISION},
  { at: Steps.SHOW_MEDICAL_QUESTIONS_DECISION,                given: "NO",                                  goto: Steps.ANYTHING_ELSE },
  { at: Steps.SHOW_MEDICAL_QUESTIONS_DECISION,                given: "YES",                                 goto: Steps.AMAB_OR_AFAB_DECISION },
  { at: Steps.AMAB_OR_AFAB_DECISION,                          given: "DECLINE",                             goto: Steps.ANYTHING_ELSE },
  { at: Steps.ANYTHING_ELSE,                                  given: "",                                    goto: Steps.PROCESS_CREATE_CARROT_PLAN},
  { at: Steps.AMAB_OR_AFAB_DECISION,                          given: "AMAB",                                goto: MedicalSteps.WELCOME, sectionName: "Medical" },
  { at: Steps.AMAB_OR_AFAB_DECISION,                          given: "AFAB",                                goto: MedicalSteps.WELCOME, sectionName: "Medical" },
]

export type JourneyFlowTransition =
  | { at: Steps; given: string; goto: Steps; sectionName?: undefined }
  | { at: Steps; given: string; goto: MedicalSteps; sectionName: string }

export const journeyWorkflows = [{ name: Workflows.JOURNEY, workflow: journeyFlow }]
