import React, { useLayoutEffect, useRef } from "react"
import { Provider } from "./types/Providers"
import { ResultsSidebar } from "./components/sidebar/ResultsSidebar"
import { ResultDisplay } from "./components/results/ResultDisplay"
import { Redirect, useLocation } from "react-router-dom"
import useGetProviders from "./hooks/useGetProviders"
import { useUserContext } from "./context/UserContext"
import { ProviderFinderParam, providerFinderRootPath } from "./utils/providerFinderSearchUrl"
import { useMapViewContext } from "./context/MapViewContext"
import { Loading } from "./components/Loading"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { Helmet } from "react-helmet"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Stack, useTheme } from "@carrotfertility/carotene-core"
import { PageLayout } from "#/features/global-ui/page-layout"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"

export function ResultsPage({ benefit }: { benefit: Benefit }): JSX.Element {
  const intl = useIntl()
  const { search: geocodeQueryString } = useLocation()
  const { companyCountryCode } = useUserContext()
  const params = new URLSearchParams(geocodeQueryString)
  const locationCountryCode = params.get(ProviderFinderParam.Country)
  const searchLocation = params.get(ProviderFinderParam.Search)
  const keyword = params.get(ProviderFinderParam.Keyword)
  params.set("companyCountryCode", companyCountryCode)
  const { data, isLoading, isError } = useGetProviders(params)
  const { showMapView } = useMapViewContext()
  const theme = useTheme()
  const pageTitle = showMapView ? "Map view of providers" : "List of providers"

  const currentPage = Number(params.get(ProviderFinderParam.Page))
  const priorPage = useRef(null)
  useLayoutEffect(() => {
    if (currentPage !== priorPage.current) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
      priorPage.current = currentPage
    }
  }, [currentPage, priorPage])

  if (isError) {
    return <Redirect to={`${providerFinderRootPath}/error`} />
  }

  return (
    <>
      <PageLayout
        header={
          <Stack marginBottom={theme.spacing(theme.tokens.spacing.xl)} spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <PageHeader
              startGlyph={<PageHeaderGlyph src="/images/icn-providers.png" srcSet={"/images/icn-providers-2x.png"} />}
              pageTitle={<FormattedMessage defaultMessage="Provider finder" />}
            />
          </Stack>
        }
        variant="sidebar"
        sidebarDirection="end"
        sidebar={<ResultsSidebar searchLocation={searchLocation} benefit={benefit} keyword={keyword} />}
      >
        <Helmet>
          <title>
            {intl.formatMessage(
              {
                defaultMessage: "{pageTitle} | Find a provider",
                description:
                  "The title of the current page as shown in the browser's tab or window. {pageTitle} is the name of current page."
              },
              { pageTitle }
            )}
          </title>
        </Helmet>
        <Box sx={{ position: "relative" }}>
          <Box
            display="flex"
            justifyContent="flex-start"
            gap={[null, null, null, "48px"]}
            padding="8px 0 0 0"
            width="100%"
            id="provider-finder-results-container"
            data-provider-finder-results-view={showMapView ? "map" : "list"}
            height={showMapView ? "calc(100vh - 216px)" : "100%"}
          >
            <Box role="region" width="100%" maxWidth={["100%", "100%", "100%", 600]}>
              {isLoading ? (
                <Loading />
              ) : (
                <ResultDisplay
                  locationCountryCode={locationCountryCode}
                  providers={data as Provider[]}
                  benefit={benefit}
                />
              )}
            </Box>
          </Box>
        </Box>
      </PageLayout>
    </>
  )
}
