import React, { Dispatch, SetStateAction } from "react"
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import { v4 as uuid } from "uuid"
import {
  YesNo,
  CarrotMatchState,
  EntryPoint,
  Sex,
  User
} from "#/components/carrot-match-fertility-clinic-precision-matching/utils/carrotMatchTypes"

const initialState: CarrotMatchState = {
  interactionId: uuid(),
  triedAgainFromInteractionId: "",
  entryPoint: EntryPoint.PROVIDER_FINDER,
  location: { lat: 0, long: 0 },
  whoIsAnsweringTheIntake: User.MEMBER,
  whoIsSeekingCare: User.MEMBER,
  memberPreferredNameOrFirstName: "",
  partnerPreferredNameOrFirstName: "",
  memberDemographics: {
    memberAge: 0,
    memberSex: Sex.MALE,
    partnerAge: 0,
    partnerSex: Sex.MALE
  },
  medicalInfo: {
    femaleMedicalHistory: [],
    femaleConditions: [],
    maleMedicalHistory: [],
    maleConditions: [],
    previouslyPregnant: YesNo.NO,
    receivedInfertilityTreatment: YesNo.NO,
    weight: 0,
    height: 0
  },
  questionsAndAnswers: [],
  lastQuestionBeforeLeavingFlow: "",
  lastMatchViewedBeforeLeavingFlow: 0,
  matches: []
}

interface ICarrotMatchContext {
  carrotMatchInteraction: CarrotMatchState
  updateCarrotMatchInteraction: (newState: Partial<CarrotMatchState>) => void
  saveCarrotMatchInteraction: () => void
  resetCarrotMatchInteraction: () => void
  retryCarrotMatchSearchWithCareReceiverData: () => void
  isCarrotMatchDialogOpen: boolean
  setIsCarrotMatchDialogOpen: Dispatch<SetStateAction<boolean>>
}

const CarrotMatchContext = React.createContext<ICarrotMatchContext>(null)

export const useCarrotMatch = () => React.useContext<ICarrotMatchContext>(CarrotMatchContext)

export const CarrotMatchContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [carrotMatchInteraction, setCarrotMatchInteraction] = React.useState<CarrotMatchState>(initialState)
  const [isCarrotMatchDialogOpen, setIsCarrotMatchDialogOpen] = React.useState(false)

  const updateCarrotMatchInteraction = (newState: Partial<CarrotMatchState>) => {
    setCarrotMatchInteraction((prevState) => ({ ...prevState, ...newState }))
  }

  const resetCarrotMatchInteraction = () => setCarrotMatchInteraction({ ...initialState, interactionId: uuid() })

  const retryCarrotMatchSearchWithCareReceiverData = () => {
    setCarrotMatchInteraction((prevState) => ({
      interactionId: uuid(),
      triedAgainFromInteractionId: prevState.interactionId,
      entryPoint: prevState.entryPoint,
      location: prevState.location,
      whoIsAnsweringTheIntake: prevState.whoIsAnsweringTheIntake,
      whoIsSeekingCare: prevState.whoIsSeekingCare,
      memberPreferredNameOrFirstName: prevState.memberPreferredNameOrFirstName,
      partnerPreferredNameOrFirstName: prevState.partnerPreferredNameOrFirstName,
      memberDemographics: prevState.memberDemographics,
      medicalInfo: prevState.medicalInfo,
      questionsAndAnswers: [],
      lastQuestionBeforeLeavingFlow: "",
      lastMatchViewedBeforeLeavingFlow: 0,
      matches: []
    }))
  }

  // TODO: make request to the carrot backend endpoint in OP-2272
  const saveCarrotMatchInteraction = (): void => {}

  return (
    <CarrotMatchContext.Provider
      value={{
        carrotMatchInteraction,
        updateCarrotMatchInteraction,
        saveCarrotMatchInteraction,
        resetCarrotMatchInteraction,
        retryCarrotMatchSearchWithCareReceiverData,
        isCarrotMatchDialogOpen,
        setIsCarrotMatchDialogOpen
      }}
    >
      {children}
    </CarrotMatchContext.Provider>
  )
}
