import { Box, CloseIcon, IconButton } from "@carrotfertility/carotene-core"
import React from "react"
import { useIntl } from "react-intl"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"

export function CarrotMatchDialogCloseButton() {
  const intl = useIntl()
  const { send } = useStateMachine()

  function handleClose() {
    send("CLOSE")
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end"
      }}
    >
      <IconButton aria-label={intl.formatMessage({ defaultMessage: "close" })} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}
