import { RetinaImage } from "../views/atoms/images/RetinaImage"
import React from "react"
import { useIntl } from "react-intl"
import { Typography, useTheme, Stack } from "@carrotfertility/carotene-core"

function DisclaimerMessageBubble({ messageDisclaimer }: { messageDisclaimer: string }): JSX.Element {
  const theme = useTheme()
  return (
    <Stack
      display="flex"
      direction="row"
      spacing={theme.spacing(theme.tokens.spacing.lg)}
      alignItems="start"
      padding="24px 0px 45px"
      marginBlockEnd={theme.spacing(theme.tokens.spacing.md)}
      boxShadow="0px 1px 0px rgba(0, 0, 0, 0.1)"
    >
      <RetinaImage name="icn-help-header-msgng" height="40px" width="40px" />
      <Typography variant="body2" color={theme.palette.text.tertiary}>
        {messageDisclaimer}
      </Typography>
    </Stack>
  )
}

export function DisclaimerMessage(): JSX.Element {
  const intl = useIntl()
  const messagesDisclaimer = intl.formatMessage({
    defaultMessage:
      "Carrot resources are for informational purposes only and do not constitute medical advice or professional services. They are not a substitute for legal, tax, or professional medical advice, diagnosis, or treatment. If you are experiencing a medical emergency, call emergency services immediately."
  })
  return <DisclaimerMessageBubble messageDisclaimer={messagesDisclaimer} />
}
