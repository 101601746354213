import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { ButtonFooterLayout, ConfirmButton, RoutingFlowTitle, StepLayout } from "#/components/carrot-plans/shared"
import { FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { useCreateZendeskTicket } from "#/components/carrot-plans/hooks/useMedicalInfoFlow"
import useCarrotPlan from "#/components/carrot-plans/hooks/useCarrotPlan"
import { useGetMedicalInfo, useMedicalInfo } from "#/components/carrot-plans/context/MedicalInfoContext"

const medicalQuestionsStepId = "5KMJM8kNBA7vtCSYRQhqR0"

export default function AnythingElseStep(): JSX.Element {
  const intl = useIntl()
  const { data: carrotPlan, isLoading: carrotPlanIsLoading, refetch: refetchCarrotPlan } = useCarrotPlan()
  const { updateMedicalInfo, hasQuestionBeenSkipped } = useMedicalInfo()
  const { data: medicalInfo } = useGetMedicalInfo()
  const { createZendeskTicket } = useCreateZendeskTicket()
  const menuItemStep = carrotPlan?.steps.find((item) => item.menuItemId === medicalQuestionsStepId)
  const defaultValue = medicalInfo?.medicalAnythingElse
  const { send } = useStateMachine(defaultValue || hasQuestionBeenSkipped("medicalAnythingElse") ? "" : null)

  function sanitizeInputs(inputValue: string): string | null {
    return inputValue === null || inputValue.split("").every((char) => char === " ") || inputValue === ""
      ? null
      : inputValue
  }

  async function onSubmit(formValues: { medicalAnythingElse?: string }): Promise<void> {
    const sanitizedInput = sanitizeInputs(formValues?.medicalAnythingElse)
    if (sanitizedInput === null) {
      await updateMedicalInfo({ skipFields: ["medicalAnythingElse"] })
    } else {
      await updateMedicalInfo({ medicalAnythingElse: sanitizedInput })
    }
    await createZendeskTicket(menuItemStep.carrotPlanStepId)
    await refetchCarrotPlan()
    send("")
  }

  const header = intl.formatMessage({
    defaultMessage: "Is there anything else you'd like to share?"
  })

  return carrotPlanIsLoading ? null : (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="medicalAnythingElse-label">{header}</span>}
        description={intl.formatMessage({
          defaultMessage:
            "This could include information about your journey to this point, any other journeys you are on or " +
            "considering, or any personal or cultural considerations you'd like us to be aware of."
        })}
        formElements={
          <Form onSubmit={onSubmit}>
            <FormTextField
              name="medicalAnythingElse"
              defaultValue={defaultValue}
              rows={4}
              multiline
              inputProps={{ maxLength: 3000, "aria-labelledby": "medicalAnythingElse-label" }}
            />
            <ButtonFooterLayout
              continueButton={
                <ConfirmButton>
                  <FormattedMessage defaultMessage="Update Carrot Plan" />
                </ConfirmButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
