import React from "react"
import { useCurrentUser } from "../context/user/UserContext"
import useCarrotPlan from "../carrot-plans/hooks/useCarrotPlan"
import useCarrotPlanEmployeeSettings from "../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import CarrotPlanHero from "./CarrotPlansHero"
import { OptOutOrExistingMemberCpHero } from "./OptOutOrExistingMemberCpHero"

export function Hero(): JSX.Element {
  const { carrotPlansRoutingCompleted } = useCurrentUser()
  const { data: carrotPlan, isLoading } = useCarrotPlan()
  const {
    data: carrotPlanEmployeeSettings,
    isLoading: isLoadingEmployeeSettings,
    isError: isErrorEmployeeSettings,
    isDoneForNow,
    appIsUnlockedAndPendingCarrotPlan
  } = useCarrotPlanEmployeeSettings()

  if (isLoading || isLoadingEmployeeSettings) {
    return null
  }

  if (isDoneForNow) {
    return null
  }

  if (carrotPlan && !carrotPlan?.journeyCompleteTimestampUtc && !appIsUnlockedAndPendingCarrotPlan) {
    return <CarrotPlanHero plan={carrotPlan} />
  }

  if (
    !isErrorEmployeeSettings &&
    !carrotPlan?.journeyCompleteTimestampUtc &&
    (carrotPlanEmployeeSettings?.initialCarrotPlanChatRequested || appIsUnlockedAndPendingCarrotPlan)
  ) {
    return null
  }

  if (!isErrorEmployeeSettings && carrotPlanEmployeeSettings.memberRejectedCarrotPlan) {
    return <OptOutOrExistingMemberCpHero />
  }

  if (!carrotPlansRoutingCompleted) {
    return null
  }

  return <OptOutOrExistingMemberCpHero />
}
