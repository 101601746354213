import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { Button, DialogContent, DialogContentText, DialogTitle } from "@carrotfertility/carotene-core"
import { FormattedMessage } from "react-intl"
import { DialogActionsBar } from "@carrotfertility/carotene-core-x"
import React from "react"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"

export function CloseWithoutAMatchStep() {
  const { back } = useStateMachine()
  const { resetCarrotMatchInteraction, setIsCarrotMatchDialogOpen } = useCarrotMatch()

  return (
    <>
      <DialogTitle id="step-heading">
        <FormattedMessage defaultMessage="Close without a match?" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage defaultMessage="Your answers won’t be saved." />
        </DialogContentText>
      </DialogContent>
      <DialogActionsBar
        primaryAction={
          <Button onClick={() => back()}>
            <FormattedMessage defaultMessage="Keep matching" />
          </Button>
        }
        secondaryAction={
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              setIsCarrotMatchDialogOpen(false)
              resetCarrotMatchInteraction()
            }}
          >
            <FormattedMessage defaultMessage="Close" />
          </Button>
        }
      />
    </>
  )
}
