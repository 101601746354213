import React, { ComponentType } from "react"
import { reportError } from "#/utils/ErrorReporting"

type ComponentProps = { unsetError: () => void }

const createErrorBoundary = (component: ComponentType<ComponentProps>, origin?: string) =>
  class ErrorBoundary extends React.Component<React.PropsWithChildren, { hasError: boolean }> {
    state = {
      hasError: false
    }

    static getDerivedStateFromError(error: Error) {
      if (origin) {
        const customError = new Error(`Unhandled error in ${origin}: ${error.message}`)
        customError.name = `${origin} Error`
        customError.stack = error.stack

        reportError(customError)
      } else {
        reportError(error)
      }

      return { hasError: true }
    }

    unsetError = () => {
      this.setState({
        hasError: false
      })
    }

    render() {
      if (this.state.hasError) {
        return React.createElement<ComponentProps>(component, { unsetError: this.unsetError })
      }

      return this.props.children
    }
  }

export { createErrorBoundary }
