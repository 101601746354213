import React from "react"
import { NotSmall, Small } from "./Responsive"
import { H1, H2 } from "@carrotfertility/carotene"

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const SwitchH1ToH2 = ({ children }) => (
  <>
    <NotSmall>
      <H1>{children}</H1>
    </NotSmall>
    <Small>
      <H2>{children}</H2>
    </Small>
  </>
)

export { SwitchH1ToH2 }
