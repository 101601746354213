import React from "react"
import { Box, Typography, Button, useTheme, Stack } from "@carrotfertility/carotene-core"
import useCarrotPlan from "#/components/carrot-plans/hooks/useCarrotPlan"
import { CarrotPlan } from "#/types/carrotPlanTypes"
import { useHistory } from "react-router-dom"
import { Paths } from "#/utils/Paths"
import useCarrotPlanEmployeeSettings from "../carrot-plans/hooks/useCarrotPlanEmployeeSettings"
import { FormattedMessage, MessageDescriptor, defineMessage, useIntl } from "react-intl"

function carrotPlanCompleted(carrotPlan: Pick<CarrotPlan, "steps">): boolean {
  return Boolean(carrotPlan?.steps?.every((step) => Boolean(step?.completedAtUtc)))
}

function getModuleCopy(carrotPlan: Pick<CarrotPlan, "steps">): MessageDescriptor {
  if (carrotPlanCompleted(carrotPlan)) {
    return defineMessage({
      defaultMessage:
        "It looks like you completed everything in your plan. Update us about your journey so we can refresh your plan with the most up-to-date guidance."
    })
  }
  return defineMessage({
    defaultMessage:
      "Has anything changed? Update us about your journey so we can refresh your plan with the most up-to-date guidance."
  })
}

export function CarrotPlansRefreshModule(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()

  const carrotPlan = useCarrotPlan()
  const history = useHistory()
  const { isDoneForNow, appIsUnlockedAndPendingCarrotPlan } = useCarrotPlanEmployeeSettings()
  const showRefreshModule =
    !carrotPlan.data?.journeyCompleteTimestampUtc &&
    (carrotPlan.data?.isStale || carrotPlanCompleted(carrotPlan.data)) &&
    !isDoneForNow &&
    !appIsUnlockedAndPendingCarrotPlan

  if (showRefreshModule) {
    const bodyCopy = intl.formatMessage(getModuleCopy(carrotPlan.data))
    return (
      <Box
        padding={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        bgcolor={(theme) => theme.palette.info.light}
        borderRadius={(theme) => theme.tokens.borderRadius.md}
      >
        <Stack spacing={theme.spacing(theme.tokens.spacing.md)} alignItems={"flex-start"}>
          <Typography>{bodyCopy}</Typography>
          <Button onClick={() => history.push(Paths.REFRESH_CARROT_PLAN)}>
            <FormattedMessage defaultMessage="Get new suggestions" />
          </Button>
        </Stack>
      </Box>
    )
  }

  return null
}
