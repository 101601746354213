/* eslint formatjs/no-literal-string-in-jsx: "error" */

import React, { FormEvent, useEffect, useState } from "react"
import { useUserContext } from "../../context/UserContext"
import {
  DEFAULT_IMPERIAL_RADIUS_IN_KM,
  DEFAULT_METRIC_RADIUS_IN_KM,
  getProviderFinderSearchURL,
  ProviderFinderParam,
  providerFinderRootPath,
  RedirectToProviderFinderURL
} from "../../utils/providerFinderSearchUrl"
import { Redirect, useHistory } from "react-router"
import { getAvailableParentIdsByBenefitConfig } from "../../utils/parentIdMapping"
import { useSearchTypeContext, SearchType } from "../../context/SearchTypeContext"
import { useBroadAreaContext } from "../../context/BroadAreaContext"
import { FormattedMessage, useIntl } from "react-intl"
import { Tabs, Tab, Box, Button, Stack, Typography, useTheme } from "@carrotfertility/carotene-core"
import { ProviderTypeFilterDropdown } from "./ProviderTypeFilterDropdown"
import { useGetLocationDetails } from "../../hooks/useGetLocationDetails"
import { LocationPicker } from "../location-picker/LocationPicker"
import { Link as RouterLink } from "react-router-dom"
import { KeywordSearchInput } from "../keyword-search/KeywordSearchInput"
import { getCountryUsesImperialUnits } from "../../utils/getCountryUsesImperialUnits"
import { Benefit } from "#/lib/carrot-api/types/Benefit"

export function ProviderSearchForm({
  isCustomNetworks,
  isCarrotCore,
  benefit
}: {
  isCustomNetworks: boolean
  isCarrotCore: boolean
  benefit: Benefit
}): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()

  const [locationPickerValue, setLocationPickerValue] = useState("")

  const [providerNameValue, setProviderNameValue] = useState("")

  const [searchLocation, setSearchLocation] = useState("")
  const [providerKeyword, setProviderKeyword] = useState("")
  const [typeaheadLocationSearch, setTypeaheadLocationSearch] = useState("")

  const { data: locationDetails, isFetching, isError } = useGetLocationDetails(searchLocation)
  const { companyCountryCode } = useUserContext()
  const { setBroadAreaSearched } = useBroadAreaContext()
  const { searchType, setSearchType } = useSearchTypeContext()

  const availableParentIds = getAvailableParentIdsByBenefitConfig(benefit)
  const history = useHistory()
  const params = new URLSearchParams(history.location.search)
  const providerTypeFromParams = params?.get(ProviderFinderParam.ProviderType)?.split(",") ?? []

  // OP-2282 - We now only allow one provider type to be selected at a time but there may still be links in the wild with multiple.
  //           If there are multiple then we won't auto-select any provider type.
  const [selectedProviderType, setSelectedProviderType] = useState(
    providerTypeFromParams.length === 1 && availableParentIds.includes(providerTypeFromParams[0])
      ? providerTypeFromParams[0]
      : ""
  )

  useEffect(() => {
    setBroadAreaSearched(locationDetails?.isBroadArea)
  }, [setBroadAreaSearched, locationDetails?.isBroadArea])

  const handleChange = (_event: any, newValue: SearchType) => {
    setSearchType(newValue)
  }

  const preserveScrollPositionOnNavigation = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    const scrollPosition = window.scrollY
    history.push(event.currentTarget.pathname)
    requestAnimationFrame(() => {
      window.scrollTo(0, scrollPosition)
    })
  }

  const isSearchDetailsLoaded = searchLocation && !isFetching && !isError
  if (isSearchDetailsLoaded) {
    const searchURL = getProviderFinderSearchURL({
      searchLocation,
      locationDetails,
      companyCountryCode,
      providerType: selectedProviderType,
      radiusInKm: getCountryUsesImperialUnits(companyCountryCode)
        ? DEFAULT_IMPERIAL_RADIUS_IN_KM
        : DEFAULT_METRIC_RADIUS_IN_KM,
      keyword: providerKeyword
    })

    return <RedirectToProviderFinderURL url={searchURL} />
  }

  const handleSubmit = (event: FormEvent): void => {
    event.preventDefault()
    setSearchLocation(locationPickerValue)
    setProviderKeyword(providerNameValue)
  }

  if (isError) {
    return <Redirect to={`${providerFinderRootPath}/error`} />
  }

  return (
    <Box
      component="section"
      borderRadius={theme.tokens.borderRadius.md}
      position="relative"
      display="flex"
      flexDirection="column"
      paddingTop={(theme) => theme.tokens.spacing.xxl}
      paddingBottom={(theme) => theme.tokens.spacing.xxxl}
      sx={(theme) => ({
        background: searchType === SearchType.KEYWORD ? theme.palette.primary.light : theme.palette.info.light
      })}
    >
      {!isCarrotCore || isCustomNetworks ? (
        <Box
          borderBottom={(theme) => `solid ${theme.tokens.borderWidth.sm}`}
          borderColor="divider"
          paddingX={(theme) => theme.tokens.spacing.xl}
          marginBottom={(theme) => theme.tokens.spacing.xl}
          sx={(theme) => ({
            [theme.breakpoints.up("lg")]: { paddingX: "4rem" }
          })}
        >
          <Tabs
            value={searchType}
            onChange={handleChange}
            indicatorColor="secondary"
            TabIndicatorProps={{
              sx: (theme) => ({
                height: theme.tokens.borderWidth.lg,
                borderRadius: theme.tokens.borderRadius.md
              })
            }}
          >
            <Tab
              component={RouterLink}
              to="/providerfinder"
              label={
                <Typography variant="h4" color={(theme) => theme.palette.text.secondary}>
                  <FormattedMessage defaultMessage="Search by type" />
                </Typography>
              }
              value={SearchType.LOCATION}
              sx={(theme) => ({
                padding: theme.tokens.spacing.none
              })}
              onClick={preserveScrollPositionOnNavigation}
            />
            <Tab
              component={RouterLink}
              to="/providerfinder/search-by-name"
              label={
                <Typography variant="h4" color={(theme) => theme.palette.text.secondary}>
                  <FormattedMessage defaultMessage="Search by name" />
                </Typography>
              }
              value={SearchType.KEYWORD}
              sx={(theme) => ({
                padding: theme.tokens.spacing.none,
                marginBlockStart: theme.tokens.spacing.xs,
                marginInlineEnd: theme.tokens.spacing.md,
                marginBlockEnd: theme.tokens.spacing.xs,
                marginInlineStart: theme.tokens.spacing.md
              })}
              onClick={preserveScrollPositionOnNavigation}
            />
          </Tabs>
        </Box>
      ) : null}

      <Box
        paddingX={(theme) => theme.tokens.spacing.xl}
        sx={(theme) => ({
          [theme.breakpoints.up("lg")]: { paddingX: "4rem" }
        })}
      >
        <Box
          sx={(theme) => ({
            zIndex: 3,
            inlineSize: "100%",
            [theme.breakpoints.up("lg")]: { maxInlineSize: "540px" }
          })}
        >
          <form name="providerSearchForm" onSubmit={handleSubmit}>
            <Stack spacing={theme.tokens.spacing.md}>
              {searchType === SearchType.KEYWORD && (
                <KeywordSearchInput
                  tooltipTitle={
                    <Box>
                      <Typography fontSize="inherit" color={(theme) => theme.palette.secondary.contrastText}>
                        <FormattedMessage defaultMessage="A practice refers to a clinic, law firm, or agency. Generally, our network includes practices, not individuals. Instead of looking for Dr. Smith at CCRM, try looking for the CCRM clinic." />
                      </Typography>
                      <br></br>
                      <Typography fontSize="inherit" color={(theme) => theme.palette.secondary.contrastText}>
                        <FormattedMessage defaultMessage="Sometimes, the practice name is the same as an  individual’s name. i.e., John Smith Law Firm." />
                      </Typography>
                    </Box>
                  }
                  label={intl.formatMessage({ defaultMessage: "Practice Name" })}
                  setProviderNameValue={setProviderNameValue}
                  setTypeaheadLocationSearch={setTypeaheadLocationSearch}
                />
              )}
              {searchType === SearchType.LOCATION && availableParentIds.length > 1 && (
                <ProviderTypeFilterDropdown
                  selectedProviderType={selectedProviderType}
                  setSelectedProviderType={setSelectedProviderType}
                  benefit={benefit}
                />
              )}
              <LocationPicker
                label={intl.formatMessage({ defaultMessage: "Location" })}
                typeaheadLocationSearch={typeaheadLocationSearch}
                setLocationPickerValue={setLocationPickerValue}
              />
              <Button
                type="submit"
                size="medium"
                color="secondary"
                variant="contained"
                disabled={
                  searchType === SearchType.KEYWORD
                    ? !locationPickerValue || !providerNameValue
                    : !locationPickerValue || !selectedProviderType
                }
                sx={{ inlineSize: "fit-content" }}
              >
                <FormattedMessage defaultMessage="Search" />
              </Button>
            </Stack>
          </form>
        </Box>
      </Box>
    </Box>
  )
}
