import React from "react"
import { useCaseRateRenewals } from "../useCaseRateRenewals"
import { ThanksForLettingUsKnowContent } from "../ThanksForLettingUsKnowContent"
import { useRefetchRenewalData } from "#/components/carrot-plans/case-rate-renewals/useQueryCaseRateRenewals"

export function ThanksForLettingUsKnowStep(): JSX.Element {
  const { setOpenGatedAccountModal } = useCaseRateRenewals()
  const refetchData = useRefetchRenewalData()

  async function close(): Promise<void> {
    setOpenGatedAccountModal(false)
    await refetchData()
  }

  return <ThanksForLettingUsKnowContent {...{ close }} />
}
