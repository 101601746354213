import { useQuery } from "@tanstack/react-query"
import { carrotClient } from "#/utils/CarrotClient"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"

const CARROT_PLAN_REQUEST_STATUS_KEY = "getCarrotPlanRequestStatus"

export function useGetCarrotPlanRequestStatus() {
  const { data, isLoading, isError } = useQuery<CarrotPlanResponseStatus, ResponseError>(
    [CARROT_PLAN_REQUEST_STATUS_KEY],
    () => carrotClient.getCarrotPlanRequestStatus(),
    {
      retry: buildCarrotApiRetryPolicy(3)
    }
  )

  return {
    data,
    isLoading,
    isError
  }
}

type CarrotPlanResponseStatus = {
  carrotPlanIsOnTheWay: boolean
}
