import { useCurrentUser } from "#/components/context/user/UserContext"
import useBenefit from "#/components/dynamic-content/hooks/useGetBenefit"
import useGetCustomNetworks from "#/pages/provider-finder/hooks/useGetCustomNetworks"

export function useShowCarrotMatch(): boolean {
  const { isUsa } = useCurrentUser()
  const { data: benefitData, isLoading: isBenefitLoading } = useBenefit()
  const { data: customNetworkData, isLoading: isCustomNetworksLoading } = useGetCustomNetworks()

  if (isBenefitLoading || isCustomNetworksLoading) {
    return null
  }

  return (
    isUsa &&
    (benefitData.journeys.fertilityCare.isAppSupportEnabled ||
      benefitData.journeys.fertilityPreservation.isAppSupportEnabled ||
      benefitData.journeys.gestationalCarrier.isAppSupportEnabled) &&
    benefitData.providerFinder.showFertilityClinics &&
    !customNetworkData.hasCustomNetworks
  )
}
