import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Loading } from "../../views/Loading"
import { carrotClient } from "#/utils/CarrotClient"
import { SupportCenterUrlResponse } from "../../../types/zendeskTypes"
import { reportError } from "../../../utils/ErrorReporting"
import Settings from "#/utils/CarrotConfig"
import { getLocalizedZendeskLink } from "carrot-api/HelpCenterClient"
import { AppAccessError } from "#/components/views/AppAccessError"

const RETURN_TO = "return_to"
const ZENDESK_LOCALE = "zendesk_locale"

const SupportCenterLoginContainer = (): JSX.Element => {
  const urlSearchParams = new URLSearchParams(useLocation().search)
  const returnToUrlString = urlSearchParams.get(RETURN_TO) || Settings.ZENDESK_GUIDE
  const [error, setError] = useState(false)

  useEffect(() => {
    carrotClient
      .getSupportCenterUrl()
      .then((response: SupportCenterUrlResponse) => {
        const supportCenterUrl = new URL(response.supportCenterUrl)
        const zendeskLocale = supportCenterUrl.searchParams.get(ZENDESK_LOCALE)
        const returnToUrlWithUserZendeskLocale = getLocalizedZendeskLink(returnToUrlString, zendeskLocale)

        supportCenterUrl.searchParams.set(RETURN_TO, returnToUrlWithUserZendeskLocale)

        window.location.href = supportCenterUrl.toString()
      })
      .catch((error) => {
        reportError(error)
        setError(true)
      })
  }, [returnToUrlString])

  return error ? <AppAccessError /> : <Loading />
}

export { SupportCenterLoginContainer }
