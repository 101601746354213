type CarrotMatchAnswer = {
  text: string
  selected: boolean
}

interface CarrotMatchQuestion {
  question: string
  answer: CarrotMatchAnswer[]
}

export enum EntryPoint {
  PROVIDER_FINDER = "PROVIDER_FINDER",
  CARROT_PLAN = "CARROT_PLAN",
  DBG = "DBG",
  EXTERNAL = "EXTERNAL"
}

export enum User {
  MEMBER = "MEMBER",
  PARTNER = "PARTNER"
}

export enum Sex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  INTERSEX = "INTERSEX"
}

export enum YesNo {
  YES = "YES",
  NO = "NO"
}

export interface CarrotMatchState {
  interactionId: string
  triedAgainFromInteractionId: string
  entryPoint: EntryPoint
  location: {
    lat: number
    long: number
  }
  whoIsAnsweringTheIntake: User
  whoIsSeekingCare: User
  memberPreferredNameOrFirstName: string
  partnerPreferredNameOrFirstName: string
  memberDemographics: {
    memberAge: number
    memberSex: Sex
    partnerAge: number
    partnerSex: Sex
  }
  medicalInfo: {
    femaleMedicalHistory: string[]
    femaleConditions: string[]
    maleMedicalHistory: string[]
    maleConditions: string[]
    previouslyPregnant: YesNo
    receivedInfertilityTreatment: YesNo
    weight: number
    height: number
  }
  questionsAndAnswers: CarrotMatchQuestion[]
  lastQuestionBeforeLeavingFlow: string
  lastMatchViewedBeforeLeavingFlow: number
  matches: {
    uuid: number
    providerName: string
    address: string
  }[]
}
