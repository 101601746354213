import React from "react"
import { InputAdornment } from "@carrotfertility/carotene-core"
import { FormTextField, FormTextFieldProps, useWatch } from "@carrotfertility/carotene-core-x"
import { useIntl } from "react-intl"
import { CountryCode } from "#/utils/CountryCode"
import { getCountryCallingCodeConsideringEdgeCases } from "#/components/phone-number-collection/usePhoneCodeSelectOptions"
import { useTextDirection } from "#/services/locale/hooks/useTextDirection"
import { TextDirection } from "#/types/Locale"

type FormPhoneNumberTextInputProps = FormTextFieldProps & {
  countryCodeFieldName: string
}

export default function FormPhoneNumberTextInput({ countryCodeFieldName, ...props }: FormPhoneNumberTextInputProps) {
  const intl = useIntl()
  const textDirection = useTextDirection()
  const countryCodeSelection = useWatch({ name: countryCodeFieldName })
  const phoneCode = getCountryCallingCodeConsideringEdgeCases(
    countryCodeSelection ? (countryCodeSelection as CountryCode) : ("US" as CountryCode)
  )

  return (
    <FormTextField
      {...props}
      type="tel"
      InputProps={{
        startAdornment:
          phoneCode && textDirection === TextDirection.LeftToRight ? (
            <InputAdornment disableTypography={props.disabled} position="start">
              {intl.formatMessage({ defaultMessage: "+{phoneCode}" }, { phoneCode: phoneCode })}
            </InputAdornment>
          ) : null,
        endAdornment:
          phoneCode && textDirection === TextDirection.RightToLeft ? (
            <InputAdornment disableTypography={props.disabled} position="start">
              {intl.formatMessage({ defaultMessage: "+{phoneCode}" }, { phoneCode: phoneCode })}
            </InputAdornment>
          ) : null
      }}
    />
  )
}
