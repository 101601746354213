import React from "react"
import { Box, CloseIcon, IconButton } from "@carrotfertility/carotene-core"
import { useCarrotMatch } from "#/components/carrot-match-fertility-clinic-precision-matching/context/CarrotMatchContext"

export function PlaceholderYes(): JSX.Element {
  const { setIsCarrotMatchDialogOpen } = useCarrotMatch()
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton onClick={() => setIsCarrotMatchDialogOpen(false)}>
          <CloseIcon />
        </IconButton>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      </Box>
      YES
    </>
  )
}

export function PlaceholderNo(): JSX.Element {
  const { setIsCarrotMatchDialogOpen } = useCarrotMatch()
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <IconButton onClick={() => setIsCarrotMatchDialogOpen(false)}>
          <CloseIcon />
        </IconButton>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
      </Box>
      NO
    </>
  )
}
