import React from "react"
import { useHistory } from "react-router"
import { useCurrentUser } from "../context/user/UserContext"
import { FormattedMessage } from "react-intl"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Typography,
  Button,
  DialogTitle
} from "@carrotfertility/carotene-core"
import EmployeeOnlyModalContainer from "../employee-only-containers/EmployeeOnlyModalContainer"
import { PiiText } from "#/services/tracking"

export default function EmailVerifiedModal({ open }: { open: boolean }): JSX.Element {
  const { personalEmail } = useCurrentUser()
  const history = useHistory()

  function closeModal() {
    history.push({
      pathname: "/",
      state: { showPersonalEmailVerifiedModal: false, showVerifyEmailModal: false }
    })
  }

  return (
    <EmployeeOnlyModalContainer onClose={closeModal} open={open}>
      <Dialog open={open}>
        <DialogTitle>
          <FormattedMessage defaultMessage="Your email address has been verified" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <FormattedMessage
              defaultMessage="We'll communicate with you at <bold>{personalEmail}.</bold>"
              values={{
                personalEmail: personalEmail,
                bold: (boldContent) => (
                  <>
                    <br />
                    <Typography component={PiiText} fontWeight="bold">
                      {boldContent}
                    </Typography>
                    <br />
                  </>
                )
              }}
            />
          </DialogContentText>
          <DialogContentText>
            <FormattedMessage
              defaultMessage="To change this, go to <TextLink>Account settings</TextLink>."
              values={{
                TextLink: (textLinkContent) => <Link href={"/account#email-addresses"}>{textLinkContent}</Link>
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>
            <FormattedMessage defaultMessage="Close" />
          </Button>
        </DialogActions>
      </Dialog>
    </EmployeeOnlyModalContainer>
  )
}
