import React from "react"
import { useHistory } from "react-router-dom"
import { useCaseRateRenewals } from "../useCaseRateRenewals"
import { getHeap } from "../../../../utils/heap"
import { CaseRateRenewalModalTopSection } from "../CaseRateRenewalModalTopSection"
import { CaseRateRenewalModalButtonGroup } from "../CaseRateRenewalModalButtonGroup"
import { FormattedMessage, useIntl } from "react-intl"
import { Box, Button, Typography } from "@carrotfertility/carotene-core"
import { useRefetchRenewalData } from "#/components/carrot-plans/case-rate-renewals/useQueryCaseRateRenewals"
import { Paths } from "#/utils/Paths"

export function KeepMyPlanStep(): JSX.Element {
  const intl = useIntl()
  const history = useHistory()
  const { setOpenGatedAccountModal } = useCaseRateRenewals()
  const refetchData = useRefetchRenewalData()

  function chatWithAnExpert(): void {
    setOpenGatedAccountModal(false)
    history.push(Paths.TALK_TO_CARROT)
    getHeap().track("Action: Keep my plan", {
      EventName: "CaseRateRenewal",
      CurrentStep: "Keep my plan",
      Selection: "Chat with an expert"
    })
  }

  async function close(): Promise<void> {
    setOpenGatedAccountModal(false)
    await refetchData()
  }

  return (
    <>
      <CaseRateRenewalModalTopSection
        header={intl.formatMessage({
          defaultMessage: "You're all set"
        })}
        showImage
        imageSrc="/images/illo-underwater-dashboard"
        imageAltText={intl.formatMessage({
          defaultMessage: "underwater dashboard"
        })}
      />
      <Box paddingY={(theme) => theme.spacing(theme.tokens.spacing.sm)} color={(theme) => theme.palette.text.secondary}>
        <Box>
          <Typography paddingY={(theme) => theme.spacing(theme.tokens.spacing.md)}>
            <FormattedMessage defaultMessage="Your Carrot Plan is ready to go and you have full access to all features of your benefit." />
          </Typography>
          <Typography paddingY={(theme) => theme.spacing(theme.tokens.spacing.md)}>
            <FormattedMessage defaultMessage="We'd love the opportunity to check in live and see if there might be additional ways we can support you. Consider scheduling a chat now or whenever you're ready." />
          </Typography>
        </Box>
        <CaseRateRenewalModalButtonGroup
          buttonOne={
            <Button variant="outlined" color="inherit" onClick={chatWithAnExpert}>
              <FormattedMessage defaultMessage="Chat with an expert" />
            </Button>
          }
          buttonTwo={
            <Button variant="contained" onClick={close}>
              <FormattedMessage defaultMessage="Close" />
            </Button>
          }
        />
      </Box>
    </>
  )
}
