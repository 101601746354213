import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export function HasRecentlyReceivedPreservationDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyStageValue = routingData?.journeyStage
  const preservationInterestValue = routingData?.preservationInterest
  const { send } = useStateMachine()

  function getStateMachineValue() {
    if (journeyStageValue === "PRESERVATION_RECENTLY_RECEIVED") {
      return "ANYTHING_ELSE"
    } else if (journeyStageValue === "EXPLORING") {
      return "EXPLORING_PRESERVATION"
    }

    return preservationInterestValue
  }

  useEffect(() => {
    send(getStateMachineValue())
  })
  return <></>
}
