import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import ResponseError from "#/types/responseError"
import buildCarrotApiRetryPolicy from "#/utils/CarrotApiRetryPolicy"
import { carrotClient } from "#/utils/CarrotClient"
import { useMutation, useQuery, useQueryClient, UseQueryResult } from "@tanstack/react-query"

interface TelemedicineConsent {
  telemedicineConsentTimeUtc: string
}

const MEMBER_TELEMEDICINE_CONSENT_QUERY_KEY = ["memberTelemedicineConsent"]
const PARTNER_TELEMEDICINE_CONSENT_QUERY_KEY = ["PartnerTelemedicineConsents"]

export function useTelemedicineConsents(): {
  telemedicineConsentTime: UseQueryResult<TelemedicineConsent, ResponseError>
} {
  const { isPartnerAccessingAccount } = useUserRole()
  const memberConsent = useQuery({
    queryKey: MEMBER_TELEMEDICINE_CONSENT_QUERY_KEY,
    queryFn: () => carrotClient.getTelemedicineMemberConsentTime(),
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })

  const partnerConsent = useQuery({
    queryKey: PARTNER_TELEMEDICINE_CONSENT_QUERY_KEY,
    queryFn: () => carrotClient.getTelemedicinePartnerConsentTime(),
    retry: buildCarrotApiRetryPolicy(1),
    refetchOnWindowFocus: false
  })

  const telemedicineConsentTime = isPartnerAccessingAccount ? partnerConsent : memberConsent
  return { telemedicineConsentTime }
}

export function useTelemedicineConsentsUpdate() {
  const queryClient = useQueryClient()
  const { isPartnerAccessingAccount } = useUserRole()
  const member = useMutation(() => carrotClient.createOrUpdateMemberTelemedicineConsentTime(), {
    retry: false,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: MEMBER_TELEMEDICINE_CONSENT_QUERY_KEY })
  })

  const partner = useMutation(() => carrotClient.createOrUpdatePartnerTelemedicineConsentTime(), {
    retry: false,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: PARTNER_TELEMEDICINE_CONSENT_QUERY_KEY })
  })

  const updateTelemedicineConsent = isPartnerAccessingAccount ? partner : member

  return updateTelemedicineConsent
}
