import React, { useEffect } from "react"
import { useSignUpFlow } from "../context/signup/SignUpFlowContext"
import { useHistory } from "react-router"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { Button, Typography, Stack, useTheme, Box } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { UnauthPreferredLocale } from "../views/UnauthPreferredLocale"
import { PiiText } from "../../services/tracking"

export default function SignUpEmployeeAlreadyRegisteredPage() {
  const { email } = useSignUpFlow()
  const history = useHistory()
  const theme = useTheme()
  const intl = useIntl()

  useEffect(() => {
    if (!email) {
      history.push("/signup")
    }
  }, [email, history])
  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "It looks like you already have an account" })}>
      <AppAccessPageCard>
        <Box textAlign="center" marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          <Typography component="h1" variant="h2">
            <FormattedMessage defaultMessage="It looks like you already have an account" />
          </Typography>
        </Box>
        <Stack
          spacing={theme.spacing(theme.tokens.spacing.xxl)}
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.lg)}
        >
          <Stack spacing={theme.spacing(theme.tokens.spacing.xs)} textAlign="center">
            <Typography>
              <FormattedMessage defaultMessage="We found an account for " />
            </Typography>
            <Typography component={PiiText}>{email}</Typography>
            <Typography>
              <FormattedMessage defaultMessage="Please try logging in with the email above." />
            </Typography>
          </Stack>
          <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
            <Box textAlign="center">
              <Button component={RouterLink} to={"/login"}>
                <FormattedMessage defaultMessage="Go to login" />
              </Button>
            </Box>
            <Box display="block" sx={{ alignSelf: "center" }}>
              <UnauthPreferredLocale />
            </Box>
          </Stack>
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
