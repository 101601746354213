import { useMutation, UseMutationResult, useQueryClient } from "@tanstack/react-query"
import { CarrotPlan, CarrotPlanStepRequest } from "../../../types/carrotPlanTypes"
import { HttpStatusCodes } from "../../../utils/HttpStatusCodes"
import ResponseError from "../../../types/responseError"
import { carrotClient } from "#/utils/CarrotClient"
import { getCarrotPlanQueryKey } from "#/components/carrot-plans/hooks/useCarrotPlan"
import { dayjs } from "@carrotfertility/carotene-core"

export default function useUpdatePlanStep(): UseMutationResult<void, ResponseError> {
  const queryClient = useQueryClient()
  const carrotPlanQueryKey = getCarrotPlanQueryKey()
  return useMutation((updateStepData: CarrotPlanStepRequest) => carrotClient.updateCarrotPlanStep(updateStepData), {
    retry: (failureCount, error) => {
      switch (error.statusCode) {
        case HttpStatusCodes.UNAUTHORIZED:
        case HttpStatusCodes.BAD_REQUEST:
        case HttpStatusCodes.NOT_FOUND:
          return false
        default:
          return failureCount <= 3
      }
    },
    onMutate: async (updateStepData) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: carrotPlanQueryKey })

      const previousPlan = queryClient.getQueryData<CarrotPlan>(carrotPlanQueryKey)
      // Optimistically update our Carrot Plan to include the new step completion status
      queryClient.setQueryData<CarrotPlan>(carrotPlanQueryKey, (prevCarrotPlanState) => {
        if (!prevCarrotPlanState) return prevCarrotPlanState
        return {
          ...prevCarrotPlanState,
          steps: prevCarrotPlanState.steps.map((step) => {
            const updatedStep = step.carrotPlanStepId === updateStepData.stepId
            if (!updatedStep) return step

            const newCompletedDate = updateStepData.completed ? dayjs().toISOString() : null
            return {
              ...step,
              completedAtUtc: newCompletedDate
            }
          })
        }
      })
      // Return a context object with the snapshotted value
      return { previousPlan }
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: carrotPlanQueryKey }),
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (err, newStepData, context) => {
      queryClient.setQueryData<CarrotPlan>(carrotPlanQueryKey, context.previousPlan)
    }
  })
}
