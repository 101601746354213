import { HasPartnerDecisionStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/HasPartnerDecisionStep"
import { WillYouBeReceivingTheCare } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/WillYouBeReceivingTheCare"
import { CloseWithoutAMatchStep } from "#/components/carrot-match-fertility-clinic-precision-matching/steps/CloseWithoutAMatchStep"
import {
  PlaceholderNo,
  PlaceholderYes
} from "#/components/carrot-match-fertility-clinic-precision-matching/steps/PlaceholderSteps"

export enum Steps {
  CLOSE_WITHOUT_A_MATCH = "Close without a match?",
  HAS_PARTNER_DECISION = "Has Partner",
  WILL_MEMBER_BE_RECEIVING_CARE = "Will member be receiving care?",
  PLACEHOLDER_YES = "Placeholder yes",
  PLACEHOLDER_NO = "Placeholder no"
}

export enum Workflows {
  CARROT_MATCH = "CarrotMatch"
}

export const carrotMatchSteps = [
  { name: Steps.CLOSE_WITHOUT_A_MATCH, component: CloseWithoutAMatchStep },
  { name: Steps.HAS_PARTNER_DECISION, component: HasPartnerDecisionStep, decisionStep: true },
  { name: Steps.WILL_MEMBER_BE_RECEIVING_CARE, component: WillYouBeReceivingTheCare },
  { name: Steps.PLACEHOLDER_YES, component: PlaceholderYes },
  { name: Steps.PLACEHOLDER_NO, component: PlaceholderNo }
]

// prettier-ignore
const carrotMatchWorkflow = [
  { at: Steps.HAS_PARTNER_DECISION,                     given: "YES",          goto: "" },
  { at: Steps.HAS_PARTNER_DECISION,                     given: "NO",           goto: Steps.WILL_MEMBER_BE_RECEIVING_CARE },
  { at: Steps.WILL_MEMBER_BE_RECEIVING_CARE,            given: "YES",          goto: Steps.PLACEHOLDER_YES },
  { at: Steps.WILL_MEMBER_BE_RECEIVING_CARE,            given: "NO",           goto: Steps.PLACEHOLDER_NO },
  { at: Steps.WILL_MEMBER_BE_RECEIVING_CARE,            given: "CLOSE",        goto: Steps.CLOSE_WITHOUT_A_MATCH }
]

export const carrotMatchWorkflows = [{ name: Workflows.CARROT_MATCH, workflow: carrotMatchWorkflow }]
