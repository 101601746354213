import { useCurrentUser } from "#/components/context/user/UserContext"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import { TtcPaths } from "#/utils/Paths"
import { Button, DialogActions, DialogContent, DialogTitle, Link, Typography } from "@carrotfertility/carotene-core"
import React, { useContext } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { Link as RouterLink } from "react-router-dom"

export function NoPartnerAccessStep(): JSX.Element {
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const intl = useIntl()
  const { partnerPreferredName, partnerFirstName } = useCurrentUser()
  const partnerName = partnerPreferredName ? partnerPreferredName : partnerFirstName
  const partnerNameWithDefault = partnerName ?? intl.formatMessage({ defaultMessage: "your partner" })
  return (
    <>
      <DialogTitle>
        <FormattedMessage
          defaultMessage="{partnerName} is not eligible"
          values={{ partnerName: partnerNameWithDefault }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            defaultMessage="We’re sorry, but {partnerName} is not eligible to use Carrot’s virtual menopause clinic."
            values={{ partnerName: partnerNameWithDefault }}
          />
        </Typography>
        <Typography>
          <FormattedMessage
            defaultMessage="Questions? <link>Contact us</link>"
            values={{
              link: (linkContent) => (
                <Link color="inherit" component={RouterLink} to={TtcPaths.TTC_CARE_NAV}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialogFlow(false)}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
