import React from "react"
import parse, { DOMNode, domToReact, HTMLReactParserOptions, Element } from "html-react-parser"
import DOMPurify from "dompurify"
import {
  Box,
  Typography,
  Divider,
  Link,
  OrderedList,
  ListItemText,
  UnorderedList,
  UnorderedListItem
} from "@carrotfertility/carotene-core"

export function getParsedHtmlElements(htmlString: string): string | JSX.Element | JSX.Element[] {
  const sanitizedHtml = DOMPurify.sanitize(htmlString)
  const options: HTMLReactParserOptions = {
    replace: (domNode: DOMNode) => {
      if (domNode instanceof Element && domNode?.attribs) {
        const { name, children, attribs } = domNode
        switch (name) {
          case "h1":
            return <Typography variant="h1">{domToReact(children, options)}</Typography>
          case "h2":
            return <Typography variant="h2">{domToReact(children, options)}</Typography>
          case "h3":
            return <Typography variant="h3">{domToReact(children, options)}</Typography>
          case "h4":
            return <Typography variant="h4">{domToReact(children, options)}</Typography>
          case "ol":
            return <OrderedList>{domToReact(children, options)}</OrderedList>
          case "ul":
            return <UnorderedList>{domToReact(children, options)}</UnorderedList>
          case "li":
            return (
              <UnorderedListItem>
                <ListItemText>{domToReact(children, options)}</ListItemText>
              </UnorderedListItem>
            )
          case "img":
            return (
              <Box
                component="img"
                marginY={(theme) => theme.spacing(theme.tokens.spacing.md)}
                display="block"
                src={attribs.src}
                alt={attribs.altText ? attribs.altText : "no alt text available"}
              />
            )
          case "hr":
            return (
              <Box
                paddingY={(theme) => theme.spacing(theme.tokens.spacing.md)}
                color={(theme) => theme.palette.divider}
              >
                <Divider />
              </Box>
            )
          case "p":
            return (
              <Typography
                variant="body1"
                color={(theme) => theme.palette.text.secondary}
                sx={{ wordBreak: "break-word" }}
                display="inline"
              >
                {domToReact(children, options)}
              </Typography>
            )
          case "blockquote":
            return (
              <Typography
                component="blockquote"
                marginY={(theme) => theme.spacing(theme.tokens.spacing.lg)}
                marginX={(theme) => theme.spacing(theme.tokens.spacing.xs)}
                padding={(theme) => theme.spacing(theme.tokens.spacing.xs)}
                variant="body1"
                sx={{
                  borderInlineStart: (theme) => `${theme.tokens.borderWidth.md} solid ${theme.palette.border.light}`
                }}
              >
                {domToReact(children, options)}
              </Typography>
            )
          case "code":
            return (
              <Box
                display="inline-block"
                width="auto"
                padding={(theme) => theme.spacing(theme.tokens.spacing.md)}
                bgcolor={(theme) => theme.palette.background.paper}
                borderRadius={(theme) => theme.tokens.borderRadius.md}
              >
                <Typography component="code" style={{ fontFamily: "monospace" }}>
                  {domToReact(children, options)}
                </Typography>
              </Box>
            )
          case "a":
            return (
              <Link target={attribs.target ? attribs.target : "_blank"} href={attribs.href} display="inline">
                {domToReact(children, options)}
              </Link>
            )
          case "b":
          case "strong":
            return (
              <Typography variant="body1" fontWeight="bold" display="inline">
                {domToReact(children, options)}
              </Typography>
            )
          case "i":
          case "em":
            return (
              <Typography variant="body1" fontStyle="italic" display="inline">
                {domToReact(children, options)}
              </Typography>
            )
          case "u":
            return (
              <Typography variant="body1" display="inline" sx={{ textDecoration: "underline" }}>
                {domToReact(children, options)}
              </Typography>
            )
        }
      }
    }
  }
  return parse(sanitizedHtml, options)
}
