import { useBreakpointComponent } from "@carrotfertility/carotene"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"
import React from "react"
import { FormattedDate, FormattedMessage, useIntl } from "react-intl"
import { getPiiProps } from "#/services/tracking"
import { BackIcon, Box, IconButton, Stack, Typography, Divider, useTheme } from "@carrotfertility/carotene-core"

type BackArrowButtonProps = {
  onClick: () => void
}

const BackArrowButton = ({ onClick }: BackArrowButtonProps): JSX.Element => {
  const intl = useIntl()

  return (
    <IconButton
      aria-label={intl.formatMessage({ defaultMessage: "back", description: "go back to the previous page" })}
      onClick={onClick}
      size="small"
      sx={(theme) => ({
        bgcolor: theme.palette.primary.light,
        borderRadius: "100%",
        color: theme.palette.primary.main
      })}
    >
      <BackIcon />
    </IconButton>
  )
}

const Headers = (): JSX.Element => {
  const { showNewThreadForm, setShowNewThreadForm, setShowOnlyThreadsList, selectedThread } = useInAppMessaging()
  const theme = useTheme()

  return (
    <Stack display="flex" direction="column" spacing={theme.spacing(theme.tokens.spacing.lg)}>
      <Stack display="flex" direction="row" spacing={theme.spacing(theme.tokens.spacing.md)} alignItems="center">
        <BackArrowButton
          onClick={() => {
            if (showNewThreadForm) {
              setShowNewThreadForm(false)
              setShowOnlyThreadsList(true)
            } else {
              setShowOnlyThreadsList(true)
            }
          }}
        />
        {showNewThreadForm ? (
          <Box display="flex" alignItems="end">
            <Typography variant="h5" component="h3" marginInlineStart={(theme) => theme.tokens.spacing.sm}>
              <FormattedMessage defaultMessage="Send a new message" />
            </Typography>
          </Box>
        ) : (
          <>
            <Stack spacing="none" sx={{ marginInlineStart: (theme) => theme.tokens.spacing.md }}>
              <Typography variant="overline" color={(theme) => theme.palette.text.disabled}>
                {selectedThread.threadId !== -1 && (
                  <FormattedDate month="short" day="numeric" value={selectedThread.updatedAt} />
                )}
              </Typography>
              <Typography variant="h5" component="h3" {...getPiiProps()}>
                {selectedThread.subject}
              </Typography>
            </Stack>
          </>
        )}
      </Stack>
      <Divider
        sx={{
          borderColor: (theme) => theme.palette.border.light
        }}
      />
    </Stack>
  )
}

const MessagesHeader = (): JSX.Element => {
  const { showOnlyThreadsList } = useInAppMessaging()
  const intl = useIntl()
  const title = intl.formatMessage({ defaultMessage: "Messages", description: "contact us through messages" })
  const header = useBreakpointComponent([
    <Headers key="smallest" />,
    <Headers key="small" />,
    null,
    <PageHeader
      key="large"
      startGlyph={<PageHeaderGlyph src="/images/icn-talk-to-carrot.png" srcSet="/images/icn-talk-to-carrot-2x.png" />}
      pageTitle={title}
    />
  ])

  if (showOnlyThreadsList) {
    return (
      <PageHeader
        startGlyph={<PageHeaderGlyph src="/images/icn-talk-to-carrot.png" srcSet="/images/icn-talk-to-carrot-2x.png" />}
        pageTitle={title}
      />
    )
  }

  return header as JSX.Element
}

export { MessagesHeader }
