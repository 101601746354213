import React, { useContext } from "react"
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@carrotfertility/carotene-core"
import { Link as RouterLink } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"

export function NoPartnerAccountStep(): JSX.Element {
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const intl = useIntl()
  const { partnerPreferredName, partnerFirstName } = useCurrentUser()
  const partnerName = partnerPreferredName ? partnerPreferredName : partnerFirstName
  const partnerNameWithDefault = partnerName ?? intl.formatMessage({ defaultMessage: "your partner" })

  return (
    <>
      <DialogTitle>
        <FormattedMessage defaultMessage="Invite {partnerName}" values={{ partnerName: partnerNameWithDefault }} />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage
            defaultMessage="In order for {partnerName} to access Carrot’s virtual menopause clinic, you must invite them to access your Carrot account. This allows them to sign in with their own email address and password."
            values={{ partnerName: partnerNameWithDefault }}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialogFlow(false)} variant="outlined" color="secondary">
          <FormattedMessage defaultMessage="Cancel" />
        </Button>
        <Button component={RouterLink} to="/account#partnerAccessFooterContainer">
          <FormattedMessage defaultMessage="Invite your partner" />
        </Button>
      </DialogActions>
    </>
  )
}
