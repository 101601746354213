import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { useCheckRoutingInfoQueryCache } from "#/components/carrot-plans/shared/useQueryRoutingInfo"

export default function WhatKindOfJourneyIVFDecisionStep(): JSX.Element {
  const routingData = useCheckRoutingInfoQueryCache()
  const journeyValue = routingData?.journey
  const memberSelectsVal = (() => {
    switch (journeyValue) {
      case "ASSISTED_REPRODUCTION":
        return "ASSISTED_REPRODUCTION"
      default:
        return "OTHER_ANSWERS"
    }
  })()

  const { send } = useStateMachine()
  useEffect(() => {
    send(memberSelectsVal)
  })
  return <></>
}
