import React from "react"
import { Provider } from "../../types/Providers"
import { useUserContext } from "../../context/UserContext"
import { ProviderTypeTags } from "./ProviderTypeTags"
import { ProviderTypeTagsForExtendedNetworks } from "./ProviderTypeTagsForExtendedNetworks"
import { useCompanyContext } from "#/pages/provider-finder/context/CompanyContextProvider"
import Settings from "#/pages/provider-finder/lib/Settings"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { getPiiProps } from "#/services/tracking"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetLocalizedDistanceStringWithUnits } from "#/pages/provider-finder/hooks/useGetLocalizedDistanceStringWithUnits"
import carrotPartnerIndicator from "../../resources/images/carrot-partner-indicator.svg"
import {
  Box,
  Button,
  Chip,
  Link,
  LocationIcon,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
  VirtualIcon
} from "@carrotfertility/carotene-core"
import { Link as ReactRouterLink } from "react-router-dom"
import { VirtualServicesTag } from "./VirtualServicesTag"
import {
  getProviderNetworkConfig,
  useProviderNetworkForCurrentUser
} from "#/components/views/app/navigation/hooks/useHasProviderFinder"

const DirectionsLink = ({ address, searchLocation }: { address: string; searchLocation: string }): JSX.Element => {
  const getGoogleDirections = (address: string): string => {
    // The structure of the url is `/maps/dir/<starting point>/<destination>`
    const googleBaseUrl = "https://www.google.com/maps/dir"
    const encodedSearchLocation = encodeURIComponent(searchLocation)
    const encodedAddress = encodeURIComponent(address)
    return `${googleBaseUrl}/${encodedSearchLocation}/${encodedAddress}`
  }
  return (
    <Link data-id="provider-directions-link" href={getGoogleDirections(address)} target="_blank" color="inherit">
      <FormattedMessage
        defaultMessage="Directions"
        description="Clicking on the directions link will open up Google Maps to get the directions to this provider's location"
      />
    </Link>
  )
}

export function ProviderNetworkChip({ providerNetworkIds }: { providerNetworkIds?: string[] }) {
  const {
    data: providerNetworkData,
    isError: isProviderNetworkDataError,
    isLoading: isProviderNetworkDataLoading
  } = useProviderNetworkForCurrentUser()
  const providerNetworkIdentifier = providerNetworkData?.providerNetworkIdentifier
  const providerNetworkConfig =
    !isProviderNetworkDataError && !isProviderNetworkDataLoading && getProviderNetworkConfig(providerNetworkIdentifier)

  return (
    <>
      {providerNetworkConfig?.externalProviderNetworkId &&
        providerNetworkIds?.includes(providerNetworkConfig?.externalProviderNetworkId) && (
          <Chip size="small" color="info" label={providerNetworkConfig?.networkName} />
        )}
    </>
  )
}

type ProviderDetailsProps = {
  provider: Provider
  rank?: number
  searchLocation: string
  benefit: Benefit
}

export function ProviderDetails({ provider, rank, searchLocation, benefit }: ProviderDetailsProps): JSX.Element {
  const {
    providerName,
    parentIds,
    address,
    phone,
    website,
    distanceInKm,
    carrotPartner,
    locationMayVary,
    providerTypeIds,
    networkIds,
    isVirtual,
    providerNetworkIds
  } = provider

  const { companyId } = useCompanyContext()
  const { companyCountryCode } = useUserContext()
  const localizedProviderDistance = useGetLocalizedDistanceStringWithUnits({
    distanceInKm,
    countryCode: companyCountryCode,
    numberOfFractionDigits: 1
  })

  const networkConfigForEnv = Settings.COMPANY_IDS_WITH_TIERED_PROVIDER_NETWORK

  const intl = useIntl()
  const theme = useTheme()

  const requestAppointmentButton = (
    <Button
      variant="outlined"
      size="small"
      color="secondary"
      component={ReactRouterLink}
      to={`/providerfinder/requestappointment/${encodeURIComponent(providerName)}`}
      title={intl.formatMessage({ defaultMessage: "Contact our team to book an appointment" })}
      sx={{ inlineSize: "fit-content", marginBlockStart: (theme) => theme.tokens.spacing.md }}
      data-id="provider-request-appt-button"
      data-provider-name={{ providerName }}
    >
      <Box
        display="flex"
        alignItems="center"
        flexDirection="row"
        gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      >
        <FormattedMessage
          defaultMessage="Request appointment"
          description="Clicking on the Request appointment button will redirect you to directions on how to book an appointment at a provider"
        />
      </Box>
    </Button>
  )

  const mainContent = (
    <Box {...getPiiProps()} display="flex" flexDirection="column" maxWidth="sm">
      {networkIds && networkIds.length && networkConfigForEnv.includes(companyId) ? (
        <ProviderTypeTagsForExtendedNetworks
          parentIds={parentIds}
          providerTypeIds={providerTypeIds}
          networkIds={networkIds}
          benefit={benefit}
          providerNetworkIds={providerNetworkIds}
        />
      ) : (
        <Box display="flex" flexDirection="row" gap={(theme) => theme.spacing(theme.tokens.spacing.xs)}>
          <ProviderTypeTags parentIds={parentIds} providerTypeIds={providerTypeIds} benefit={benefit} />
          <Stack
            direction="row"
            spacing="0"
            useFlexGap
            flexWrap="wrap"
            gap={(theme) => `${theme.spacing(theme.tokens.spacing.xs)} ${theme.spacing(theme.tokens.spacing.xxs)}`}
            sx={(theme) => ({ marginBlockEnd: theme.spacing(theme.tokens.spacing.sm) })}
          >
            <ProviderNetworkChip providerNetworkIds={providerNetworkIds} />
          </Stack>
        </Box>
      )}
      <Typography
        variant="h4"
        component="h3"
        color={(theme) => theme.palette.text.secondary}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
      >
        {providerName}
      </Typography>
      <Typography
        variant="body1"
        color={(theme) => theme.palette.text.secondary}
        marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        sx={{ wordBreak: "break-word" }}
      >
        {address}
      </Typography>
      {locationMayVary && (
        <Box
          display="flex"
          flexDirection="row"
          gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
          alignItems="start"
          marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xs)}
        >
          <LocationIcon color="secondary" />
          <Typography
            variant="body2"
            color="secondary"
            marginBottom={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
            sx={{ wordBreak: "break-word" }}
            fontStyle="italic"
          >
            <FormattedMessage defaultMessage="Contact the provider to confirm. They may offer services virtually or from multiple locations." />
          </Typography>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="row"
        gap={(theme) => `${theme.spacing(theme.tokens.spacing.xs)} ${theme.spacing(theme.tokens.spacing.lg)}`}
        sx={{ whiteSpace: "nowrap", flexWrap: "wrap" }}
      >
        {phone && (
          <Link data-id="provider-phone-link" dir="ltr" href={`tel:${phone}`} color="inherit">
            {phone}
          </Link>
        )}
        {website && (
          <Link
            data-id="provider-website-link"
            href={website}
            target="_blank"
            rel="noreferrer noopener"
            color="inherit"
          >
            <FormattedMessage defaultMessage="Visit website" />
          </Link>
        )}
        {address && <DirectionsLink address={address} searchLocation={searchLocation} />}
      </Box>
      {carrotPartner && requestAppointmentButton}
      {isVirtual && <VirtualServicesTag />}
    </Box>
  )

  const distanceOrVirtualDisplay = (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      alignSelf="stretch"
      justifyContent={["flex-start", null, "center"]}
      gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
      aria-label={distanceInKm ? localizedProviderDistance : intl.formatMessage({ defaultMessage: "Virtual provider" })}
      minWidth={90}
    >
      <Box
        component="span"
        color={(theme) => theme.palette.text.tertiary}
        display="flex"
        gap={(theme) => theme.spacing(theme.tokens.spacing.xxs)}
        alignItems="center"
      >
        {distanceInKm ? (
          <>
            <LocationIcon fontSize="inherit" role="presentation" color="inherit" />
            <Typography variant="body2" color="inherit">
              {localizedProviderDistance}
            </Typography>
          </>
        ) : (
          !address && <VirtualIcon fontSize="medium" color="inherit" />
        )}
      </Box>
    </Box>
  )

  const carrotPartnerBadgeSpacer = useMediaQuery(theme.breakpoints.up("sm")) ? <Box width="24px" /> : null

  const carrotPartnerBadge = carrotPartner ? (
    <Box minWidth="24px" padding={["0", "44px 0 0 0", "44px 0 0 0", "44px 0 0 0"]}>
      <img src={carrotPartnerIndicator} alt={intl.formatMessage({ defaultMessage: "Carrot Partner indicator" })} />
    </Box>
  ) : (
    carrotPartnerBadgeSpacer
  )

  return (
    <Box
      display="flex"
      gap={(theme) => [theme.spacing(theme.tokens.spacing.sm), null, theme.spacing(theme.tokens.spacing.xs)]}
      flexDirection={["column", "row", "row", "row"]}
      data-provider-search-result-rank={rank}
      data-provider-uuid={provider.providerUuid}
      data-is-virtual-provider={Boolean(isVirtual)}
      data-provider-has-address={Boolean(address)}
    >
      {carrotPartnerBadge}
      <Box
        display="flex"
        width="100%"
        justifyContent="space-between"
        gap={(theme) => [
          theme.spacing(theme.tokens.spacing.md),
          theme.spacing(theme.tokens.spacing.sm),
          theme.spacing(theme.tokens.spacing.sm),
          theme.spacing(theme.tokens.spacing.md)
        ]}
        flexDirection={["column", "row", "row", "row"]}
      >
        {mainContent}
        {distanceOrVirtualDisplay}
      </Box>
    </Box>
  )
}
