import Settings from "./CarrotConfig"
import { MessageDescriptor, defineMessage } from "react-intl"
import { CarrotErrorCodes, getErrorMessageFromCode } from "./CarrotErrors"

type RegexPatterns = {
  email: RegExp
  utf8Characters: RegExp
  whiteSpace: RegExp
  containsAtSymbol: RegExp
  containsNumber: RegExp
  containsSpecialCharacter: RegExp
  hasUpperCase: RegExp
  hasLowerCase: RegExp
}

const regexes: RegexPatterns = {
  // https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
  email:
    /[\w!#$%&'*+\-/=?^_`{|}~]+(\.[\w!#$%&'*+\-/=?^_`{|}~]+)*@((([-\w]+\.)+[a-zA-Z]{2,62})|(([0-9]{1,3}\.){3}[0-9]{1,3}))/g,
  // eslint-disable-next-line no-control-regex
  utf8Characters: /[^\x00-\x7F]/g,
  whiteSpace: /\s/g,
  containsAtSymbol: /@/g,
  containsNumber: /\d/g,
  containsSpecialCharacter: /[^\p{L}\p{N}]/u, // Matches any non-letter or non-number in Unicode to support international characters
  hasUpperCase: /[\p{Lu}]/u, // Matches any uppercase letter in Unicode
  hasLowerCase: /\p{L}/u // Matches any letter in Unicode - all letters are considered lowercase by default
}

// Only allow urls to start with allowed Carrot URLs and end with combinations of
// slashes and alphanumeric characters. This prevents redirecting to potentially
// malicious URLs e.g. https://admin.get-carrot.com.myfakecarrot.com
const allowedUrlsRegexes = [
  `^${Settings.CARROT_BACKEND_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.CARROT_ADMIN_V2_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.CARROT_FRONTEND_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.ABALONE_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^${Settings.PRODUCT_CONTENT_URL}[/a-zA-Z0-9?%=_-]*$`,
  `^/connect/authorize([/?a-zA-Z0-9%=_&.-]*)*$`
]

function validatePasswordRegex(value: string | null): null | MessageDescriptor {
  const complexityRules = [
    regexes.containsNumber,
    regexes.containsSpecialCharacter,
    regexes.hasUpperCase,
    regexes.hasLowerCase
  ]

  const passedRules = complexityRules.filter((regex) => regex.test(value))
  return passedRules.length >= 3 ? null : getErrorMessageFromCode(CarrotErrorCodes.PASSWORD_NOT_COMPLEX)
}

function validateEmail(value: string | null): null | MessageDescriptor {
  if (!value) {
    return null
  }
  if (!value.match(regexes.containsAtSymbol)) {
    return defineMessage({ defaultMessage: "Value is missing an '@'." })
  }
  if (!value.match(regexes.email) || Boolean(value.match(regexes.whiteSpace)) || containsMoreThanOneAtSymbol(value)) {
    return defineMessage({ defaultMessage: "Please enter a valid email address." })
  }
  if (value.match(regexes.utf8Characters)) {
    return defineMessage({
      defaultMessage:
        "Unfortunately, our system isn't currently able to accept email addresses that use accent marks or other special characters."
    })
  }
  return null
}

function validateUrl(value: string | null): boolean {
  if (!value) {
    return false
  }
  const regexMatches = allowedUrlsRegexes.map((regex) => value.match(regex))
  return regexMatches.some((match) => match !== null)
}

function containsMoreThanOneAtSymbol(value: string): boolean {
  return value.match(regexes.containsAtSymbol).length > 1
}

function validateRequiredEmail(value: string | null): null | MessageDescriptor {
  if (!value) {
    return defineMessage({ defaultMessage: "Required" })
  }
  return validateEmail(value)
}

const emailValidationCaroteneCore = (value: string | null): true | MessageDescriptor => {
  return validateRequiredEmail(value) ?? true
}

const emailValidationOptionalCaroteneCore = (value: string | null): true | MessageDescriptor => {
  return validateEmail(value) ?? true
}

const emailValidation = (value: string | null): true | MessageDescriptor => {
  return validateEmail(value) ?? true
}

export {
  regexes,
  validateUrl,
  validatePasswordRegex,
  emailValidation,
  emailValidationCaroteneCore,
  emailValidationOptionalCaroteneCore
}
