import React, { useContext } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import { useTelemedicineConsents } from "#/components/telemedicine/useTelemedicineConsents"
import { Box, DialogActions, DialogContent, DialogTitle, ForwardIcon, Progress } from "@carrotfertility/carotene-core"
import { DialogCloseButton, Form, FormButton, FormRadioGroup } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"

export function WhoWillBeReceivingCareStep(): JSX.Element {
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const { telemedicineConsentTime } = useTelemedicineConsents()
  const { send } = useStateMachine(
    telemedicineConsentTime.data?.telemedicineConsentTimeUtc ? "LEGAL_CONSENT_SIGNED" : null
  )
  const { preferredName, firstName, partnerPreferredName, partnerFirstName } = useCurrentUser()
  const { isPartnerAccessingAccount } = useUserRole()
  const partnerName = partnerPreferredName ?? partnerFirstName
  const intl = useIntl()

  const options = [
    { label: preferredName ?? firstName, value: "MEMBER" },
    {
      label: partnerName ?? intl.formatMessage({ defaultMessage: "{firstName}'s partner" }, { firstName }),
      value: "PARTNER"
    }
  ]
  return (
    <Form onSubmit={(formValues) => send(formValues.whoWillBeReceivingCare)}>
      <DialogCloseButton onClick={() => setOpenDialogFlow(false)} />
      {telemedicineConsentTime.isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Progress />
        </Box>
      ) : (
        <>
          <DialogTitle id="who-will-be-receiving-care-title">
            <FormattedMessage defaultMessage="Who will be recieving Care?" />
          </DialogTitle>
          <DialogContent>
            <FormRadioGroup
              aria-labelledby="who-will-be-receiving-care-title"
              name="whoWillBeReceivingCare"
              options={isPartnerAccessingAccount ? options.reverse() : options}
            />
          </DialogContent>
          <DialogActions>
            <FormButton type="submit" color="secondary" variant="outlined" endIcon={<ForwardIcon />}>
              <FormattedMessage defaultMessage="Continue" />
            </FormButton>
          </DialogActions>
        </>
      )}
    </Form>
  )
}
