import { fetchUserInfo, updateUserInfo } from "#/redux/actions/userActions"
import { useLDClient } from "launchdarkly-react-client-sdk"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { getUserInfo } from "#/redux/reducers/userInfo"
import { UserInfo } from "#/lib/carrot-api/types/UserInfo"
import { LaunchDarklyUser } from "../../../types/launchDarklyUser"
import { getCompanyInfo } from "#/redux/reducers/companyInfo"

const removePropertyFromObject = (property: string, obj: Record<string, any>): Record<string, any> => {
  const { [property]: _, ...rest } = obj
  return rest
}

interface IUserContext extends Omit<Partial<UserInfo & { userInfoLoadError: boolean }>, "preferredLocale"> {
  refreshUserInfo: () => (dispatch: any) => Promise<void>
  updateUserInfo: (...args: any) => void
  loadLDFlagsForUser: () => Promise<boolean>
}

const UserContext = React.createContext<IUserContext>(null)

export const useCurrentUser = () => React.useContext<IUserContext>(UserContext)

export function UserContextProvider({ children }: { children: React.ReactNode | React.ReactNode[] }): JSX.Element {
  const dispatch = useDispatch()
  // We don't want preferredLocale to be used, so we remove it from the userInfo object
  const userInfo: Omit<
    Partial<UserInfo & { userInfoLoadError: boolean }>,
    "preferredLocale"
  > = removePropertyFromObject("preferredLocale", useSelector(getUserInfo))

  const company = useSelector(getCompanyInfo)
  const ldClient = useLDClient()

  const loadLDFlagsForUser: () => Promise<boolean> = React.useCallback(async () => {
    if (ldClient && userInfo.email) {
      await ldClient.waitUntilReady()

      const ldUser: LaunchDarklyUser = {
        key: userInfo.email,
        email: userInfo.email,
        custom: {
          companyId: company.companyId.toString(),
          countryCode: company.countryCode,
          isPartner: false,
          isEnrolled: true,
          isEngaged: !userInfo.isInAppLockdown
        }
      }

      if (company.parentCompanyId) {
        ldUser.custom["parentCompanyId"] = company.parentCompanyId.toString()
      }

      await ldClient.identify(ldUser)
      return true
    }
    return false
  }, [
    ldClient,
    userInfo.email,
    userInfo.isInAppLockdown,
    company.companyId,
    company.countryCode,
    company.parentCompanyId
  ])

  function refreshUserInfo(): (dispatch: any) => Promise<void> {
    return dispatch(fetchUserInfo())
  }

  return (
    <UserContext.Provider
      value={{
        refreshUserInfo,
        updateUserInfo: (args) => dispatch(updateUserInfo(args)),
        company,
        loadLDFlagsForUser,
        ...userInfo
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
