import React from "react"
import { useIntl } from "react-intl"
import { Box, Typography, useTheme } from "@carrotfertility/carotene-core"

function ClosedThreadMessageBubble({ text }: { text: string }): JSX.Element {
  const theme = useTheme()
  return (
    <Box
      flexDirection="row"
      alignItems="start"
      display="flex"
      paddingX={theme.spacing(theme.tokens.spacing.xxl)}
      paddingBlockStart={theme.spacing(theme.tokens.spacing.xxl)}
      paddingBlockEnd={theme.spacing(theme.tokens.spacing.xl)}
    >
      <Typography variant="body2" color={theme.palette.text.disabled}>
        {text}
      </Typography>
    </Box>
  )
}

export function ClosedThreadMessage(): JSX.Element {
  const intl = useIntl()
  const text = intl.formatMessage({
    defaultMessage:
      "We've marked this conversation as resolved. If you have additional questions, you can start a follow-up conversation."
  })
  return <ClosedThreadMessageBubble text={text} />
}
