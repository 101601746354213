import { LegalConsentForTelemedicineStep } from "#/components/telemedicine/steps/LegalConsentForTelemedicineStep"
import { NoPartnerAccessStep } from "#/components/telemedicine/steps/NoPartnerAccessStep"
import { NoPartnerAccountStep } from "#/components/telemedicine/steps/NoPartnerAccountStep"
import { PartnerAccountDecisionStep } from "#/components/telemedicine/steps/PartnerAccountDecisionStep"
import { ResendPartnerInviteStep } from "#/components/telemedicine/steps/ResendPartnerInvite"
import { SignInAsMemberStep } from "#/components/telemedicine/steps/SignInAsMemberStep"
import { SignInAsPartnerStep } from "#/components/telemedicine/steps/SignInAsPartnerStep"
import { WhoWillBeReceivingCareStep } from "#/components/telemedicine/steps/WhoWillBeReceivingCareStep"
import { YouAreLeavingCarrotStep } from "#/components/telemedicine/steps/YouAreLeavingCarrotStep"

export enum Steps {
  WHO_WILL_RECEIVING_CARE = "Who will be receiving care",
  LEGAL_CONSENT = "Legal consent",
  NO_PARTNER_ACCOUNT = "No partner account",
  NO_PARTNER_ACCESS = "No partner access",
  SIGN_IN_AS_PARTNER = "Sign in as the partner",
  SIGN_IN_AS_MEMBER = "Sign in as the member",
  YOU_ARE_LEAVING_CARROT = "You are leaving Carrot",
  RESEND_PARTNER_INVITE = "Resend partner invite",
  PARTNER_ACCOUNT_DECISION = "Partner account decision"
}

export enum Workflows {
  TELEMEDICINE_FOR_MENOPAUSE_MEMBER = "telemedicineForMenoPauseMember",
  TELEMEDICINE_FOR_MENOPAUSE_PARTNER = "telemedicineForMenoPausePartner"
}

// prettier-ignore
export const telemedicineForMenoPauseSteps = [
  { name: Steps.WHO_WILL_RECEIVING_CARE, component: WhoWillBeReceivingCareStep },
  { name: Steps.LEGAL_CONSENT, component: LegalConsentForTelemedicineStep },
  { name: Steps.PARTNER_ACCOUNT_DECISION, component: PartnerAccountDecisionStep, decisionStep: true },
  { name: Steps.NO_PARTNER_ACCESS, component: NoPartnerAccessStep },
  { name: Steps.NO_PARTNER_ACCOUNT, component: NoPartnerAccountStep},
  { name: Steps.SIGN_IN_AS_PARTNER, component: SignInAsPartnerStep},
  { name: Steps.YOU_ARE_LEAVING_CARROT, component: YouAreLeavingCarrotStep},
  { name: Steps.SIGN_IN_AS_MEMBER, component: SignInAsMemberStep},
  { name: Steps.RESEND_PARTNER_INVITE, component: ResendPartnerInviteStep}
]

// prettier-ignore
const memberFlow = [
  { at: Steps.WHO_WILL_RECEIVING_CARE,                           given: "MEMBER",                            goto: Steps.LEGAL_CONSENT },
  { at: Steps.WHO_WILL_RECEIVING_CARE,                           given: "PARTNER",                           goto: Steps.PARTNER_ACCOUNT_DECISION },
  { at: Steps.WHO_WILL_RECEIVING_CARE,                           given: "LEGAL_CONSENT_SIGNED",              goto: Steps.YOU_ARE_LEAVING_CARROT },
  { at: Steps.PARTNER_ACCOUNT_DECISION,                          given: "PARTNER_ACCOUNT_ACTIVE",            goto: Steps.SIGN_IN_AS_PARTNER },
  { at: Steps.PARTNER_ACCOUNT_DECISION,                          given: "PARTNER_ACCESS_NO_ACCOUNT",         goto: Steps.NO_PARTNER_ACCOUNT },
  { at: Steps.PARTNER_ACCOUNT_DECISION,                          given: "NO_PARTNER_ACCESS",                 goto: Steps.NO_PARTNER_ACCESS },
  { at: Steps.PARTNER_ACCOUNT_DECISION,                          given: "PARTNER_INVITATION_NOT_ACCEPTED",   goto: Steps.RESEND_PARTNER_INVITE },
  { at: Steps.LEGAL_CONSENT,                                     given: "",                                  goto: Steps.YOU_ARE_LEAVING_CARROT },
]

// prettier-ignore
const partnerFlow = [
    { at: Steps.WHO_WILL_RECEIVING_CARE,                           given: "MEMBER",                            goto: Steps.SIGN_IN_AS_MEMBER },
    { at: Steps.WHO_WILL_RECEIVING_CARE,                           given: "PARTNER",                           goto: Steps.LEGAL_CONSENT },
    { at: Steps.WHO_WILL_RECEIVING_CARE,                           given: "LEGAL_CONSENT_SIGNED",              goto: Steps.YOU_ARE_LEAVING_CARROT },
    { at: Steps.LEGAL_CONSENT,                                     given: "",                                  goto: Steps.YOU_ARE_LEAVING_CARROT },
  ]

export const telemedicineForMenopauseWorkflows = [
  { name: Workflows.TELEMEDICINE_FOR_MENOPAUSE_MEMBER, workflow: memberFlow },
  { name: Workflows.TELEMEDICINE_FOR_MENOPAUSE_PARTNER, workflow: partnerFlow }
]
