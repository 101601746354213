import React, { useEffect } from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import usePartnerAccess from "#/components/partner-access/hooks/usePartnerAccess"
import useAboutYou from "#/components/carrot-plans/hooks/useAboutYou"

export function PartnerAccessDecisionStep(): JSX.Element {
  const { showPartnerAccess } = usePartnerAccess()
  const useAboutYouQuery = useAboutYou()
  const { send } = useStateMachine(null)

  useEffect(() => {
    const partnerEmailExists = !!useAboutYouQuery.data?.partnerEmail
    const sendToPartnerAccessStep = showPartnerAccess && partnerEmailExists
    send(sendToPartnerAccessStep ? "YES" : "NO")
  })
  return <></>
}
