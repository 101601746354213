import React from "react"
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography
} from "@carrotfertility/carotene-core"
import {
  getAvailableParentIdsByBenefitConfig,
  PARENT_ID_MAPPING,
  PARENT_ID_TO_PROVIDER_COUNT_MAPPING
} from "../../utils/parentIdMapping"
import { useGetHasProviderTypeInCountry } from "../../hooks/useGetHasProviderTypeInCountry"
import { useUserContext } from "../../context/UserContext"
import { FormattedMessage, useIntl } from "react-intl"
import { useIntlSort } from "../../../../utils/hooks/useIntlSort"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { FertilityWraparoundMessageForDropdown } from "../sidebar/FertilityWraparoundMessage"

type ProviderTypeFilterDropdownProps = {
  selectedProviderType: string
  setSelectedProviderType: React.Dispatch<React.SetStateAction<string>>
  benefit: Benefit
}

function RenderSelectedProviderType(providerType: string): JSX.Element {
  if (!providerType) {
    return (
      <Typography color={(theme) => theme.palette.text.disabled}>
        <FormattedMessage defaultMessage="Select a provider type" />
      </Typography>
    )
  }

  return (
    <Typography component="span" overflow="hidden" textOverflow="ellipsis">
      <FormattedMessage {...PARENT_ID_MAPPING[providerType].shortDisplayName} />
    </Typography>
  )
}

export function ProviderTypeFilterDropdown({
  selectedProviderType,
  setSelectedProviderType,
  benefit
}: ProviderTypeFilterDropdownProps): JSX.Element {
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const availableParentIds = getAvailableParentIdsByBenefitConfig(benefit)
  const { companyCountryCode } = useUserContext()
  const { data: hasProviderTypes, isLoading: isLoadingHasProviderTypes } =
    useGetHasProviderTypeInCountry(companyCountryCode)
  const hasFertilityWraparoundEnabled = benefit.providerFinder.showFertilityWrapAroundMessage

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value }
    } = event
    setSelectedProviderType(value)
  }
  const options = availableParentIds
    .filter((parentId) => {
      const providerType = PARENT_ID_TO_PROVIDER_COUNT_MAPPING[parentId]
      return hasProviderTypes && hasProviderTypes[providerType]
    })
    .sort((parentId1, parentId2) => {
      const parentFullDisplayName1 = intl.formatMessage(PARENT_ID_MAPPING[parentId1].combinedDisplayName)
      const parentFullDisplayName2 = intl.formatMessage(PARENT_ID_MAPPING[parentId2].combinedDisplayName)
      return localeComparator(parentFullDisplayName1, parentFullDisplayName2)
    })
  const optionsElements = options.map((parentId) => {
    const { combinedDisplayName } = PARENT_ID_MAPPING[parentId]
    const text = intl.formatMessage(combinedDisplayName)

    return (
      <MenuItem value={parentId} key={parentId}>
        <Box display="flex" flexDirection="column">
          {text}
        </Box>
      </MenuItem>
    )
  })

  if (isLoadingHasProviderTypes) {
    return null
  }

  return (
    <FormControl fullWidth>
      <InputLabel id={"provider-type-dropdown-select-label"}>
        <FormattedMessage defaultMessage="Provider type" />
      </InputLabel>
      <Select
        labelId="provider-type-dropdown-select-label"
        id="provider-type-dropdown-select"
        value={selectedProviderType}
        onChange={handleChange}
        renderValue={RenderSelectedProviderType}
      >
        {optionsElements}
        {hasFertilityWraparoundEnabled && <FertilityWraparoundMessageForDropdown />}
      </Select>
    </FormControl>
  )
}
