import React from "react"
import { useProviderTypeSearchParam } from "../../utils/providerFinderSearchUrl"
import { useUserContext } from "../../context/UserContext"
import {
  getAvailableParentIdsByBenefitConfig,
  PARENT_ID_MAPPING,
  PARENT_ID_TO_PROVIDER_COUNT_MAPPING
} from "../../utils/parentIdMapping"
import { Benefit } from "#/lib/carrot-api/types/Benefit"
import { FormattedMessage, useIntl } from "react-intl"
import { useIntlSort } from "../../../../utils/hooks/useIntlSort"
import { useGetHasProviderTypeInCountry } from "#/pages/provider-finder/hooks/useGetHasProviderTypeInCountry"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  DownIcon,
  FormControl,
  FormControlLabel,
  RadioGroup,
  FormHelperText,
  Radio,
  Typography
} from "@carrotfertility/carotene-core"

export function ProviderTypeFilter({ benefit }: { benefit: Benefit }): JSX.Element {
  const { companyCountryCode } = useUserContext()
  const intl = useIntl()
  const { localeComparator } = useIntlSort()
  const { data: hasProviderTypes, isLoading: isLoadingHasProviderTypes } =
    useGetHasProviderTypeInCountry(companyCountryCode)
  const availableParentIds = getAvailableParentIdsByBenefitConfig(benefit)

  const [providerTypeSearchParam, setProviderTypeSearchParam] = useProviderTypeSearchParam()

  const providerTypeFromParams = providerTypeSearchParam ?? new Set(availableParentIds)

  const filterByProviderType = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const {
      target: { checked, name }
    } = event

    const newProviderTypes = new Set<string>()

    if (checked) {
      newProviderTypes.add(name)
      setProviderTypeSearchParam(newProviderTypes)
    }
  }

  const options = availableParentIds
    .filter((parentId) => {
      const providerType = PARENT_ID_TO_PROVIDER_COUNT_MAPPING[parentId]
      return hasProviderTypes && hasProviderTypes[providerType]
    })
    .sort((parentId1, parentId2) => {
      const parentFullDisplayName1 = intl.formatMessage(PARENT_ID_MAPPING[parentId1].fullDisplayName)
      const parentFullDisplayName2 = intl.formatMessage(PARENT_ID_MAPPING[parentId2].fullDisplayName)
      return localeComparator(parentFullDisplayName1, parentFullDisplayName2)
    })
    .map((parentId) => {
      const { fullDisplayName, getSecondaryText } = PARENT_ID_MAPPING[parentId]
      return (
        <Box key={parentId}>
          <FormControlLabel
            control={<Radio checked={providerTypeFromParams.has(parentId)} value={parentId} name={parentId} />}
            label={intl.formatMessage(fullDisplayName)}
          />
          <Box sx={(theme) => ({ marginInlineStart: theme.spacing(theme.tokens.spacing.xxl) })}>
            {getSecondaryText && (
              <FormHelperText>
                {intl.formatMessage(getSecondaryText({ countryCode: companyCountryCode }))}
              </FormHelperText>
            )}
          </Box>
        </Box>
      )
    })

  if (isLoadingHasProviderTypes) {
    return null
  }
  return (
    <Box
      padding={(theme) =>
        `${theme.spacing(theme.tokens.spacing.none)} ${theme.spacing(theme.tokens.spacing.sm)} ${theme.spacing(
          theme.tokens.spacing.sm
        )}`
      }
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.tokens.borderRadius.sm
      })}
    >
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<DownIcon fontSize="large" />}>
          <Typography variant="h6">
            <FormattedMessage defaultMessage="Provider type" />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" variant="standard">
            <RadioGroup onChange={filterByProviderType}>
              <Box
                role="presentation"
                display="flex"
                flexDirection="column"
                gap={(theme) => theme.spacing(theme.tokens.spacing.md)}
              >
                {options}
              </Box>
            </RadioGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
