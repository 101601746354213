import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import useAboutYou from "#/components/carrot-plans/hooks/useAboutYou"
import { StepLayout } from "#/components/carrot-plans/shared/StepLayout"
import { ButtonFooterLayout, ContinueButton } from "#/components/carrot-plans/shared/ButtonFooter"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { FormattedMessage, useIntl } from "react-intl"
import { RoutingFlowTitle } from "#/components/carrot-plans/shared"
import { Stack, useTheme } from "@carrotfertility/carotene-core"

export default function AboutYourPartnerNameStep(): JSX.Element {
  const intl = useIntl()
  const theme = useTheme()
  const requiredText = intl.formatMessage({ defaultMessage: "Required" })
  const { data, updateAboutYou } = useAboutYou()
  const { send } = useStateMachine(data?.partnerFirstName && data?.partnerLastName ? "" : null)

  async function onClickContinue(formValues: { partnerFirstName: string; partnerLastName: string }): Promise<void> {
    if (formValues?.partnerFirstName && formValues?.partnerLastName) {
      await updateAboutYou(formValues)
      send("")
    }
  }
  const header = intl.formatMessage({
    defaultMessage: "What's your partner's name?"
  })
  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form onSubmit={onClickContinue}>
            <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <FormTextField
                name="partnerFirstName"
                label={intl.formatMessage({ defaultMessage: "Partner's first name" })}
                inputProps={{ "data-testid": "about-you-partnerFirstName" }}
                defaultValue={data?.partnerFirstName}
                registerOptions={{ required: requiredText }}
              />
              <FormTextField
                name="partnerLastName"
                label={intl.formatMessage({ defaultMessage: "Partner's last name" })}
                inputProps={{ "data-testid": "about-you-partnerLastName" }}
                defaultValue={data?.partnerLastName}
                registerOptions={{ required: requiredText }}
              />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}
