import React, { useCallback, useEffect, useRef } from "react"
import { Message, ThreadMessages } from "./inAppMessagingTypes"
import { CenteredLoadingIndicator } from "../views/molecules/Molecules"
import { reportError } from "../../utils/ErrorReporting"
import useGetThreadMessages from "./hooks/useGetThreadMessages"
import { DisclaimerMessage } from "./MessagingDisclaimer"
import useMarkThreadAsRead from "./hooks/useMarkThreadAsRead"
import { useInAppMessaging } from "../context/messaging/InAppMessagingContext"
import { ClosedThreadMessage } from "./ClosedThreadMessage"
import { getParsedHtmlElements } from "./utils"
import { FormattedTime, FormattedMessage, useIntl } from "react-intl"
import { getPiiProps } from "#/services/tracking"
import { Box, Link, Stack, Tile, Typography, useTheme } from "@carrotfertility/carotene-core"
import { EmployeeSupportUrl } from "../../utils/EmployeeSupportLink"

type MessageReplyBubbleProps = {
  message: Message
}

function MessageReplyBubble({ message }: MessageReplyBubbleProps): JSX.Element {
  const theme = useTheme()

  return (
    <Box
      borderRadius={theme.tokens.borderRadius.md}
      flexDirection={"column"}
      alignItems={"start"}
      padding={theme.spacing(theme.tokens.spacing.lg)}
      bgcolor={(theme) =>
        message.authoredBy === "CARROT" ? theme.palette.background.default : theme.palette.secondary.light
      }
    >
      <Stack direction="row" spacing={theme.spacing(theme.tokens.spacing.xs)} alignItems="center">
        {message.authoredBy === "CARROT" ? (
          <Typography variant="body1" fontWeight="bold" color={theme.palette.primary.dark}>
            <FormattedMessage defaultMessage="Care Team" />
          </Typography>
        ) : (
          <Typography variant="body1" fontWeight="bold" color={theme.palette.text.secondary}>
            <FormattedMessage defaultMessage="You" />
          </Typography>
        )}
        <Typography variant="body1" color={theme.palette.text.tertiary}>
          <FormattedTime value={message.createdAt} />
        </Typography>
      </Stack>
      <Box
        {...getPiiProps()}
        padding="24px 20px"
        flexDirection="column"
        sx={{
          whiteSpace: "pre-line",
          lineHeight: theme.typography.body1.lineHeight,
          wordBreak: "break-word",
          ...theme.typography.body1
        }}
      >
        {getParsedHtmlElements(message.htmlBody)}
      </Box>
    </Box>
  )
}
export function MessagingErrorContainer(): JSX.Element {
  return (
    <Tile>
      <Stack direction={"column"} spacing={"small"}>
        <Typography variant="h3" component="h2">
          <FormattedMessage defaultMessage="Something's not working" />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage
            defaultMessage="Try reloading this page. If the problem persists, feel free to <link>contact us</link>."
            values={{
              link: (linkContent) => (
                <Link href={EmployeeSupportUrl} target={"_blank"}>
                  {linkContent}
                </Link>
              )
            }}
          />
        </Typography>
      </Stack>
    </Tile>
  )
}

export function ThreadMessagesContainer(): JSX.Element {
  const intl = useIntl()

  const { threadUpdateInFlight, selectedThread, threadIdForFailedUpdate } = useInAppMessaging()
  const {
    isLoading: isLoadingMessages,
    error,
    data,
    isSuccess: messagesFetchedSuccessfully
  } = useGetThreadMessages(selectedThread?.threadId)
  const { mutate: markThreadAsRead } = useMarkThreadAsRead()
  const theme = useTheme()

  useEffect(() => {
    if (selectedThread?.hasUnreadMessages && !isLoadingMessages && messagesFetchedSuccessfully) {
      markThreadAsRead(selectedThread?.threadId)
    }
  }, [
    selectedThread?.hasUnreadMessages,
    messagesFetchedSuccessfully,
    isLoadingMessages,
    selectedThread?.threadId,
    markThreadAsRead
  ])

  const getMappedMessageBubbles = useCallback(
    (data: ThreadMessages) => {
      let lastUsedDate: string | null = null

      function dateHelper(lastUsedDate: string | null, currentDate: string): string | null {
        if (lastUsedDate === currentDate) return null
        if (currentDate === intl.formatDate(new Date(), { month: "short", day: "numeric" })) {
          return intl.formatRelativeTime(0, "day", { numeric: "auto" })
        } else {
          return currentDate
        }
      }

      return [...data.messages].reverse().map((message, idx) => {
        const currentDate = intl.formatDate(message.createdAt, { month: "short", day: "numeric" })
        const dateToDisplay = dateHelper(lastUsedDate, currentDate)
        if (dateToDisplay) lastUsedDate = currentDate
        return (
          <React.Fragment key={idx}>
            <Box display="flex" justifyContent="center" paddingY={theme.spacing(theme.tokens.spacing.md)}>
              <Typography variant="overline" color={theme.palette.text.tertiary}>
                {dateToDisplay}
              </Typography>
            </Box>
            <MessageReplyBubble message={message} />
          </React.Fragment>
        )
      })
    },
    [intl, theme]
  )

  const AlwaysScrollToBottom = (): JSX.Element => {
    const elementRef = useRef<HTMLDivElement>()
    useEffect(() => elementRef.current.scrollIntoView({ block: "nearest", inline: "start" }), [])
    return <div ref={elementRef} />
  }

  if (isLoadingMessages) {
    return <CenteredLoadingIndicator />
  }
  if (error) {
    reportError(error, {
      threadId: selectedThread?.threadId.toString()
    })
    return (
      <Box marginInlineStart={theme.spacing(theme.tokens.spacing.xl)}>
        <MessagingErrorContainer />
      </Box>
    )
  }
  if (data) {
    return (
      <Box
        paddingBlockStart={theme.spacing(theme.tokens.spacing.sm)}
        paddingX={theme.spacing(theme.tokens.spacing.sm)}
        marginBottom={theme.spacing(theme.tokens.spacing.sm)}
      >
        <DisclaimerMessage />
        {getMappedMessageBubbles(data)}
        {selectedThread?.isClosed && <ClosedThreadMessage />}
        {threadIdForFailedUpdate === selectedThread?.threadId && (
          <Typography
            color={theme.palette.error.main}
            variant="body2"
            paddingTop={theme.spacing(theme.tokens.spacing.sm)}
          >
            <FormattedMessage
              defaultMessage="Your message failed to send. Please try again or <link>contact us</link>."
              values={{
                link: (linkContent) => (
                  <Link
                    color="inherit"
                    href={EmployeeSupportUrl}
                    target={"_blank"}
                    sx={{ fontSize: theme.typography.body2.fontSize }}
                  >
                    {linkContent}
                  </Link>
                )
              }}
            />
          </Typography>
        )}
        {threadUpdateInFlight && <CenteredLoadingIndicator />}
        <AlwaysScrollToBottom />
      </Box>
    )
  }

  return null
}
