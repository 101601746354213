import React from "react"
import { useSelector } from "react-redux"
import { AboutThisExpenseWhatKindOfJourney } from "./WhatKindOfJourney"
import { useReimbursementContext } from "../../context/reimbursement/GetReimbursedContext"
import { getCountryCode } from "#/redux/reducers/companyInfo"
import { JourneySubTypes, JourneyTypes } from "./ExpenseJourneyTypes"
import { defineMessage, MessageDescriptor, useIntl } from "react-intl"
import { CountryCode } from "#/utils/CountryCode"
import useBenefit from "../../dynamic-content/hooks/useGetBenefit"
import { Benefit } from "#/lib/carrot-api/types/Benefit"

type WhatServiceOption = {
  label: MessageDescriptor
  value: string
  filter?: (e?: JourneyTypes, geo?: CountryCode, benefit?: Benefit) => boolean
}

const whatServicesOptions: WhatServiceOption[] = [
  {
    value: JourneySubTypes.DOULA_SERVICES_PRENATAL_AND_BIRTH,
    label: defineMessage({ defaultMessage: "Doula services: Prenatal and birth" }),
    filter: (e, _, benefit) =>
      e === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES &&
      benefit.journeys.pregnancy.isAppSupportEnabled &&
      benefit.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered
  },
  {
    value: JourneySubTypes.DOULA_SERVICES_POSTPARTUM,
    label: defineMessage({ defaultMessage: "Doula services: Postpartum" }),
    filter: (e, _, benefit) =>
      e === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES &&
      benefit.journeys.pregnancy.isAppSupportEnabled &&
      benefit.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered
  },
  {
    value: JourneySubTypes.DOULA_SERVICES_PREGNANCY_LOSS,
    label: defineMessage({ defaultMessage: "Doula services: Pregnancy loss" }),
    filter: (e, _, benefit) =>
      e === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES &&
      benefit.journeys.pregnancy.isAppSupportEnabled &&
      benefit.journeys.pregnancy.eligibleExpensesDetail.doulaExpensesCovered
  },
  {
    value: JourneySubTypes.HUMAN_MILK_SHIPPING,
    label: defineMessage({ defaultMessage: "Human milk shipping" }),
    filter: (e, _, benefit) =>
      e === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES &&
      benefit.journeys.pregnancy.isAppSupportEnabled &&
      benefit.journeys.pregnancy.eligibleExpensesDetail.milkShippingCovered
  },
  {
    value: JourneySubTypes.CHILDBIRTH_CLASSES,
    label: defineMessage({ defaultMessage: "Childbirth classes" }),
    filter: (e, _, benefit) =>
      e === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES &&
      benefit.journeys.pregnancy.isAppSupportEnabled &&
      benefit.journeys.pregnancy.eligibleExpensesDetail.childbirthClassesCovered
  },
  {
    value: JourneySubTypes.OTHER_PREGNANCY_SERVICES,
    label: defineMessage({ defaultMessage: "Other services" }),
    filter: (e, _) => e === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES
  },
  {
    value: JourneySubTypes.MEDICATION,
    label: defineMessage({ defaultMessage: "Medications" }),
    filter: (e, companyGeo) =>
      [JourneyTypes.MENOPAUSE, JourneyTypes.LOW_TESTOSTERONE].includes(e) ||
      (companyGeo === CountryCode.United_States && [JourneyTypes.GENDER_AFFIRMING_CARE].includes(e))
  },
  {
    value: JourneySubTypes.MEDICAL_APPOINTMENTS,
    label: defineMessage({ defaultMessage: "Medical appointments" }),
    filter: (e, companyGeo) =>
      companyGeo !== CountryCode.United_States && [JourneyTypes.MENOPAUSE, JourneyTypes.LOW_TESTOSTERONE].includes(e)
  },
  {
    value: JourneySubTypes.IN_PERSON_CARE,
    label: defineMessage({ defaultMessage: "In-person care" }),
    filter: (e, companyGeo) =>
      companyGeo === CountryCode.United_States &&
      [JourneyTypes.MENOPAUSE, JourneyTypes.LOW_TESTOSTERONE, JourneyTypes.GENDER_AFFIRMING_CARE].includes(e)
  },
  {
    value: JourneySubTypes.ONLINE_CARE,
    label: defineMessage({ defaultMessage: "Online care" }),
    filter: (e, companyGeo) =>
      companyGeo === CountryCode.United_States &&
      [JourneyTypes.MENOPAUSE, JourneyTypes.LOW_TESTOSTERONE, JourneyTypes.GENDER_AFFIRMING_CARE].includes(e)
  },
  {
    value: JourneySubTypes.LABS,
    label: defineMessage({ defaultMessage: "Labs" }),
    filter: (e, companyGeo) =>
      (companyGeo === CountryCode.United_States && [JourneyTypes.GENDER_AFFIRMING_CARE].includes(e)) ||
      [JourneyTypes.MENOPAUSE].includes(e)
  },
  {
    value: JourneySubTypes.BINDERS_PACKERS_BODY_SHAPING,
    label: defineMessage({ defaultMessage: "Binders or packers for body shaping" }),
    filter: (e, companyGeo) =>
      companyGeo === CountryCode.United_States && [JourneyTypes.GENDER_AFFIRMING_CARE].includes(e)
  },
  {
    value: JourneySubTypes.TRAVEL_ELIGIBLE_PROVIDER,
    label: defineMessage({ defaultMessage: "Travel to an eligible provider" }),
    filter: (e, companyGeo) =>
      companyGeo === CountryCode.United_States && [JourneyTypes.GENDER_AFFIRMING_CARE].includes(e)
  },
  {
    value: JourneySubTypes.NUTRITION_COUNSELING,
    label: defineMessage({ defaultMessage: "Nutrition counseling with a registered dietitian" }),
    filter: (e, companyGeo) =>
      companyGeo === CountryCode.United_States &&
      [JourneyTypes.MENOPAUSE, JourneyTypes.LOW_TESTOSTERONE, JourneyTypes.GENDER_AFFIRMING_CARE].includes(e)
  },
  {
    value: JourneySubTypes.TEMPERATURE_REGULATION_PRODUCTS,
    label: defineMessage({ defaultMessage: "Temperature regulation products" }),
    filter: (e, companyGeo) =>
      [
        CountryCode.United_States,
        CountryCode.Australia,
        CountryCode.United_Kingdom,
        CountryCode.Germany,
        CountryCode.Poland
      ].includes(companyGeo) && [JourneyTypes.MENOPAUSE].includes(e)
  }
]

export const AboutThisExpenseWhatKindOfService = (): JSX.Element => {
  const { state } = useReimbursementContext()
  const { expenseRelatedTo } = state
  const companyGeo = useSelector(getCountryCode)
  const intl = useIntl()
  const bodyText = intl.formatMessage({
    defaultMessage: "If you're not sure, choose the option that seems like the best fit."
  })

  // expenseRelatedTo.label has already been translated in ExpenseRelatedTo
  const journeyType = (expenseRelatedTo.label as string).toLocaleLowerCase()
  const { data: benefit } = useBenefit()
  let headerText
  if (expenseRelatedTo.value === JourneyTypes.PREGNANCY_AND_POSTPARTUM_SERVICES) {
    headerText = intl.formatMessage({ defaultMessage: "What kind of service is this related to?" })
  } else {
    headerText = intl.formatMessage({ defaultMessage: "What kind of {journeyType} expense is this?" }, { journeyType })
  }

  const filteredServiceTypes = whatServicesOptions
    .filter((c) => !c.filter || c.filter((expenseRelatedTo.value as JourneyTypes) || null, companyGeo, benefit))
    .map((serviceOption) => ({
      ...serviceOption,
      label: intl.formatMessage(serviceOption.label)
    }))

  return (
    <AboutThisExpenseWhatKindOfJourney
      headerText={headerText}
      bodyText={bodyText}
      selectOptions={filteredServiceTypes}
    />
  )
}
