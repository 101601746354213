import React from "react"
import { useStateMachine } from "../../../context/stateMachine/StateMachineV2"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import {
  hasQuestionBeenSkipped,
  useCheckRoutingInfoQueryCache,
  useUpdateRoutingInfo
} from "#/components/carrot-plans/shared/useQueryRoutingInfo"
import { ButtonFooterLayout, ContinueButton, SkipButton } from "#/components/carrot-plans/shared/ButtonFooter"
import { StepLayout } from "#/components/carrot-plans/shared/StepLayout"
import { defineMessage, FormattedMessage, useIntl } from "react-intl"
import { Form, FormTextField } from "@carrotfertility/carotene-core-x"
import { RoutingFlowTitle, useConvertTranslatedLabel } from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"

const OPTIONS = [
  { label: defineMessage({ defaultMessage: "Yes" }), value: "YES" },
  { label: defineMessage({ defaultMessage: "No" }), value: "NO" }
]
export function GestationalCarrierInMindStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const defaultValue = routingData?.gestationalCarrierInMind
  const { send } = useStateMachine(defaultValue ? "" : null)
  const intl = useIntl()
  const radioOptions = useConvertTranslatedLabel(OPTIONS)

  async function onClickContinue(formValues: { gestationalCarrierInMind: string }): Promise<void> {
    await mutateAsync(formValues, { onSuccess: () => send("") })
  }

  const header = intl.formatMessage({
    defaultMessage: "Do you already have a gestational carrier in mind?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form
            onSubmit={onClickContinue}
            defaultValues={{ gestationalCarrierInMind: routingData?.gestationalCarrierInMind }}
          >
            <FormRadioButtonGroup
              name="gestationalCarrierInMind"
              controllerProps={{ rules: { required: intl.formatMessage({ defaultMessage: "Required" }) } }}
              options={radioOptions}
            />
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
            />
          </Form>
        }
      />
    </>
  )
}

export function GestationalCarrierDeliveryStep(): JSX.Element {
  const { mutateAsync } = useUpdateRoutingInfo()
  const routingData = useCheckRoutingInfoQueryCache()
  const [defaultCountry = null, defaultState = null] = [
    routingData?.gestationalCarrierDeliverLocation?.country,
    routingData?.gestationalCarrierDeliverLocation?.state
  ]
  const filledOut = defaultState !== null && defaultCountry !== null
  const { send } = useStateMachine(
    filledOut || hasQuestionBeenSkipped("gestationalCarrierDeliverLocation", routingData) ? "" : null
  )
  const intl = useIntl()
  const theme = useTheme()

  function sanitizeInputs(inputValue: string): string | null {
    return inputValue === null || inputValue.split("").every((char) => char === " ") || inputValue === ""
      ? null
      : inputValue
  }

  async function onSkip() {
    await mutateAsync(
      {
        skipFields: ["gestationalCarrierDeliverLocation"]
      },
      { onSuccess: () => send("") }
    )
  }

  async function onSubmit(formValues: { country?: string; state?: string }) {
    if (formValues?.country || formValues?.state) {
      await mutateAsync(
        {
          gestationalCarrierDeliverLocation: {
            country: sanitizeInputs(formValues?.country),
            state: sanitizeInputs(formValues?.state)
          }
        },
        { onSuccess: () => send("") }
      )
    } else {
      await onSkip()
    }
  }

  const header = intl.formatMessage({
    defaultMessage: "Where do you hope to have your gestational carrier deliver?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={header}
        formElements={
          <Form onSubmit={onSubmit}>
            <Stack spacing={theme.spacing(theme.tokens.spacing.lg)}>
              <FormTextField
                label={intl.formatMessage({ defaultMessage: "State/province" })}
                name="state"
                id="stateProvince"
                defaultValue={defaultState}
              />
              <FormTextField
                label={intl.formatMessage({ defaultMessage: "Country" })}
                id="country"
                name="country"
                defaultValue={defaultCountry}
              />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
    </>
  )
}
