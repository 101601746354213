import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import usePartnerAccess, { useGetPartnerInviteStatus } from "#/components/partner-access/hooks/usePartnerAccess"
import { PartnerInviteStatus } from "#/components/partner-access/partnerAccessTypes"
import { Box, Progress } from "@carrotfertility/carotene-core"
import React, { useEffect } from "react"

export function PartnerAccountDecisionStep(): JSX.Element {
  const { inviteData, isInviteStatusLoading } = useGetPartnerInviteStatus()
  const { showPartnerAccess } = usePartnerAccess()
  const { send } = useStateMachine()

  useEffect(() => {
    if (!isInviteStatusLoading) {
      if (inviteData?.status === PartnerInviteStatus.ACTIVE) {
        send("PARTNER_ACCOUNT_ACTIVE")
      } else if (inviteData?.status === PartnerInviteStatus.INVITED) {
        send("PARTNER_INVITATION_NOT_ACCEPTED")
      } else if (showPartnerAccess) {
        send("PARTNER_ACCESS_NO_ACCOUNT")
      } else {
        send("NO_PARTNER_ACCESS")
      }
    }
  }, [inviteData?.status, send, showPartnerAccess, isInviteStatusLoading])
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Progress />
    </Box>
  )
}
