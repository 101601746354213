import React, { useEffect } from "react"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { useCurrentUser } from "#/components/context/user/UserContext"
import { Progress } from "@carrotfertility/carotene-core"

export function HasPartnerDecisionStep(): JSX.Element {
  const { send } = useStateMachine(null)
  const { partnerFirstName, partnerPreferredName } = useCurrentUser()

  useEffect(() => {
    if (partnerFirstName || partnerPreferredName) {
      send("YES")
    } else {
      send("NO")
    }
  }, [send, partnerFirstName, partnerPreferredName])

  return <Progress />
}
