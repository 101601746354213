import React from "react"
import { StateMachineProvider, StateMachineStepView } from "#/components/context/stateMachine/StateMachineV2"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"
import {
  Steps,
  telemedicineForMenoPauseSteps,
  telemedicineForMenopauseWorkflows,
  Workflows
} from "#/components/telemedicine/workflow"
import { Dialog } from "@carrotfertility/carotene-core"

type TelemedicineDialogContextReturn = {
  openDialogFlow: boolean
  setOpenDialogFlow: (arg: boolean) => void
}

export const TelemedicineDialogContext = React.createContext<TelemedicineDialogContextReturn>(null)

export function EnterTelemedicineForMenopauseDialog({
  openDialogFlow,
  setOpenDialogFlow
}: {
  openDialogFlow: boolean
  setOpenDialogFlow: (arg: boolean) => void
}): JSX.Element {
  const { isPartnerAccessingAccount } = useUserRole()
  enum Sections {
    TELEMEDICINE_FOR_MENOPAUSE = "TelemedicineForMenopause"
  }

  const telemedicineForMenopause = {
    name: Sections.TELEMEDICINE_FOR_MENOPAUSE,
    initialStep: Steps.WHO_WILL_RECEIVING_CARE,
    desiredWorkflow: isPartnerAccessingAccount
      ? Workflows.TELEMEDICINE_FOR_MENOPAUSE_PARTNER
      : Workflows.TELEMEDICINE_FOR_MENOPAUSE_MEMBER,
    steps: telemedicineForMenoPauseSteps,
    workflows: telemedicineForMenopauseWorkflows
  }
  return (
    <Dialog open={openDialogFlow}>
      <StateMachineProvider
        initialSection={Sections.TELEMEDICINE_FOR_MENOPAUSE}
        stateMachineDescription={[telemedicineForMenopause]}
        onComplete={null}
      >
        <TelemedicineDialogContext.Provider value={{ openDialogFlow, setOpenDialogFlow }}>
          <StateMachineStepView />
        </TelemedicineDialogContext.Provider>
      </StateMachineProvider>
    </Dialog>
  )
}
