import { useCurrentUser } from "#/components/context/user/UserContext"
import { TelemedicineDialogContext } from "#/components/telemedicine/EnterTelemedicineForMenopauseDialog"
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@carrotfertility/carotene-core"
import React, { useContext } from "react"
import { FormattedMessage } from "react-intl"

export function SignInAsMemberStep(): JSX.Element {
  const { setOpenDialogFlow } = useContext(TelemedicineDialogContext)
  const { preferredName, firstName } = useCurrentUser()
  return (
    <>
      <DialogTitle>
        <FormattedMessage
          defaultMessage="Sign in with {name}'s account"
          values={{ name: preferredName ?? firstName }}
        />
      </DialogTitle>
      <DialogContent>
        <Typography>
          <FormattedMessage defaultMessage="Please ask the member to sign in to their account to access Carrot’s virtual menopause clinic." />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialogFlow(false)}>
          <FormattedMessage defaultMessage="Close" />
        </Button>
      </DialogActions>
    </>
  )
}
