import React from "react"
import { Typography, Alert, Stack, useTheme, Link, Divider, Box, InternalIcon } from "@carrotfertility/carotene-core"
import { AppAccessPageCard, AppAccessPageCardFooter } from "#/components/access-page/AppAccessPageCard"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { FormattedMessage, defineMessage, useIntl } from "react-intl"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import { FormButton, FormTextField } from "@carrotfertility/carotene-core-x"
import { useIntlValidator } from "#/utils/hooks/useIntlValidator"
import { emailValidation } from "#/utils/Regexes"
import ConditionalSiteMaintenanceAlert from "#/components/site-maintenance/ConditionalSiteMaintenanceAlert"
import { getHeap } from "#/utils/heap"

export interface LoginProps {
  errorMessage?: React.ReactNode | React.ReactNode[]
  showForgotPassword: boolean
  showEmailInput: boolean
  showPasswordInput: boolean
  submitting: boolean
  shake: boolean
  samlConfig: SamlIdpConfig
  returnUrl: string
  redirectToSamlExternalLogin: (
    externalLoginUrl: string,
    entityId: string,
    employeeIsAdmin: boolean,
    returnUrl: string
  ) => void
}

type SamlIdpConfig = {
  samlExternalLoginUrl: string
  samlIdpEntityId: string
}

/**
 * For a11y of the sign in form fieldset
 */
function VisuallyHiddenText(props: React.PropsWithChildren<Record<string, unknown>>): JSX.Element {
  return (
    <Typography
      sx={{
        position: "absolute",
        insetInlineStart: "-9999px",
        inlineSize: "100px",
        blockSize: "auto",
        overflow: "hidden"
      }}
    >
      {props.children}
    </Typography>
  )
}

interface EmailInputProps {
  enabled: boolean
}

function EmailInput({ enabled }: EmailInputProps): JSX.Element {
  const intl = useIntl()
  const intlEmailValidation = useIntlValidator(emailValidation)

  return (
    <FormTextField
      disabled={!enabled}
      inputProps={{ readOnly: !enabled, disabled: false }}
      registerOptions={{
        required: intl.formatMessage({ defaultMessage: "Required" }),
        validate: intlEmailValidation
      }}
      label={intl.formatMessage({ defaultMessage: "Email" })}
      name="username"
      id="username"
    />
  )
}

function PasswordInput(): JSX.Element {
  const intl = useIntl()

  return (
    <FormTextField
      name="password"
      id="password"
      label={intl.formatMessage({ defaultMessage: "Password" })}
      type="password"
      registerOptions={{
        required: intl.formatMessage({ defaultMessage: "Required" })
      }}
    />
  )
}

export function OpenIDSignIn({
  errorMessage,
  shake,
  showEmailInput,
  showForgotPassword,
  showPasswordInput,
  samlConfig,
  redirectToSamlExternalLogin,
  returnUrl
}: LoginProps): JSX.Element {
  const intl = useIntl()

  const actionText = showPasswordInput
    ? defineMessage({ defaultMessage: "Sign in" })
    : defineMessage({ defaultMessage: "Continue" })

  const authFooterLinks = React.useMemo(() => {
    const links = [
      {
        id: "access-page-sign-up-link",
        to: "/signup",
        linkText: intl.formatMessage({ defaultMessage: "New to Carrot? Sign up" })
      }
    ]
    if (showForgotPassword) {
      links.push({
        id: "access-page-forgot-password-link",
        to: "/request-reset-password",
        linkText: intl.formatMessage({ defaultMessage: "Forgot your password?" })
      })
    }
    return links
  }, [showForgotPassword, intl])

  const theme = useTheme()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign in with Carrot" })}>
      <AppAccessPageCard id="login-card" shake={shake}>
        <Box marginBottom={(theme) => theme.spacing(theme.tokens.spacing.md)}>
          {errorMessage ? (
            <Alert severity="error">{errorMessage}</Alert>
          ) : (
            <Box textAlign="center">
              <Typography variant="h1">
                <FormattedMessage defaultMessage="Sign in with Carrot" />
              </Typography>
            </Box>
          )}
        </Box>

        <ConditionalSiteMaintenanceAlert />

        <Stack divider={<Divider />} spacing={theme.spacing(theme.tokens.spacing.lg)}>
          <Stack
            spacing={theme.spacing(theme.tokens.spacing.lg)}
            sx={{ textAlign: "center" }}
            marginBottom={theme.spacing(theme.tokens.spacing.xl)}
          >
            <fieldset>
              <legend>
                <VisuallyHiddenText>
                  <FormattedMessage defaultMessage="Sign in" />
                </VisuallyHiddenText>
              </legend>
              <Stack textAlign={"left"} spacing={theme.spacing(theme.tokens.spacing.lg)}>
                {showEmailInput && <EmailInput enabled={!showPasswordInput} />}
                {showPasswordInput ? <PasswordInput /> : null}
              </Stack>
            </fieldset>
            <Box display="block">
              <FormButton type="submit">{intl.formatMessage(actionText)}</FormButton>
            </Box>
            {samlConfig && (
              <div>
                <Link
                  endIcon={<InternalIcon fontSize="small" color="secondary" />}
                  component="button"
                  onClick={() => {
                    getHeap().track("Click on 'Sign in with SSO' Link on Sign In Page")
                    redirectToSamlExternalLogin(
                      samlConfig.samlExternalLoginUrl,
                      samlConfig.samlIdpEntityId,
                      false,
                      returnUrl
                    )
                  }}
                >
                  <FormattedMessage defaultMessage="Sign in with SSO" />
                </Link>
              </div>
            )}
            <div>
              <UnauthPreferredLocale />
            </div>
          </Stack>
          <AppAccessPageCardFooter links={authFooterLinks} />
        </Stack>
      </AppAccessPageCard>
    </AppAccessPageLayout>
  )
}
