import React from "react"
import { Stack, useTheme } from "@carrotfertility/carotene-core"
import { Form, FormSelectOption, useWatch } from "@carrotfertility/carotene-core-x"
import useAboutYou from "../../hooks/useAboutYou"
import { useToggle } from "../../../../utils/Hooks"
import { useStateMachine } from "#/components/context/stateMachine/StateMachineV2"
import { FormattedMessage, useIntl } from "react-intl"
import { useGetGenderIdentityOptions } from "#/services/common-forms/GenderIdentityOptions"
import {
  ButtonFooterLayout,
  ContinueButton,
  FormTextFieldWithCharacterCount,
  RoutingFlowDialog,
  RoutingFlowModalIcon,
  RoutingFlowTitle,
  SkipButton,
  StepLayout
} from "#/components/carrot-plans/shared"
import { FormRadioButtonGroup } from "#/services/common-forms"

function OptionalAnotherGenderIdentityTextField() {
  const selectedValue = useWatch({ name: "genderIdentity" })
  if (selectedValue !== "ANOTHER_GENDER_IDENTITY") {
    return null
  }
  return (
    <FormTextFieldWithCharacterCount
      name="genderIdentityOther"
      helperText={<FormattedMessage defaultMessage="Optional details for another gender identity" />}
    />
  )
}

export default function AboutYouGenderIdentityStep(): JSX.Element {
  const intl = useIntl()
  const { data, updateAboutYou, hasQuestionBeenSkipped } = useAboutYou()
  const [showMemberGenderIdentityModal, toggleMemberGenderIdentityModal] = useToggle(false)
  const { send } = useStateMachine(data?.genderIdentity || hasQuestionBeenSkipped("genderIdentity") ? "" : null)
  const genderIdentityOptions: FormSelectOption[] = useGetGenderIdentityOptions()
  const theme = useTheme()

  async function onSkip(): Promise<void> {
    await updateAboutYou({ genderIdentity: "skip", genderIdentityOther: "nullOut" })
    send("")
  }

  async function onClickContinue(formValues: { genderIdentity: string; genderIdentityOther: string }): Promise<void> {
    if (formValues?.genderIdentity) {
      await updateAboutYou({ ...formValues, genderIdentityOther: formValues.genderIdentityOther || "nullOut" })
      send("")
    } else {
      await onSkip()
    }
  }
  const header = intl.formatMessage({
    defaultMessage: "What's your gender identity?"
  })

  return (
    <>
      <RoutingFlowTitle title={header} />
      <StepLayout
        header={<span id="genderIdentity-label">{header}</span>}
        iconButton={
          <RoutingFlowModalIcon
            aria-label={intl.formatMessage({ defaultMessage: "Why do you need to know my gender identity?" })}
            onClick={toggleMemberGenderIdentityModal}
          />
        }
        formElements={
          <Form
            defaultValues={{ genderIdentity: data?.genderIdentity, genderIdentityOther: data?.genderIdentityOther }}
            onSubmit={onClickContinue}
          >
            <Stack spacing={theme.spacing(theme.tokens.spacing.sm)}>
              <FormRadioButtonGroup
                name="genderIdentity"
                options={genderIdentityOptions}
                aria-labelledby="genderIdentity-label"
              />
              <OptionalAnotherGenderIdentityTextField />
            </Stack>
            <ButtonFooterLayout
              continueButton={
                <ContinueButton>
                  <FormattedMessage defaultMessage="Continue" />
                </ContinueButton>
              }
              skipButton={<SkipButton onClick={onSkip} />}
            />
          </Form>
        }
      />
      <RoutingFlowDialog
        open={showMemberGenderIdentityModal}
        onClose={toggleMemberGenderIdentityModal}
        title={intl.formatMessage({ defaultMessage: "Why do you need to know my gender identity?" })}
        description={intl.formatMessage({
          defaultMessage:
            "This is the gender that you identify as, which may or may not be the same as your sex. This information helps us provide guidance and suggest relevant resources, and is never shared with your benefit sponsor."
        })}
      />
    </>
  )
}
