import {
  AdapterDayjs,
  Box,
  Button,
  dayjs,
  type Dayjs,
  DialogContentText,
  Divider,
  Link,
  LocalizationProvider,
  Stack,
  Typography,
  useTheme
} from "@carrotfertility/carotene-core"
import { PageHeader, PageHeaderGlyph } from "#/features/global-ui/page-header"
import { PageLayout } from "#/features/global-ui/page-layout"
import React, { useState } from "react"
import { Link as RouterLink, useHistory } from "react-router-dom"
import { FormattedMessage, useIntl } from "react-intl"
import { Title } from "#/services/page-title/Title"
import { useCurrentUser } from "#/components/context/user/UserContext"
import {
  Form,
  FormAlert,
  FormButton,
  FormOnSubmitHandler,
  FormTextField,
  InfoDialog
} from "@carrotfertility/carotene-core-x"
import { AppointmentRequest, ContactMethods, Days, Times } from "../../../types/RequestAppointmentTypes"
import useSubmitAppointmentRequest from "../../../hooks/useSubmitAppointmentRequest"
import { Address, formatAddress } from "localized-address-format"
import { Email } from "./Email"
import { PhoneNumberAndCountryCode } from "./PhoneNumberAndCountryCode"
import { CountryCode } from "#/utils/CountryCode"
import { PartnerInformation } from "./PartnerInformation"
import { PreferredContactMethod } from "./PreferredContactMethod"
import { AppointmentPreference } from "./AppointmentPreference"
import { getCountryCallingCodeConsideringEdgeCases } from "#/components/phone-number-collection/usePhoneCodeSelectOptions"

export type FormSelectValue<ValueType = string> = {
  value: ValueType
  label: string
}

export type CarrotPartnerBookingFormValues = {
  email: string
  phoneCountryCode: string
  phoneNumber: string
  preferredContactMethod: ContactMethods
  preferredAppointmentDays?: Days[]
  preferredAppointmentTimes?: Times[]
  desiredService?: string
  isBringingPartner: boolean
  partnerName: string
  partnerDateOfBirth: Dayjs
  partnerEmail: string
  partnerPhoneCountryCode: string
  partnerPhoneNumber: string
  partnerPreferredContactMethod: ContactMethods
  additionalDetails?: string
}

function formatMemberAddress(user: any): string {
  const addressLines = user.internationalAddressLine
    ? [user.internationalAddressLine]
    : [user.address1, user.address2].filter(Boolean)
  const addressProps: Address = {
    postalCountry: user.internationalCountry ?? user.company.countryCode,
    administrativeArea: user.state ? user.state : undefined,
    locality: user.city ? user.city : undefined,
    postalCode: user.zip ? user.zip : undefined,
    addressLines
  }
  return formatAddress(addressProps).join(", ")
}

function buildRequest(user: any, formFields: CarrotPartnerBookingFormValues): AppointmentRequest {
  const encodedProviderName = window.location.pathname.split("/").pop()
  return {
    providerName: decodeURIComponent(encodedProviderName),
    email: formFields.email,
    address: formatMemberAddress(user),
    phoneCountryCode: getCountryCallingCodeConsideringEdgeCases(formFields.phoneCountryCode as CountryCode),
    phoneNumber: formFields.phoneNumber,
    preferredContactMethod: formFields.preferredContactMethod,
    preferredAppointmentDays: formFields.preferredAppointmentDays ?? [],
    preferredAppointmentTimes: formFields.preferredAppointmentTimes ?? [],
    desiredService: formFields.desiredService,
    additionalDetails: formFields.additionalDetails,
    partner: !formFields.isBringingPartner
      ? null
      : {
          name: formFields.partnerName,
          dateOfBirth: formFields.partnerDateOfBirth.format("YYYY-MM-DD"),
          email: formFields.partnerEmail,
          phoneCountryCode: getCountryCallingCodeConsideringEdgeCases(
            formFields.partnerPhoneCountryCode as CountryCode
          ),
          phoneNumber: formFields.partnerPhoneNumber,
          preferredContactMethod: formFields.partnerPreferredContactMethod
        }
  }
}

export function CarrotPartnerBooking(): JSX.Element {
  const history = useHistory()
  const intl = useIntl()
  const theme = useTheme()
  const user = useCurrentUser()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { mutateAsync, isError } = useSubmitAppointmentRequest(() => {
    setShowSuccessModal(true)
  })
  const defaultCountryCode = user.internationalCountry ?? user.company.countryCode
  const phoneCountryCode = getCountryCallingCodeConsideringEdgeCases(defaultCountryCode as CountryCode)

  // This is left untyped for inputs that should not have default value
  const formDefaultValues = {
    email: user.preferredEmail,
    phoneCountryCode: defaultCountryCode,
    phoneNumber: user.phoneNumber?.replace(`+${phoneCountryCode}`, ""),
    isBringingPartner: false,
    partnerName: user.partnerFullName,
    partnerDateOfBirth: dayjs(user.partnerDateOfBirth),
    partnerEmail: user.partnerEmail,
    partnerPhoneCountryCode: "US"
  }

  const formSubmitHandler: FormOnSubmitHandler<CarrotPartnerBookingFormValues> = async (formFields): Promise<void> => {
    try {
      await mutateAsync(buildRequest(user, formFields))
    } catch {
      // https://github.com/react-hook-form/react-hook-form/issues/9821
      // ignored - we use the isError state from useSubmitAppointmentRequest() to handle errors
    }
  }

  return (
    <PageLayout
      variant="form"
      header={
        <PageHeader
          startGlyph={<PageHeaderGlyph src="/images/icn-providers.png" srcSet={"/images/icn-providers-2x.png"} />}
          pageTitle={<FormattedMessage defaultMessage="Provider finder" />}
        />
      }
    >
      <Title
        title={intl.formatMessage({ defaultMessage: "Request an appointment" })}
        productTitle={intl.formatMessage({ defaultMessage: "Find a provider" })}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Box maxWidth="620px">
          <Form defaultValues={formDefaultValues as CarrotPartnerBookingFormValues} onSubmit={formSubmitHandler}>
            <Stack spacing={theme.spacing(theme.tokens.spacing.xxxl)}>
              <Stack spacing={theme.spacing(theme.tokens.spacing.md)}>
                <Typography variant="h2">
                  <FormattedMessage defaultMessage="Request an appointment" />
                </Typography>
                {isError ? (
                  <FormAlert severity="error">
                    <FormattedMessage
                      defaultMessage="Something went wrong on our end. Please try again. If the problem persists, <link>contact us</link>."
                      values={{
                        link: (linkContent) => (
                          <Link component={RouterLink} color="inherit" to="/talk-to-carrot/care-navigation">
                            {linkContent}
                          </Link>
                        )
                      }}
                    />
                  </FormAlert>
                ) : null}
                <Typography>
                  <FormattedMessage defaultMessage="Please provide the details below to book an appointment. This information will be forwarded to the provider." />
                </Typography>
              </Stack>
              <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.xxl)} divider={<Divider />}>
                <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
                  <Typography variant="h3">
                    <FormattedMessage defaultMessage="Contact information" />
                  </Typography>
                  <Email />
                  <PhoneNumberAndCountryCode phoneCountryCode={"phoneCountryCode"} phoneNumber={"phoneNumber"} />
                  <PreferredContactMethod name="preferredContactMethod" />
                </Stack>
                <AppointmentPreference />
                <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
                  <Typography variant="h3">
                    <FormattedMessage defaultMessage="Partner information" />
                  </Typography>
                  <PartnerInformation />
                </Stack>
                <Stack gap={(theme) => theme.spacing(theme.tokens.spacing.lg)}>
                  <Typography variant="h3">
                    <FormattedMessage defaultMessage="Additional Information" />
                  </Typography>
                  <FormTextField
                    inputProps={{
                      maxLength: 3000
                    }}
                    multiline
                    minRows={4}
                    label={intl.formatMessage({ defaultMessage: "Additional questions or concerns" })}
                    name="additionalDetails"
                    helperText={intl.formatMessage(
                      {
                        defaultMessage:
                          "We can relay this information when setting up your appointment. For questions about your benefit or provider or service eligibility, <link>contact your Care Team</link>."
                      },
                      {
                        link: (linkContent) => (
                          <Link
                            variant="inherit"
                            color="inherit"
                            target="_blank"
                            href="/talk-to-carrot/care-navigation"
                          >
                            {linkContent}
                          </Link>
                        )
                      }
                    )}
                  />
                </Stack>
                <Box display="flex" justifyContent="flex-end">
                  <FormButton type="submit">
                    <FormattedMessage defaultMessage="Request appointment" />
                  </FormButton>
                </Box>
              </Stack>
            </Stack>
          </Form>
        </Box>
        <InfoDialog
          title={intl.formatMessage({ defaultMessage: "Appointment request received" })}
          open={showSuccessModal}
          primaryAction={
            <Button
              onClick={() => {
                setShowSuccessModal(false)
                history.push("/")
              }}
            >
              <FormattedMessage defaultMessage="Close" />
            </Button>
          }
        >
          <DialogContentText>
            <FormattedMessage defaultMessage="A member of your Care Team will be in touch via email with next steps shortly." />
          </DialogContentText>
        </InfoDialog>
      </LocalizationProvider>
    </PageLayout>
  )
}
