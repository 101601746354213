import React, { useEffect } from "react"
import { useSignUpFindAccount } from "./context/SignUpFindAccountContext"
import queryString from "query-string"
import { useHistory, useLocation } from "react-router"
import { SignUpFooter } from "#/components/signup"
import { AppAccessPageLayout } from "#/components/access-page/AppAccessPageLayout"
import { AppAccessPageCard } from "#/components/access-page/AppAccessPageCard"
import { UnauthPreferredLocale } from "#/components/views/UnauthPreferredLocale"
import { Alert, Box, Button, BackIcon, dayjs, useTheme, Stack, Link, Typography } from "@carrotfertility/carotene-core"
import { FormattedMessage, useIntl } from "react-intl"
import { getErrorMessageFromCode } from "../../utils/CarrotErrors"
import { Form, FormDatePicker, FormTextField, useFormContext } from "@carrotfertility/carotene-core-x"
import { validateMemberDOB } from "#/services/common-forms"
import { Link as RouterLink } from "react-router-dom"

function SignUpFindAccountHeader({ fromSignup }: { fromSignup: boolean }): JSX.Element {
  const { errorMessage, showTryAgain } = useSignUpFindAccount()
  const intl = useIntl()
  const theme = useTheme()
  const headerCopy = fromSignup
    ? intl.formatMessage({ defaultMessage: "Next, share your name and date of birth" })
    : intl.formatMessage({ defaultMessage: "Sign up for Carrot" })

  return (
    <>
      <Typography variant="h1">
        {showTryAgain ? <FormattedMessage defaultMessage="Try again" /> : headerCopy}
      </Typography>
      {errorMessage ? (
        <>
          <Alert severity="error">{intl.formatMessage(getErrorMessageFromCode(errorMessage))}</Alert>
        </>
      ) : (
        <>
          {showTryAgain && (
            <>
              <Box margin={theme.tokens.spacing.md}>
                <Alert severity="error">
                  <FormattedMessage
                    defaultMessage="Sorry, we couldn't find your account with the information provided. Try again or <link>contact our Care Team</link> for help."
                    values={{
                      link: (linkContent) => (
                        <Link component={RouterLink} color="inherit" to="/signup-get-help">
                          {linkContent}
                        </Link>
                      )
                    }}
                  />
                </Alert>
              </Box>
            </>
          )}
        </>
      )}
    </>
  )
}

function HiddenEmail() {
  const { register } = useFormContext()
  return <input hidden {...register("email")} />
}

function SignUpFindAccountForm({
  fromSignup,
  email
}: {
  fromSignup?: boolean
  email?: string | string[]
}): JSX.Element {
  const { shake, inputsEnabled, onSubmit } = useSignUpFindAccount()
  const theme = useTheme()
  const intl = useIntl()
  const history = useHistory()

  return (
    <AppAccessPageCard shake={shake}>
      <Form reValidateMode="onSubmit" defaultValues={{ email }} onSubmit={onSubmit}>
        <Stack spacing={theme.spacing(theme.tokens.spacing.lg)} marginBottom={theme.spacing(theme.tokens.spacing.md)}>
          <SignUpFindAccountHeader {...{ fromSignup }} />
          <HiddenEmail />
          <FormTextField
            name="firstName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            disabled={!inputsEnabled}
            label={intl.formatMessage({ defaultMessage: "First Name" })}
          />
          <FormTextField
            name="lastName"
            registerOptions={{ required: intl.formatMessage({ defaultMessage: "Required" }) }}
            disabled={!inputsEnabled}
            label={intl.formatMessage({ defaultMessage: "Last Name" })}
          />
          <FormDatePicker
            name="dateOfBirth"
            controllerProps={{
              rules: {
                required: intl.formatMessage({ defaultMessage: "Required" }),
                validate: (value: dayjs.Dayjs) => {
                  const result = validateMemberDOB(value)
                  if (typeof result !== "boolean") {
                    return intl.formatMessage(result)
                  }
                  return result
                }
              }
            }}
            label={intl.formatMessage({ defaultMessage: "Date of birth" })}
          />

          <Box
            display="flex"
            justifyContent={fromSignup ? "space-between" : "center"}
            marginTop={(theme) => theme.spacing(theme.tokens.spacing.xs)}
            sx={{ inlineSize: "100%" }}
          >
            {fromSignup ? (
              <Button
                data-testid="back-arrow"
                variant="text"
                color="secondary"
                startIcon={<BackIcon />}
                onClick={() => history.push("/signup")}
              >
                <FormattedMessage defaultMessage="Back" />
              </Button>
            ) : null}
            <Button type="submit" disabled={!inputsEnabled}>
              <FormattedMessage defaultMessage="Continue" />
            </Button>
          </Box>
          <UnauthPreferredLocale />
          {fromSignup ? null : (
            <Box>
              <SignUpFooter />
            </Box>
          )}
        </Stack>
      </Form>
    </AppAccessPageCard>
  )
}

export function SignUpFindAccountPage(): JSX.Element {
  const location = useLocation()
  const history = useHistory()
  const { email } = queryString.parse(location.search)
  const intl = useIntl()

  useEffect(() => {
    if (!email) {
      history.push("/signup")
    }
  }, [history, email])

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <SignUpFindAccountForm {...{ email }} fromSignup />
    </AppAccessPageLayout>
  )
}

export function SignUpInfoPage(): JSX.Element {
  const intl = useIntl()

  return (
    <AppAccessPageLayout title={intl.formatMessage({ defaultMessage: "Sign up" })}>
      <SignUpFindAccountForm />
    </AppAccessPageLayout>
  )
}
