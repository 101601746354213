export enum Steps {
  WHAT_KIND_OF_JOURNEY = "What kind of Journey",
  WHAT_KIND_OF_JOURNEY_IVF_DECISION = "What kind of journey IVF decision",
  WHERE_ARE_YOU_IN_JOURNEY = "Where are you in journey",
  WHERE_ARE_YOU_IN_JOURNEY_DECISION = "Where are you in journey decision",
  WHERE_ARE_YOU_IN_JOURNEY_IVF_DECISION = "Where are you in journey IVF decision",
  NAME_OF_AGENCY = "Name of agency",
  NAME_OF_CLINIC_AGENCY_ATTORNEY = "Name of clinic agency attorney",
  NAME_OF_CLINIC = "Name of clinic",
  MOST_HELPFUL = "What would be most helpful",
  US_MOST_HELPFUL = "US or non US",
  MOST_HELPFUL_DECISION = "What would be most helpful decision",
  WHAT_KIND_OF_JOURNEY_SHARE_BACKGROUND_DECISION = "What kind of journey share background decision",
  PERSON_OF_COLOR_SHARE_BACKGROUND = "Person of color share background",
  SOCIAL_DETERMINANTS_OF_HEALTH = "Social determinants of health",
  SOCIAL_DETERMINANTS_OF_HEALTH_DECISION = "Social determinants of health decision",
  WHAT_KIND_OF_JOURNEY_FINAL_DECISION = "What kind of journey final decision",
  IVF_INFORMATION = "IVF information",
  IVF_TREATMENT_START = "When are you starting fertility testing?",
  ADOPTION_PATHWAY = "Adoption pathway",
  GESTATIONAL_CARRIER_DECISION = "Gestational carrier decision",
  GESTATIONAL_CARRIER_IN_MIND = "Gestational carrier in mind",
  GESTATIONAL_CARRIER_DELIVERY = "Gestational carrier delivery",
  DO_ANY_APPLY = "Do any apply",
  WHAT_KIND_OF_JOURNEY_PRESERVATION_DECISION = "What kind of journey preservation decision",
  PRESERVATION = "Preservation",
  ANYTHING_ELSE = "Anything else",
  PROCESS_CREATE_CARROT_PLAN = "Process create Carrot Plan",
  MESSAGE_WONDERFUL = "Message wonderful",
  ART_MEDICAL_RECORDS_CONSENT = "ART medical records consent",
  PRESERVATION_MEDICAL_RECORDS_CONSENT = "Fertility preservation medical records consent",
  FERTILITY_PRESERVATION_IS_US_DECISION = "US or non US for Fertility preservation",
  ASSISTED_REPRODUCTION_IS_US_DECISION = "US or non US for Assisted reproduction",
  ATTEMPTING_TO_GET_PREGNANT_IS_US_DECISION = "US or non US for Attempting to get pregnant",
  CURRENTLY_PREGNANT_IS_US_DECISION = "US or non US for Currently pregnant",
  MENOPAUSE_IS_US_DECISION = "US or non US for Menopause",
  LOW_TESTOSTERONE_IS_US_DECISION = "US or non US for Low testosterone",
  MOST_HELPFUL_PATHWAY_FINDING_PROVIDER_DECISION = "Most helpful pathway and finding provider Decision",
  SHOW_MEDICAL_QUESTIONS_DECISION = "Show Medical Questions Decision Step",
  AMAB_OR_AFAB_DECISION = "AMAB or AFAB",
  AMAB_AFAB_MEDICAL_PREFERENCE_STEP = "AMAB or AFAB Medical Questions Preference Step",
  MEDICAL_PREFERENCE_STEP = "General Medical Questions Preference Step",
  ASSISTED_REPRODUCTION = "Assisted reproduction",
  DUE_DATE = "Due date",
  DELIVERY_DATE = "Delivery (birth) date",
  NUMBER_OF_CHILDREN = "The number of children a member has",
  ART_MEDICATION_START_DATE = "The date someone started assisted reproduction medication",
  PRESERVATION_MEDICATION_START_DATE = "The date someone started preservation medication",
  PRESERVATION_CYCLE_TESTING = "Testing for the egg or embryo preservation cycle timing",
  EGG_RETRIEVAL_DATE = "When did egg retrieval take place",
  WHERE_ARE_YOU_IN_JOURNEY_PRESERVATION_DECISION = "Where are you in journey preservation decision",
  RECENTLY_RECEIVED_PRESERVATION_DECISION = "Recently received preservation decision step"
}
