import React from "react"
import { AppAccessError } from "./AppAccessError"
import { useCurrentUser } from "../context/user/UserContext"
import { getBenefitConfigurationLoadFailed } from "#/redux/reducers/benefitConfiguration"
import { useSelector } from "react-redux"
import { Box, Progress } from "@carrotfertility/carotene-core"

export const Loading = () => {
  const { userInfoLoadError } = useCurrentUser()
  const benefitLoadError = useSelector(getBenefitConfigurationLoadFailed)
  const hasError = userInfoLoadError || benefitLoadError
  return hasError ? <AppAccessError /> : <LoadingInner />
}

export const LoadingInner = () => {
  return (
    <Box
      display="flex"
      bgcolor={(theme) => theme.palette.info.light}
      justifyContent="center"
      alignItems="center"
      sx={{ blockSize: "100vb", inlineSize: "100vi" }}
    >
      <Progress />
    </Box>
  )
}
