import { CountryCode } from "#/utils/CountryCode"
import { useQueryActiveSignatures, CarrotDocumentType } from "#/lib/carrot-api/signatureClient"
import { useSelector } from "react-redux"
import { getCompanyInfo } from "#/redux/reducers/companyInfo"
import { useGetIsExcludedFromMedicalRecordsConsent } from "#/services/user/hooks/useGetIsExcludedFromMedicalRecordsConsent"
import { useUserRole } from "#/components/partner-access/hooks/usePartnerAccess"

export function useMeetsInitialCriteriaToShowPreservationMedicalRecordsConsent({
  routingData
}: {
  routingData: { journey: string; preservationInterest: string }
}): boolean {
  const { companyId, parentCompanyId, countryCode } = useSelector(getCompanyInfo)
  const getIsExcludedFromMedicalRecordsConsent = useGetIsExcludedFromMedicalRecordsConsent()
  const { isPartnerAccessingAccount } = useUserRole()

  const isExcludedFromMedicalRecordsConsent = getIsExcludedFromMedicalRecordsConsent({
    companyId: Number(companyId),
    parentCompanyId: parentCompanyId
  })

  return (
    countryCode === CountryCode.United_States &&
    routingData?.journey === "PRESERVATION" &&
    !!routingData?.preservationInterest &&
    routingData?.preservationInterest !== "PRESERVE_SPERM" &&
    !isExcludedFromMedicalRecordsConsent &&
    !isPartnerAccessingAccount
  )
}

export function useShouldShowPreservationMedicalRecordsConsent({
  routingData
}: {
  routingData: { journey: string; preservationInterest: string }
}): {
  shouldShowPreservationMedicalRecordsConsent: boolean
  isLoadingShouldShowPreservationMedicalRecordsConsent: boolean
} {
  const meetsInitialCriteria = useMeetsInitialCriteriaToShowPreservationMedicalRecordsConsent({ routingData })

  const { data: activeSignatures, isFetching: isLoadingActiveSignatures } = useQueryActiveSignatures({
    enabled: meetsInitialCriteria
  })

  return {
    isLoadingShouldShowPreservationMedicalRecordsConsent: isLoadingActiveSignatures,
    shouldShowPreservationMedicalRecordsConsent:
      meetsInitialCriteria &&
      !activeSignatures?.some(
        (signature) => signature.documentType === CarrotDocumentType.FertilityPreservationConsentForm
      )
  }
}
